import { switchComponentBackground } from '@assets/themes/palette'
import { Box, Stack, styled } from '@mui/material'
import { useEffect, useRef } from 'react'

type TooltipWrapperProps = {
  children: React.ReactNode
  setTooltipOpen: (state: boolean) => void
}

const CustomBox = styled(Stack)(({ theme }) => ({
  position: 'relative',
  border: `1px solid ${switchComponentBackground}`,
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.borderRadius.medium,
  padding: 24,
  minWidth: 310,
  pointerEvents: 'all',
  boxShadow: '0px 2.058px 1.934px 0px rgba(0, 0, 0, 0.0022)',
  [theme.breakpoints.up('md')]: {
    minWidth: 350,
  },
}))

const TooltipWrapper = ({ children, setTooltipOpen }: TooltipWrapperProps) => {
  const ref = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (!ref.current) return

      const target = event.target as Node
      const nodeName = (event.target as HTMLElement)?.nodeName

      if (!ref.current.contains(target) && nodeName !== 'path') {
        setTooltipOpen(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [setTooltipOpen])

  return (
    <Box ref={ref} sx={{ position: 'relative', pointerEvents: 'all' }}>
      <CustomBox>{children}</CustomBox>
    </Box>
  )
}

export default TooltipWrapper
