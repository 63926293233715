import DtpInterimInvoice from '@components/DtpInterimInvoice/DtpInterimInvoice'
import InvoiceSettingsWrapper from '@src/ui/wrappers/InvoiceSettingsWrapper/InvoiceSettingsWrapper'
import { useTranslation } from 'react-i18next'

const InterimInvoice = () => {
  const { t } = useTranslation()

  return (
    <InvoiceSettingsWrapper
      link={t('navigation_url.invoices')}
      buttonTitle={t('back')}
      title={t('interim_invoice.request_interim_statement')}
      sx={{ gap: 1, '& .MuiTypography-heading2': { fontSize: '28px' } }}
    >
      <DtpInterimInvoice />
    </InvoiceSettingsWrapper>
  )
}

export default InterimInvoice
