import { InfoIcon, LogoIcon } from '@assets/icons'
import DtpInfoAlert from '@components/DtpInfoAlert/DtpInfoAlert'
import { ArrowForward } from '@mui/icons-material'
import { AppBar, Button, IconButton, SxProps, Toolbar } from '@mui/material'
import { useOnboardingStore } from '@src/stores/onboarding/useOnboardingStore'
import Show from '@src/ui/wrappers/Show/Show'
import { useEffect } from 'react'
import { Trans } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import DesktopMenu from './DesktopMenu'
import MobileMenu from './MobileMenu'

const alertWrapperStyle: SxProps = {
  position: 'relative',
  top: 0,
  left: 0,
  right: 0,
  width: '100%',
  display: 'flex',
  flexWrap: 'nowrap',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  bgcolor: 'rgba(60, 64, 60, 1)',
  color: 'white',
  px: {
    xs: 2,
    md: 3,
  },
}
const Navigation = () => {
  const navigate = useNavigate()
  const { currentOnboarding, setOnboarding } = useOnboardingStore()
  const { infoAlert, hasEmailChanged } = currentOnboarding
  const handleOnboardingClose = () => {
    setOnboarding({ ...currentOnboarding, infoAlert: false })
  }
  useEffect(() => {
    if (hasEmailChanged && !infoAlert) {
      setOnboarding({ ...currentOnboarding, infoAlert: true, hasEmailChanged: false })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [infoAlert, setOnboarding, hasEmailChanged])
  return (
    <>
      <AppBar color="default" position="relative" sx={{ padding: 0, zIndex: 2 }}>
        <Toolbar>
          <IconButton
            onClick={() => navigate('/')}
            disableRipple
            sx={{ mr: { xs: 0, md: 4 }, pt: { xs: 3.25, md: 1.75 }, pb: { xs: 2.25, md: 1.75 }, px: 0 }}
          >
            <LogoIcon data-testid="logo-icon" sx={{ width: '100%', height: { xs: '34px', md: '44px' } }} />
          </IconButton>
          <DesktopMenu />
          <MobileMenu />
        </Toolbar>
      </AppBar>
      <Show when={infoAlert}>
        <DtpInfoAlert
          wrapperSx={alertWrapperStyle}
          handleClose={handleOnboardingClose}
          message={
            <Trans
              i18nKey="onboarding.new_tariffs_hint"
              components={{
                a: (
                  <Button
                    variant="text"
                    color="inherit"
                    endIcon={<ArrowForward />}
                    sx={{
                      padding: { xs: 0 },
                      ml: { xs: 0, sm: 2 },
                      width: 'fit-content',
                      justifyContent: 'start',
                      fontSize: '18px',
                    }}
                  />
                ),
              }}
            />
          }
          icon={<InfoIcon />}
        />
      </Show>
    </>
  )
}

export default Navigation
