import theme from '@assets/themes/theme'
import { getProductName } from '@components/DtpEnergyProduct/utils'
import DtpModal from '@components/DtpModal/DtpModal'
import { Button, Box, Stack, Typography, Checkbox, Link, CircularProgress } from '@mui/material'
import Show from '@src/ui/wrappers/Show/Show'
import { useEffect, useState } from 'react'
import { useTranslation, Trans } from 'react-i18next'

export type DtpEnergyProductModalProps = {
  productKey: string
  isUpdating: boolean
  open: boolean
  onClose: () => void
  onConfirm: () => void
}

const DtpEnergyProductModal = ({ productKey, open, onClose, onConfirm, isUpdating }: DtpEnergyProductModalProps) => {
  const { t } = useTranslation()
  const [checked, setChecked] = useState(false)

  const { productName: product } = getProductName(productKey)

  useEffect(() => {
    if (!open) {
      setChecked(false)
    }
  }, [open])

  return (
    <DtpModal
      open={open}
      onClose={onClose}
      titleTypographyVariant="heading3"
      title={t('product.confirm_product_change')}
      sx={{
        '.MuiPaper-root': {
          padding: theme.spacing(6, 8, 7, 8),
          margin: 3,
          maxHeight: '100%',
          maxWidth: 784,
          width: '100%',
          height: 'initial',
          [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(4, 2),
            margin: 0,
            height: '100vh',
            maxWidth: '100%',
          },
        },
      }}
    >
      <Stack gap={3.5} pt={4} height="100%">
        <Typography variant="bodyRegular" sx={{ color: '#4F544F', my: 0 }}>
          <Trans
            i18nKey="product.switch_product_description"
            values={{ product: t(`product.${product}`) }}
            components={{ strong: <strong /> }}
          />
        </Typography>
        <Stack direction="row" alignItems="center" ml={-1}>
          <Checkbox size="small" checked={checked} onChange={({ target }) => setChecked(target.checked)} />
          <Typography sx={{ my: 0, mt: 0.25 }} variant="bodySmallEmphasized">
            <Trans
              i18nKey="product.accept_gtc"
              components={[
                <Link
                  key={'https://www.ckw.ch/agb'}
                  href="https://www.ckw.ch/agb"
                  target="_blank"
                  rel="noopener noreferrer"
                  color="primary"
                  sx={{ textDecoration: 'none' }}
                />,
              ]}
            />
          </Typography>
        </Stack>
        <Stack direction={{ xs: 'column', sm: 'row' }} gap={2} marginTop="auto">
          <Box>
            <Button variant="contained" disabled={!checked || isUpdating} fullWidth size="large" onClick={onConfirm}>
              <Show when={!isUpdating}>{t('product.confirm_change')}</Show>
              <Show when={isUpdating}>
                <CircularProgress size={30} sx={{ color: '#fff' }} />
              </Show>
            </Button>
          </Box>
          <Box>
            <Button variant="outlined" size="large" fullWidth onClick={onClose}>
              {t('cancel')}
            </Button>
          </Box>
        </Stack>
      </Stack>
    </DtpModal>
  )
}

export default DtpEnergyProductModal
