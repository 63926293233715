import { getInvoices } from '@api/queries'
import useAccounts from '@hooks/useAccounts/useAccounts'
import { useQuery } from '@tanstack/react-query'
import { useMemo } from 'react'

interface IUseInvoicesProps {
  status?: 'PAID' | 'NOTPAID'
  invoiceId?: string
}

export const useInvoices = ({ status, invoiceId }: IUseInvoicesProps) => {
  const { activeAccount } = useAccounts()

  const { data, isLoading, isError, refetch } = useQuery({
    queryKey: ['invoices', activeAccount.gpnr, status],
    queryFn: () =>
      getInvoices({
        gpnr: activeAccount.gpnr,
        paymentStatus: status,
        invoiceId: invoiceId,
      }),
    enabled: !!activeAccount.gpnr,
  })

  const invoices = useMemo(() => data?.body || {}, [data])

  return { invoices, isLoading, isError, refetch }
}
