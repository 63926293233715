import { isNil } from '@helpers/functional'
import { TooltipContentProps } from '@interfaces/tooltip'
import { Divider, Stack, Typography } from '@mui/material'
import Show from '@src/ui/wrappers/Show/Show'
import dayjs from 'dayjs'

import TooltipContentRow from '../TooltipContentRow/TooltipContentRow'
import NoDataTooltipContent from './NoDataTooltipContent'

const ConsumptionTooltipContent = ({ payload, unit, t, chartType, maxPower }: TooltipContentProps) => {
  const {
    consumption,
    energyMeasured,
    energy,
    amountHighRate,
    amountLowRate,
    sumOfRates,
    forecast,
    timestampStartOfPeriod,
    description,
  } = payload
  const consumptionColor = 'rgba(108, 156, 48, 1)'
  const consumptionMeasuredColor = 'rgba(108, 156, 48, 0.5)'
  const maxPowerTimestamp = maxPower?.timestamp

  const maxPowerValue = maxPower?.maxPower
  const WithHighAndLowRate = () => {
    const sumOfRatesWithForecast = forecast !== undefined ? Number(sumOfRates) + Number(forecast) : sumOfRates
    if (isNil(amountHighRate) && isNil(amountLowRate) && isNil(sumOfRates) && isNil(forecast) && !energy)
      return <NoDataTooltipContent />
    return (
      <>
        <Show when={!energy}>
          <TooltipContentRow
            lableIconColor={consumptionMeasuredColor}
            label={t('power_consumption_page.low_rate')}
            value={amountLowRate !== undefined ? String(Number(amountLowRate).toFixed(2)) : null}
            unit={unit}
            lableIconVariant="square"
          />
          <TooltipContentRow
            lableIconColor={consumptionColor}
            label={t('power_consumption_page.high_rate')}
            value={amountHighRate !== undefined ? String(Number(amountHighRate).toFixed(2)) : null}
            unit={unit}
            lableIconVariant="square"
          />
          <Show when={!!forecast && forecast !== '0'}>
            <TooltipContentRow
              lableIconColor={consumptionColor}
              label={t('power_consumption_page.forecast')}
              value={forecast !== undefined ? String(Number(forecast).toFixed(2)) : null}
              unit={unit}
              lableIconVariant="forecast"
            />
          </Show>
          <Show when={chartType !== 'area'}>
            <Divider sx={{ mt: 1, mb: 1 }} />
            <TooltipContentRow
              lableIconColor={consumptionColor}
              label={t('power_consumption_page.total_consumption')}
              value={
                sumOfRates !== undefined
                  ? String(Number(!!forecast && forecast !== '0' ? sumOfRatesWithForecast : sumOfRates).toFixed(2))
                  : null
              }
              unit={unit}
            />
          </Show>
        </Show>
      </>
    )
  }

  const WithEnergy = () => {
    const totalEnergy = !!energyMeasured && !!energy ? Number(energyMeasured) + Number(energy) : undefined
    if (!energy) return <NoDataTooltipContent />
    return (
      <>
        <Show when={!!energyMeasured && totalEnergy !== undefined && maxPowerTimestamp !== timestampStartOfPeriod}>
          <TooltipContentRow
            lableIconColor={consumptionMeasuredColor}
            label={t('prosumer.prosumer_measured')}
            value={energy !== undefined ? Number(energyMeasured).toFixed(2) : null}
            unit={unit}
            lableIconVariant="square"
          />
          <TooltipContentRow
            lableIconColor={consumptionMeasuredColor}
            label={t('prosumer.prosumer_invoiced')}
            value={energy !== undefined ? Number(energy).toFixed(2) : null}
            unit={unit}
            lableIconVariant="square"
          />
          <Divider />
        </Show>
        <Show
          when={
            (!!energy || totalEnergy !== undefined || !!consumption) && maxPowerTimestamp !== timestampStartOfPeriod
          }
        >
          <TooltipContentRow
            lableIconColor={consumptionColor}
            label={t('consumption.energy')}
            value={
              energy
                ? Number(totalEnergy !== undefined ? totalEnergy : energy).toFixed(2)
                : consumption
                  ? Number(consumption).toFixed(2)
                  : null
            }
            unit={unit}
            lableIconVariant="square"
          />
        </Show>
      </>
    )
  }

  const WithConsumption = () => {
    return (
      <Show when={!!consumption}>
        <TooltipContentRow
          lableIconColor={consumptionColor}
          label={t('prosumer.prosumer_consumption')}
          value={consumption !== undefined ? String(Number(consumption).toFixed(2)) : null}
          unit={unit}
          lableIconVariant="square"
        />
      </Show>
    )
  }

  const WithMaxPower = () => {
    const fomratedMaxPowerTimestamp = dayjs(maxPowerTimestamp).format('YYYY-MM-DDTHH:00')
    const isSameDayHour = dayjs(fomratedMaxPowerTimestamp).isSame(timestampStartOfPeriod)
    const isSameDay = dayjs(maxPowerTimestamp).isSame(timestampStartOfPeriod, 'day')
    const hasMaxPower = chartType === 'area' ? isSameDayHour : isSameDay
    return (
      <Show when={!!maxPowerValue && hasMaxPower}>
        <TooltipContentRow
          lableIconColor={'transparent'}
          label={t('consumption.maxPower')}
          value={maxPowerValue !== undefined ? String(Number(maxPowerValue).toFixed(2)) : null}
          unit={'kW'}
          lableIconVariant="maxPower"
        />
      </Show>
    )
  }

  return (
    <Stack rowGap={1}>
      <Show when={sumOfRates !== undefined}>
        <WithHighAndLowRate />
      </Show>

      <WithConsumption />

      <Show when={energy !== undefined}>
        <WithEnergy />
      </Show>

      <WithMaxPower />

      <Show when={!!description}>
        <Typography sx={{ fontSize: '16px' }} variant="bodyRegular">
          *{t(`power_consumption_page.${description}`)}
        </Typography>
      </Show>
    </Stack>
  )
}

export default ConsumptionTooltipContent
