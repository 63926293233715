import DtpTextField from '@components/DtpTextField/DtpTextField'
import { useMask } from '@react-input/mask'
import { useTranslation } from 'react-i18next'

interface IAccessCodeInput {
  value: string
  error: boolean
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  onBlur: (event: React.FocusEvent<HTMLInputElement>) => void
  helperText?: string
}

const AccessCodeInput = ({ value, error, onChange, onBlur, helperText }: IAccessCodeInput) => {
  const evidenceInputRef = useMask({ mask: '___ ___ ___', replacement: '_' })
  const { t } = useTranslation()
  return (
    <DtpTextField
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      name="evidence"
      inputRef={evidenceInputRef}
      label={t('authorization.access_code')}
      inputMode="tel"
      helperText={helperText}
      error={error}
      placeholder="xxx xxx xxx"
      autoComplete="off"
      fullWidth
    />
  )
}

export default AccessCodeInput
