import { interimInvoiceModalStyles } from '@components/DtpInterimInvoice/utils'
import DtpModal from '@components/DtpModal/DtpModal'
import { Stack, Button, Typography, CircularProgress } from '@mui/material'
import Show from '@src/ui/wrappers/Show/Show'
import { Trans, useTranslation } from 'react-i18next'

type ConfirmationModalProps = {
  open: boolean
  isFree: boolean
  isUpdating: boolean
  onClose: () => void
  onConfirm: () => void
}

const ConfirmationModal = ({ open, onClose, isFree, onConfirm, isUpdating }: ConfirmationModalProps) => {
  const { t } = useTranslation()
  return (
    <DtpModal
      open={open}
      sx={interimInvoiceModalStyles}
      title={t('interim_invoice.important_information')}
      titleTypographyVariant="heading3"
      onClose={onClose}
    >
      <Stack justifyContent="space-between" height={1}>
        <Typography variant="bodyRegular" sx={{ my: 4 }}>
          <Trans i18nKey={`interim_invoice.${isFree ? 'free_interim_invoice' : 'fee_interim_invoice'}`} />
        </Typography>
        <Stack direction={{ xs: 'column', sm: 'row' }} gap={2}>
          <Button variant="contained" size="large" onClick={onConfirm} disabled={isUpdating}>
            <Show when={!isUpdating}>
              {t(`interim_invoice.${isFree ? 'accept_and_request' : 'request_for_a_fee'}`)}
            </Show>
            <Show when={isUpdating}>
              <CircularProgress size={30} sx={{ color: '#fff' }} />
            </Show>
          </Button>
          <Button variant="outlined" size="large" onClick={onClose}>
            {t('cancel')}
          </Button>
        </Stack>
      </Stack>
    </DtpModal>
  )
}

export default ConfirmationModal
