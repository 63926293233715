export const getProductName = (productKey: string) => {
  let productName = ''
  let energyFrom = ''
  switch (productKey) {
    case '05':
      productName = 'product_budgetstrom'
      energyFrom = 'energy_from_nuclear_power'
      break
    case '06':
      productName = 'product_classicstrom'
      energyFrom = 'energy_from_hydropower'
      break
    case '07':
      productName = 'product_meinregiostrom'
      energyFrom = 'energy_from_solar_energy'
      break
  }
  return { productName, energyFrom }
}
