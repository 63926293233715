import {
  ArrowDiagonalIcon,
  DesktopComputerIcon,
  HeavyCheckMarkIcon,
  HighVoltageIcon,
  TelephoneReceiverIcon,
} from '@assets/icons'
import theme from '@assets/themes/theme'
import { Box, Button, Chip, Divider, Paper, Stack, styled, Typography, useMediaQuery } from '@mui/material'
import Show from '@src/ui/wrappers/Show/Show'
import { useTranslation } from 'react-i18next'

import { getProductName } from './utils'

interface IDtpEnergyProductProps {
  productKey: string
  isCurrent: boolean
  amountPerYear: string | number | null
  amountPerMonth: string | number | null
  highlighted?: boolean
  onClick: () => void
}

const EnergyProductCard = styled(Box)(({ theme }) => ({
  position: 'relative',
  marginTop: 25,
  width: '100%',
  [theme.breakpoints.down('sm')]: {
    maxWidth: 'unset',
  },
}))

const SelectedAccount = styled(Chip)(({ theme }) => ({
  position: 'absolute',
  left: 16,
  top: '0',
  transform: 'translateY(-50%)',
  background: theme.palette.primary.main,
  width: 'fit-content',
  color: theme.palette.primary.contrastText,
  borderRadius: theme.borderRadius?.large,
  fontWeight: 500,
  fontSize: 16,
  fontFamily: 'GothamMedium',
  padding: theme.spacing(1, 0.5),
  height: 'auto',
}))

const ProductItem = ({ children, divider }: { children: React.ReactNode; divider?: boolean }) => {
  return (
    <>
      {divider && <Divider sx={{ border: 1, width: 16, color: '#365F10' }} />}
      <Typography variant="bodyRegular" sx={{ my: 2, display: 'inline-flex', alignItems: 'center', gap: 1 }}>
        {children}
      </Typography>
    </>
  )
}

const DtpEnergyProduct = ({
  productKey,
  isCurrent,
  amountPerYear,
  amountPerMonth,
  highlighted,
  onClick,
}: IDtpEnergyProductProps) => {
  const { t } = useTranslation()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const { productName, energyFrom } = getProductName(productKey)

  return (
    <EnergyProductCard sx={{ height: isMobile ? '550px' : '630px' }}>
      <Show when={isCurrent}>
        <SelectedAccount label={t('product.current_energy_product')} />
      </Show>
      <Paper
        sx={{
          mt: 0,
          pt: 5,
          px: 4,
          pb: 4,
          backgroundImage: highlighted ? 'radial-gradient(360px 360px at 34px bottom, #fbfce2 0%, #ffffff 100%)' : '',
          border: isCurrent ? '1.5px solid #365F10' : '1px solid #D5D8D5',
          height: '100%',
        }}
      >
        <Stack direction="column" rowGap={2} p={0} height="100%">
          <Typography variant="heading3" sx={{ my: 0 }}>
            {t(`product.${productName}`)}
          </Typography>
          <Stack flexGrow={1}>
            <ProductItem>
              <HighVoltageIcon />
              {t(`product.${energyFrom}`)}
            </ProductItem>
            <Show when={productKey === '05'}>
              <ProductItem divider>
                <DesktopComputerIcon />
                {t('product.self_service_only')}
              </ProductItem>
            </Show>
            <Show when={productKey === '06' || productKey === '07'}>
              <ProductItem divider>
                <TelephoneReceiverIcon />
                {t('product.personalised_customer_service')}
              </ProductItem>
            </Show>
            <Show when={productKey === '06'}>
              <ProductItem divider>
                <HeavyCheckMarkIcon />
                {t('product.standard_product')}
              </ProductItem>
            </Show>
            <Show when={productKey === '07'}>
              <ProductItem divider>
                <HeavyCheckMarkIcon />
                {t('product.energy_from_the_region')}
              </ProductItem>
            </Show>
          </Stack>
          <Button
            variant="text"
            sx={{ justifyContent: 'start', p: { xs: 0 } }}
            endIcon={<ArrowDiagonalIcon />}
            fullWidth
            href="https://www.ckw.ch/energie/strom/stromprodukte/privat#rechner"
            target="_blank"
            rel="noopener"
          >
            {t('product.show_price_sheet')}
          </Button>
          <Divider />
          <Box>
            <Show when={!!amountPerYear}>
              <Typography variant="heading3" textAlign="right" sx={{ my: 0 }}>
                {amountPerYear}
                <Typography component="span" variant="bodyRegular">
                  {` CHF / ${t('year')}`}
                </Typography>
              </Typography>
            </Show>
            <Show when={!!amountPerMonth}>
              <Typography variant="captionEmphasized" textAlign="right" sx={{ my: 0 }}>
                {amountPerMonth}
                <Typography component="span" variant="caption">
                  {` CHF / ${t('month')}`}
                </Typography>
              </Typography>
            </Show>
          </Box>
          <Divider sx={{ border: 0 }} />
          <Box minHeight={48}>
            <Show when={!isCurrent}>
              <Button variant="contained" size="small" fullWidth sx={{ height: '48px' }} onClick={onClick}>
                {t('product.switch_to_this_product')}
              </Button>
            </Show>
          </Box>
        </Stack>
      </Paper>
    </EnergyProductCard>
  )
}

export default DtpEnergyProduct
