import { styled, Box, SxProps } from '@mui/material'
import Slider, { Settings } from 'react-slick'
import 'slick-carousel/slick/slick-theme.css'
import 'slick-carousel/slick/slick.css'

interface ISliderProps extends Settings {
  children: React.ReactNode
  sliderWrapperStyle?: SxProps
}

const defaultSettings = {
  dots: true,
  infinite: false,
  arrows: false,
  speed: 500,
  initialSlide: 0,
}

const SliderWrapper = styled(Box)({
  '& .slick-slider': {
    overflowX: 'hidden',
    position: 'unset',
  },
  '& .slick-track > div:not(:last-of-type) > div': {
    paddingRight: '16px',
  },
  '& .slick-list': {
    overflow: 'visible',
  },
  '& .slick-dots': {
    position: 'unset',
    '& li': {
      height: '10px',
      '& > button': {
        transition: 'all 0.1s ease',
        width: '10px',
        height: '10px',
        background: '#D9D9D9',
        margin: '0 auto',
        borderRadius: '999px',
        '&::before': {
          content: 'none',
        },
      },
      '&.slick-active > button': {
        width: '28px',
        background: 'linear-gradient(256.56deg, #9AB20F -22.21%, #4E7818 65.58%)',
      },
    },
  },
})

export default function DtpCarousel(props: ISliderProps) {
  const { children, sliderWrapperStyle, ...rest } = props
  const sliderProps = { ...defaultSettings, ...rest }

  return (
    <SliderWrapper sx={sliderWrapperStyle}>
      <Slider {...sliderProps}>{children}</Slider>
    </SliderWrapper>
  )
}
