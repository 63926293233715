import DtpCardSelect, { DtpCardSelectItem } from '@components/DtpCardSelect'
import { DtpContractDropdown } from '@components/DtpContractDropdown/DtpContractDropdown'
import { DtpDropdown } from '@components/DtpDropdown/DtpDropdown'
import DtpErrorCard from '@components/DtpErrorCard/DtpErrorCard'
import DtpInvoiceSettingsCard from '@components/DtpInvoiceSettingsCard/DtpInvoiceSettingsCard'
import DtpInvoiceSettingsModal from '@components/DtpInvoiceSettingsModal/DtpInvoiceSettingsModal'
import DtpReceiptOfInvoiceCard from '@components/DtpReceiptOfInvoiceCard/DtpReceiptOfInvoiceCard'
import { ISelectItem } from '@components/DtpSelect/DtpSelect'
import DtpCardSelectSkeleton from '@components/Skeletons/DtpCardSelectSkeleton'
import { SuspenseLoading } from '@components/SuspenseLoading/SuspenseLoading'
import { InvoiceDeliveryMethod, InvoicePaymentMethod } from '@enums/invoiceSettings'
import useInvoiceSettings from '@hooks/useInvoiceSettings/useInvoiceSettings'
import useLocationsData, { IAddOn } from '@hooks/useLocations/useLocationsData'
import useNotification from '@hooks/useNotification/useNotification'
import { Button, Stack, Typography, Box } from '@mui/material'
import InvoiceSettingsWrapper from '@src/ui/wrappers/InvoiceSettingsWrapper/InvoiceSettingsWrapper'
import Show from '@src/ui/wrappers/Show/Show'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

const InvoiceSettings = () => {
  const { t } = useTranslation()
  const notification = useNotification()
  const { activeLocations, isLoading } = useLocationsData()
  const [selectedItem, setSelectedItem] = useState<ISelectItem<IAddOn>>({ label: '', value: '' })
  const [open, setOpen] = useState(false)
  const [selectedDeliveryMethod, setSelectedDeliveryMethod] = useState('')

  const activeContracts = activeLocations.filter((contract) => contract.addOn?.contractId)

  const {
    invoiceSettings,
    isLoadingInvoiceSettings,
    isInvoiceSettingsError,
    refetchInvoices,
    updateDeliveryMethod,
    isUpdatingDeliveryMethod,
  } = useInvoiceSettings({
    contractAccountId: selectedItem.addOn?.contractAccountId,
  })

  const affectedContracts = activeContracts.filter(
    (contract) => contract.addOn?.contractAccountId === selectedItem.addOn?.contractAccountId
  )

  const handleUpdateDeliveryMethod = async () => {
    try {
      if (selectedItem.addOn?.contractAccountId) {
        await updateDeliveryMethod(
          {
            contractAccountId: selectedItem.addOn?.contractAccountId,
            paymentDeliveryType: selectedDeliveryMethod,
          },
          {
            onSuccess: () => {
              setOpen(false)
              notification(
                'success',
                t('toast.update_invoice_settings_successful_title'),
                t('toast.update_invoice_settings_successful_explanation')
              )
              refetchInvoices()
            },
          }
        )
      }
    } catch (error) {
      notification('error', t('http_errors.saving_error_title'), t('http_errors.saving_error_description'))
    }
  }

  useEffect(() => {
    if (invoiceSettings?.deliveryMethod) {
      setSelectedDeliveryMethod(invoiceSettings?.deliveryMethod)
    }
  }, [invoiceSettings, setSelectedDeliveryMethod])

  useEffect(() => {
    if (activeContracts.length && !selectedItem.value) {
      setSelectedItem(activeContracts[0])
    }
  }, [activeContracts, selectedItem.value])

  return (
    <InvoiceSettingsWrapper
      link={t('navigation_url.invoices')}
      buttonTitle={t('navigation.navi_invoices')}
      title={t('navigation.navi_invoices_settings')}
      sx={{ gap: 1 }}
    >
      <Show when={activeContracts.length > 1}>
        <DtpContractDropdown
          activeContracts={activeContracts}
          expiredContracts={[]}
          value={selectedItem.value}
          onLocationsSelect={setSelectedItem}
          isLoading={isLoading}
        />
      </Show>

      <Show when={activeContracts.length === 0}>
        <DtpDropdown
          disabled
          noDataLabel={t('no_active_contract')}
          value=""
          activeLocations={[]}
          expiredLocations={[]}
        />
      </Show>

      <Typography variant="heading4">{t('invoices_settings.payment_method')}</Typography>

      <Stack rowGap={2}>
        <Show when={isInvoiceSettingsError}>
          <DtpErrorCard action={refetchInvoices} />
        </Show>

        <Show when={!isInvoiceSettingsError}>
          <DtpInvoiceSettingsCard
            status={invoiceSettings?.paymentMethod === InvoicePaymentMethod.EBILL ? 'active' : 'recommended'}
            type={InvoicePaymentMethod.EBILL}
            title={t('e_bill.e_bill')}
            description={t('e_bill.directly_in_online_banking')}
            link={t('e_bill.e_bill')}
            isLoading={isLoadingInvoiceSettings}
          />
          <DtpInvoiceSettingsCard
            status={invoiceSettings?.paymentMethod === InvoicePaymentMethod.DIRECT_DEBIT ? 'active' : 'none'}
            type={InvoicePaymentMethod.DIRECT_DEBIT}
            title={t('invoices_settings.direct_debit')}
            description={t('invoices_settings.automatic_debiting')}
            link={t('invoices_settings.navigation_url')}
            isLoading={isLoadingInvoiceSettings}
          />
          <DtpInvoiceSettingsCard
            status={invoiceSettings?.paymentMethod === InvoicePaymentMethod.MANUAL ? 'active' : 'none'}
            type={InvoicePaymentMethod.MANUAL}
            title={t('manual_payment.title')}
            description={t('manual_payment.pay_manually')}
            link={t('manual_payment.navigation_url')}
            isLoading={isLoadingInvoiceSettings}
          />
        </Show>
      </Stack>

      <Show when={!isLoadingInvoiceSettings && !isInvoiceSettingsError && !!invoiceSettings?.paymentMethod}>
        <Typography marginTop={5} variant="heading4">
          {t('invoices_settings.receipt_of_invoice')}
        </Typography>

        <Show when={invoiceSettings?.deliveryMethod === InvoiceDeliveryMethod.EBILL}>
          <Typography marginTop={0} marginBottom={2.25} maxWidth={'550px'} variant="bodyRegular">
            {t('invoices_settings.ebill_active_additional_info')}
          </Typography>
          <DtpReceiptOfInvoiceCard
            title={t('invoices_settings.ebill_activated')}
            description={t('invoices_settings.invoice_online_banking')}
          />
        </Show>

        <Show when={invoiceSettings?.deliveryMethod !== InvoiceDeliveryMethod.EBILL}>
          <Box maxWidth={550}>
            <DtpCardSelect
              defaultValue={invoiceSettings?.deliveryMethod}
              onChange={({ target }) => setSelectedDeliveryMethod(target.value)}
            >
              <DtpCardSelectItem
                label={t('invoices.invoice_by_email')}
                checked={InvoiceDeliveryMethod.EMAIL === selectedDeliveryMethod}
                value={InvoiceDeliveryMethod.EMAIL}
              />
              <DtpCardSelectItem
                label={t('invoices.invoice_by_post')}
                checked={InvoiceDeliveryMethod.POST === selectedDeliveryMethod}
                value={InvoiceDeliveryMethod.POST}
              />
            </DtpCardSelect>
          </Box>

          <Box height={42}>
            <Show when={invoiceSettings?.deliveryMethod !== selectedDeliveryMethod}>
              <Button
                size="small"
                onClick={affectedContracts.length > 1 ? () => setOpen(true) : handleUpdateDeliveryMethod}
              >
                {t('invoices_settings.apply_changes')}
              </Button>
            </Show>
          </Box>
        </Show>
      </Show>

      <Show when={isLoadingInvoiceSettings}>
        <DtpCardSelectSkeleton />
        <DtpCardSelectSkeleton />
      </Show>

      <DtpInvoiceSettingsModal
        open={open}
        onClose={() => setOpen(false)}
        onConfirm={handleUpdateDeliveryMethod}
        contracts={affectedContracts}
      />

      <Show when={isUpdatingDeliveryMethod}>
        <Box
          sx={{
            position: 'fixed',
            left: 0,
            top: 0,
            width: '100%',
            bgcolor: 'rgba(255, 255, 255, 0.5)',
            zIndex: 1,
          }}
        >
          <SuspenseLoading />
        </Box>
      </Show>
    </InvoiceSettingsWrapper>
  )
}

export default InvoiceSettings
