// import { ICardItemProps } from '@interfaces/bills'
import theme from '@assets/themes/theme'
import { Typography, Stack, SxProps } from '@mui/material'

interface ICardItemProps {
  title: string
  value: string
  bold?: boolean
  isCreditNote?: boolean
  color?: string
  sx?: SxProps
}

export const CardItem = ({ title, value, bold = false, isCreditNote = false, color, sx }: ICardItemProps) => {
  return (
    <Stack direction="row" justifyContent="space-between" alignItems="center" sx={sx}>
      <Typography variant="bodySmall" fontFamily="GothamBook" sx={{ my: 0.5 }}>
        {title}
      </Typography>
      <Stack flexDirection="row" alignItems="center">
        <Typography
          variant="bodySmall"
          fontFamily={bold ? 'GothamMedium' : 'GothamBook'}
          sx={{ my: 0.5, color: color ?? (isCreditNote ? theme.palette.primary.main : 'inherit') }}
        >
          {value}
        </Typography>
      </Stack>
    </Stack>
  )
}
