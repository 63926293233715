import { Typography } from '@mui/material'

const CustomLabel = ({ label, sublabel, isMobile }: { label: string; sublabel?: string; isMobile: boolean }) => {
  return (
    <Typography
      variant={'bodyRegular'}
      component="span"
      sx={{
        m: '0 !important',
      }}
      fontFamily="GothamMedium"
    >
      {label}
      {sublabel && (
        <Typography
          p={0}
          m={0}
          component="span"
          variant={isMobile ? 'bodySmall' : 'bodyRegular'}
          color="textSecondary"
          fontFamily="GothamBook"
          sx={{
            m: '0 !important',
            fontSize: {
              xs: '14px',
              md: '16px',
            },
            lineHeight: '160%',
          }}
        >
          <br />
          {sublabel}
        </Typography>
      )}
    </Typography>
  )
}

export default CustomLabel
