import { isNil } from '@helpers/functional'
import { MeasurmentsType } from '@interfaces/measurements'
import { TooltipContentProps } from '@interfaces/tooltip'
import { Divider, Stack } from '@mui/material'
import Show from '@src/ui/wrappers/Show/Show'

import TooltipContentRow from '../TooltipContentRow/TooltipContentRow'

const ComparisonTooltipContent = ({
  payload,
  unit,
  t,
  measurementsType,
}: TooltipContentProps & {
  measurementsType?: MeasurmentsType
}) => {
  const { energy, energyChf, consumption, forecast, value, balance, feedIn } = payload

  const consumptionComparisonColor = 'rgba(177, 97, 162, 1)'
  const forecastColor = 'rgba(177, 97, 162, 0.2)'
  const WithConsumption = () => {
    const totalEnergyWithForcast =
      !isNil(energy) || !isNil(consumption) || !isNil(forecast) || !isNil(energyChf)
        ? Number(energy ? energy : consumption ? consumption : energyChf) + Number(forecast)
        : null

    const EnergyRow = () => {
      const showChfValue = unit === 'CHF' && !!energyChf
      const translationKeyForEnergyCosts = showChfValue
        ? 'power_consumption_page.energy_costs'
        : 'power_consumption_page.consumption'

      return (
        <Show when={!!energy || !!energyChf}>
          <TooltipContentRow
            label={t(showChfValue && !!forecast ? 'power_consumption_page.past_costs' : translationKeyForEnergyCosts)}
            value={showChfValue ? Number(energyChf).toFixed(2) : Number(energy).toFixed(2)}
            unit={unit}
            lableIconColor={consumptionComparisonColor}
            lableIconVariant="square"
          />
        </Show>
      )
    }
    const ConsumptionRow = () => {
      const translationKeyForCosumption =
        unit === 'kWh' && !!forecast ? 'power_consumption_page.past_consumption' : 'power_consumption_page.consumption'

      return (
        <Show when={!!consumption || measurementsType === 'consumption'}>
          <TooltipContentRow
            label={t(
              forecast !== undefined && unit === 'CHF'
                ? 'power_consumption_page.past_costs'
                : translationKeyForCosumption
            )}
            value={Number(consumption ?? value).toFixed(2)}
            unit={unit}
            lableIconColor={consumptionComparisonColor}
            lableIconVariant="square"
          />
        </Show>
      )
    }
    const ForecastRow = () => {
      return (
        <Show when={!!forecast}>
          <TooltipContentRow
            label={t('power_consumption_page.forecast')}
            value={Number(forecast).toFixed(2)}
            unit={unit}
            lableIconColor={forecastColor}
            lableIconVariant="forecast"
          />
        </Show>
      )
    }
    const TotalRow = () => {
      return (
        <Show when={!!totalEnergyWithForcast && !!forecast}>
          <TooltipContentRow
            label={t(
              unit === 'CHF' ? 'power_consumption_page.estimated_costs' : 'power_consumption_page.estimated_consumption'
            )}
            value={Number(totalEnergyWithForcast).toFixed(2)}
            unit={unit}
            lableIconColor={forecastColor}
            lableIconVariant="forecast"
          />
        </Show>
      )
    }

    return (
      <>
        <EnergyRow />
        <ConsumptionRow />
        <ForecastRow />
        <Show when={!!forecast}>
          <Divider sx={{ mt: 1, mb: 1 }} />
        </Show>
        <TotalRow />
      </>
    )
  }

  return (
    <Stack gap={1}>
      <Show when={measurementsType !== 'feedIn'}>
        <WithConsumption />
      </Show>

      <Show when={(measurementsType === 'balance' || measurementsType === 'prosumer') && (!!value || !!balance)}>
        <TooltipContentRow
          label={t('prosumer.prosumer_balance')}
          value={String(Number(value ? value : balance).toFixed(2))}
          unit={unit}
          lableIconColor={Number(value ? value : balance) < 0 ? 'rgb(236, 106, 8)' : consumptionComparisonColor}
          lableIconVariant="square"
        />
      </Show>

      <Show when={measurementsType === 'feedIn' && (!!value || !!feedIn)}>
        <TooltipContentRow
          label={t('prosumer.prosumer_feed_in')}
          value={String(Number(value ? value : feedIn).toFixed(2))}
          unit={unit}
          lableIconColor={'rgba(253, 195, 0, 1)'}
          lableIconVariant="square"
        />
      </Show>
    </Stack>
  )
}

export default ComparisonTooltipContent
