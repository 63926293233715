import DtpConnectAccountWizzard from '@components/DtpConnectAccountWizard/DtpConnectAccountWizard'
import DtpErrorCard from '@components/DtpErrorCard/DtpErrorCard'
import DtpFrequentRequests from '@components/DtpFrequentRequests/DtpFrequentRequests'
import DtpHeroCardV2 from '@components/DtpHeroCardV2/DtpHeroCardV2'
import DtpInvoiceCardGroup from '@components/DtpInvoiceCardGroup/DtpInvoiceCardGroup'
import DtpNoOpenInvoices from '@components/DtpNoOpenInvoices/DtpNoOpenInvoices'
import OnboardingModal from '@components/Onboarding/OnboardingModal/OnboardingModal'
import DtpInvoiceCardGroupSkeleton from '@components/Skeletons/DtpInvoiceCardGroupSkeleton'
import { useInvoices } from '@hooks/useInvoices/useInvoices'
import useRoleAccess from '@hooks/useRoleAccess/useRoleAccess'
import { Container, Stack } from '@mui/material'
import { useOnboardingStore } from '@src/stores/onboarding/useOnboardingStore'
import FeatureToggle from '@src/ui/wrappers/FeatureToggle/FeatureToggle'
import HomeWrapper from '@src/ui/wrappers/HomeWrapper/HomeWrapper'
import Show from '@src/ui/wrappers/Show/Show'
import { useEffect } from 'react'

const Home = () => {
  const { invoices, isLoading, isError, refetch: refetchOpenInvoices } = useInvoices({ status: 'NOTPAID' })
  const openInvoices = Object.values(invoices).flat()
  const { isEndUser, user } = useRoleAccess()
  const { currentOnboarding, setOnboarding } = useOnboardingStore()
  const { userEmail, onboardingOpen, hasEmailChanged } = currentOnboarding

  const handleOnboardingClose = () => {
    setOnboarding({ ...currentOnboarding, userEmail: user?.email ?? '', onboardingOpen: false })
  }

  useEffect(() => {
    if (isEndUser && userEmail !== user?.email) {
      setOnboarding({ ...currentOnboarding, userEmail: user?.email ?? '', onboardingOpen: true, hasEmailChanged: true })
    }
  }, [currentOnboarding, hasEmailChanged, isEndUser, setOnboarding, user?.email, userEmail])

  if (!isEndUser) return <DtpConnectAccountWizzard />
  return (
    <HomeWrapper>
      <DtpHeroCardV2 />
      <Container>
        <Show when={isLoading}>
          <DtpInvoiceCardGroupSkeleton />
        </Show>

        <Show when={!isLoading && !isError}>
          <DtpInvoiceCardGroup invoices={openInvoices} />
        </Show>

        <Show when={!openInvoices.length && !isLoading && !isError}>
          <DtpNoOpenInvoices />
        </Show>

        <Show when={isError}>
          <Stack alignItems="center">
            <DtpErrorCard action={refetchOpenInvoices} />
          </Stack>
        </Show>

        <DtpFrequentRequests />
      </Container>

      <FeatureToggle
        fallback={<OnboardingModal open={onboardingOpen} onClose={handleOnboardingClose} />}
        flag="onboardingModal"
      >
        <></>
      </FeatureToggle>
    </HomeWrapper>
  )
}
export default Home
