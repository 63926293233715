import CloseIcon from '@mui/icons-material/Close'
import { Dialog, Box, IconButton, Stack, Typography, DialogProps, SxProps, TypographyOwnProps } from '@mui/material'

interface IDtpModalProps {
  open: boolean
  onClose: () => void
  title?: string
  titleTypographyVariant?: TypographyOwnProps['variant']
  titleStyle?: SxProps
  children: React.ReactNode
  sx?: SxProps
  closeOutside?: boolean
}

const DtpModal = ({
  open,
  onClose,
  title,
  children,
  sx,
  closeOutside,
  titleTypographyVariant,
  titleStyle,
}: IDtpModalProps) => {
  const handleClose: DialogProps['onClose'] = (_, reason) => {
    if (reason && reason === 'backdropClick' && !closeOutside) return
    onClose()
  }
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      sx={
        sx || {
          '.MuiPaper-root': {
            padding: '24px 32px',
            maxWidth: '450px',
            width: '100%',
          },
        }
      }
    >
      <Stack sx={titleStyle} direction="row" justifyContent="space-between" alignItems="center">
        <Typography sx={{ m: 0 }} variant={titleTypographyVariant ?? 'leadEmphasized'}>
          {title}
        </Typography>
        <IconButton aria-label="close" onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Stack>
      <Box height="100%">{children}</Box>
    </Dialog>
  )
}

export default DtpModal
