import { DtpContractDropdown } from '@components/DtpContractDropdown/DtpContractDropdown'
import { DtpDatePicker } from '@components/DtpDatePicker/DtpDatePicker'
import { ISelectItem } from '@components/DtpDropdown/DtpDropdown'
import { useInterimInvoice } from '@hooks/useInterimInvoice/useInterimInvoice'
import useLocationsData, { IAddOn } from '@hooks/useLocations/useLocationsData'
import useNotification from '@hooks/useNotification/useNotification'
import { useToggleState } from '@hooks/useToggleState/useToggleState'
import { IRequestInterimInfoProps } from '@interfaces/invoices'
import { Box, Button, Skeleton, Typography } from '@mui/material'
import { CalendarIcon } from '@mui/x-date-pickers'
import Show from '@src/ui/wrappers/Show/Show'
import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import ConfirmationModal from './components/ConfirmationModal/ConfirmationModal'
import ContractsUnavailable from './components/ContractsUnavailable/ContractsUnavailable'
import SuccessModal from './components/SuccessModal/SuccessModal'
import { getSelectableDateRange } from './utils'

const DtpInterimInvoice = () => {
  const { t } = useTranslation()
  const notification = useNotification()

  const [date, setDate] = useState<string | undefined>(undefined)
  const [selectedItem, setSelectedItem] = useState<ISelectItem<IAddOn>>({ label: '', value: '' })

  const [confirmationModal, toggleConfirmationModal] = useToggleState(false)
  const [successModal, toggleSuccessModal] = useToggleState(false)

  const { activeLocations, isLoading: isLoadingContracts } = useLocationsData()

  const activeContracts = activeLocations.filter((contract) => contract.addOn?.contractId)

  const {
    interimInvoice,
    isLoading: isLoadingInterimInvoice,
    requestInterimInvoice,
    isRequestingInterimInvoice,
  } = useInterimInvoice({
    premiseId: selectedItem.addOn?.premiseId as string,
  })

  const { minDate, maxDate, isDisabled } = getSelectableDateRange({
    lastInvoice: interimInvoice?.dateOfLastInvoice ?? '',
    nextInvoice: interimInvoice?.dateOfNextInvoice ?? '',
  })

  const handleRequestInterimInvoice = async () => {
    const params = {
      premiseId: selectedItem.addOn?.premiseId,
      dateTime: dayjs(date).format('YYYY-MM-DD'),
      contractAccountId: selectedItem.addOn?.contractAccountId,
      contractId: selectedItem.addOn?.contractId,
    } as IRequestInterimInfoProps

    try {
      await requestInterimInvoice(params, {
        onSuccess: () => {
          toggleConfirmationModal()
          toggleSuccessModal()
        },
      })
    } catch (error) {
      notification(
        'error',
        t('interim_invoice.error_interim_invoice_request_title'),
        t('interim_invoice.error_interim_invoice_request_message')
      )
    }
  }

  const hasContracts = activeContracts.length > 1

  useEffect(() => {
    if (activeContracts.length && !selectedItem.value) {
      setSelectedItem(activeContracts[0])
    }
  }, [activeContracts, selectedItem.value])

  useEffect(() => {
    setDate(undefined)
  }, [selectedItem?.addOn?.contractId])

  if (!isLoadingContracts && !activeContracts.length) {
    return <ContractsUnavailable />
  }

  return (
    <>
      <Box maxWidth={678}>
        <Typography variant="bodyRegular" sx={{ mt: 0 }}>
          {t('interim_invoice.description_interim_invoice')}
        </Typography>

        <Show when={!isLoadingContracts && hasContracts}>
          <DtpContractDropdown
            activeContracts={activeContracts}
            expiredContracts={[]}
            value={selectedItem.value}
            isLoading={isLoadingContracts}
            onLocationsSelect={setSelectedItem}
          />
        </Show>

        <Typography variant="h5" sx={{ mb: 3, mt: { xs: 5, sm: 6 } }}>
          {t('interim_invoice.regular_invoicing')}
        </Typography>

        <Show when={!isLoadingInterimInvoice}>
          <Typography variant="bodyRegular" sx={{ my: 0 }}>
            <Trans
              i18nKey="interim_invoice.next_invoice"
              values={{ nextDate: dayjs(interimInvoice?.dateOfNextInvoice).format('DD.MM.YYYY') }}
            />
          </Typography>
          <Typography variant="bodyRegular" sx={{ my: 0 }}>
            <Trans
              i18nKey="interim_invoice.last_invoice"
              values={{ lastDate: dayjs(interimInvoice?.dateOfLastInvoice).format('DD.MM.YYYY') }}
            />
          </Typography>
        </Show>

        <Show when={isLoadingInterimInvoice}>
          <Skeleton variant="text" height={28.8} />
          <Skeleton variant="text" height={28.8} />
        </Show>

        <Typography variant="h5" sx={{ mb: 3, mt: { xs: 5, sm: 6 } }}>
          {t('interim_invoice.select_new_key_date')}
        </Typography>

        <Typography variant="bodyRegular">{t('interim_invoice.explanation_interim_invoice')}</Typography>

        <Box mt={{ xs: 3, sm: 2 }}>
          <Typography variant="caption">{t('interim_invoice.select_key_date')}</Typography>
          <Show when={!isLoadingInterimInvoice}>
            <DtpDatePicker
              viewType="day"
              variant="outlined"
              value={date}
              onChange={setDate}
              minDate={minDate}
              maxDate={maxDate}
              disabled={isDisabled}
              icon={<CalendarIcon />}
              isEmpty
            />
          </Show>

          <Show when={isLoadingInterimInvoice}>
            <Skeleton variant="rounded" height={44} width={160} />
          </Show>
        </Box>

        <Button
          sx={{ mt: { xs: 5, sm: 8 } }}
          size="large"
          onClick={toggleConfirmationModal}
          disabled={isDisabled || !date}
        >
          {t('interim_invoice.request_interim_statement')}
        </Button>
      </Box>

      <ConfirmationModal
        open={confirmationModal}
        onClose={toggleConfirmationModal}
        isFree={!!interimInvoice?.free}
        onConfirm={handleRequestInterimInvoice}
        isUpdating={isRequestingInterimInvoice}
      />
      <SuccessModal open={successModal} onClose={toggleSuccessModal} />
    </>
  )
}

export default DtpInterimInvoice
