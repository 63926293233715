import { CheckFilledIcon } from '@assets/icons'
import { interimInvoiceModalStyles } from '@components/DtpInterimInvoice/utils'
import DtpModal from '@components/DtpModal/DtpModal'
import { Stack, Typography, Button, Box } from '@mui/material'
import { useTranslation, Trans } from 'react-i18next'
import { useNavigate } from 'react-router'

type SuccessModalProps = {
  onClose: () => void
  open: boolean
}

const SuccessModal = ({ open, onClose }: SuccessModalProps) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  return (
    <DtpModal open={open} sx={interimInvoiceModalStyles} onClose={onClose}>
      <Stack justifyContent="space-between" height={1}>
        <Box sx={{ textAlign: 'center' }}>
          <CheckFilledIcon sx={{ fill: '#6C9C30', height: '72px', width: '72px' }} />
          <Typography variant="h5">{t('interim_invoice.interim_invoice_requested')}</Typography>
          <Typography variant="bodyRegular" sx={{ mt: 2, mb: 4 }}>
            <Trans i18nKey={'interim_invoice.interim_invoice_requested_description'} />
          </Typography>
        </Box>
        <Stack direction={{ xs: 'column', sm: 'row' }} justifyContent="center" gap={2}>
          <Button variant="contained" size="large" onClick={() => navigate(t('navigation_url.invoices'))}>
            {t('interim_invoice.to_invoices')}
          </Button>
          <Button variant="outlined" size="large" onClick={onClose}>
            {t('button_close')}
          </Button>
        </Stack>
      </Stack>
    </DtpModal>
  )
}

export default SuccessModal
