import theme from '@assets/themes/theme'
import DtpModal from '@components/DtpModal/DtpModal'
import { Button, Stack, Typography, useMediaQuery } from '@mui/material'
import { useTranslation } from 'react-i18next'

import OnboardingCarousel from '../OnboardingCarousel/OnboardingCarousel'

const modalStyle = {
  '.MuiPaper-root': {
    p: {
      xs: 3,
      md: 5,
    },
    pb: {
      xs: 3,
      md: 4,
    },
    minWidth: {
      xs: '100%',
      md: '80%',
      xl: 1013,
    },
    minHeight: {
      xs: '100vh',
      md: 'auto',
    },
    borderRadius: {
      xs: 0,
      md: '24px',
    },
    textAlign: {
      xs: 'center',
      md: 'left',
    },
    background: {
      xs: 'rgba(241, 249, 232, 1)',
      md: 'rgba(255, 255, 255)',
    },
  },
}

const titleStyle = {
  display: 'flex',
  flexDirection: {
    xs: 'column-reverse',
    md: 'row',
  },
  gap: {
    xs: 2,
    md: 'initial',
  },
  '& .MuiIconButton-root': {
    alignSelf: 'flex-end',
    color: 'black',
  },
}

const contentWrapperStyle = {
  height: {
    xs: '80vh',
    md: 'auto',
  },
  position: 'relative',
  justifyContent: {
    xs: 'space-between',
    md: 'initial',
  },
  pt: {
    xs: 0,
    md: 4,
  },
  gap: {
    xs: 2,
    md: 0,
  },
}

export type OnboardingModalProps = {
  open: boolean
  onClose: () => void
}

const OnboardingModal = ({ open, onClose }: OnboardingModalProps) => {
  const { t } = useTranslation()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <DtpModal
      sx={modalStyle}
      open={open}
      onClose={onClose}
      title={t('onboarding.title')}
      titleTypographyVariant="heading2"
      titleStyle={titleStyle}
    >
      <Stack sx={contentWrapperStyle}>
        <Typography variant="lead">{t('onboarding.subtitle')}</Typography>
        <OnboardingCarousel />
        <Stack direction="row">
          <Button fullWidth={isMobile} onClick={onClose} variant="contained" color="primary">
            {t('onboarding.close')}
          </Button>
        </Stack>
      </Stack>
    </DtpModal>
  )
}

export default OnboardingModal
