import DtpMenu from '@components/DtpMenu/DtpMenu'
import {
  Box,
  Stack,
  styled,
  Typography, //  Chip
} from '@mui/material'
import Show from '@src/ui/wrappers/Show/Show'
import { useTranslation } from 'react-i18next'

interface IDtpAccountCard {
  active?: boolean
  name?: string
  gpnr?: string
  address?: {
    city: string
    postalCode: string
    streetAddress: string
  }
  onSetMainAccount?: () => void
  onChangeAccount?: () => void
  onDeleteAccount?: () => void
}

interface IAccountInfo {
  label: string
  value?: string
}
// TODO:  return this code when bussiness enable it
// const SelectedAccount = styled(Chip)(({ theme }) => ({
//   position: 'absolute',
//   left: 32,
//   top: -18.5,
//   background: theme.palette.primary.main,
//   width: 'fit-content',
//   color: theme.palette.primary.contrastText,
//   borderRadius: theme.borderRadius?.large,
//   fontWeight: 500,
//   fontSize: 14,
//   fontFamily: 'GothamMedium',
//   padding: theme.spacing(1, 0.5),
//   height: 'auto',
// }))

const CustomText = ({ children, subtext = false }: { children: React.ReactNode; subtext?: boolean }) => {
  return (
    <Typography sx={{ margin: 0 }} variant={subtext ? 'bodyRegular' : 'bodyRegularEmphasized'}>
      {children}
    </Typography>
  )
}

const AccountInfo = ({ label, value }: IAccountInfo) => (
  <Stack rowGap={0.5} alignItems="flex-start">
    <Show when={!!label}>
      <CustomText>{label}</CustomText>
    </Show>
    <Show when={!!value}>
      <CustomText subtext>{value}</CustomText>
    </Show>
  </Stack>
)

const DtpAccountCard = ({
  active,
  name,
  gpnr,
  address,
  onSetMainAccount,
  onChangeAccount,
  onDeleteAccount,
}: IDtpAccountCard) => {
  const { t } = useTranslation()

  const Card = styled(Box)(({ theme }) => ({
    position: 'relative',
    marginTop: 25,
    border: active ? '1.5px solid #365F10' : '1px solid #D5D8D5',
    maxWidth: 368,
    width: '100%',
    borderRadius: 24,
    padding: theme.spacing(4, 3),
    [theme.breakpoints.down('sm')]: {
      maxWidth: 'unset',
    },
  }))

  return (
    <Card>
      {/* TODO:  return this code when bussiness enable it  */}
      {/* <Show when={!!active}>
        <SelectedAccount label={t('selected')} />
      </Show> */}
      <Stack rowGap={2}>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <AccountInfo label={name ?? ''} />
          <DtpMenu
            onSetMainAccount={onSetMainAccount}
            onChangeAccount={!active ? onChangeAccount : undefined}
            onDeleteAccount={onDeleteAccount}
          />
        </Stack>
        <AccountInfo label={`${t('customer_number')}:`} value={gpnr} />
        <AccountInfo
          label={t('city')}
          value={address ? `${address?.postalCode}  ${address?.city}` : t('contracts.error_message_no_data_available')}
        />
      </Stack>
    </Card>
  )
}

export default DtpAccountCard
