import { useEffect } from 'react'

const LiveChatWidget = () => {
  useEffect(() => {
    const scriptId = 'Microsoft_Omnichannel_LCWidget'
    if (document.getElementById(scriptId)) return

    const webChatScript = document.createElement('script')
    webChatScript.id = scriptId
    webChatScript.src = 'https://oc-cdn-public-eur.azureedge.net/livechatwidget/scripts/LiveChatBootstrapper.js'

    const attributes: Record<string, string> = {
      'data-app-id': '41cc0bba-36ff-4840-b132-f723a7c5613b',
      'data-lcw-version': 'prod',
      'data-org-id': '9b5d5cd6-55b8-4a5e-b99c-a0a8ea924fac',
      'data-org-url': 'https://m-9b5d5cd6-55b8-4a5e-b99c-a0a8ea924fac.eu.omnichannelengagementhub.com',
      'data-hide-chat-button': 'false',
      //   'data-customization-callback': 'ckw',
    }

    Object.entries(attributes).forEach(([key, value]) => {
      webChatScript.setAttribute(key, value)
    })

    document.head.appendChild(webChatScript)

    return () => {
      document.head.removeChild(webChatScript)
    }
  }, [])

  return null
}

export default LiveChatWidget
