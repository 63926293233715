import { DownloadIcon } from '@assets/icons'
import theme from '@assets/themes/theme'
import DtpErrorModal from '@components/DtpErrorModal/DtpErrorModal'
import DtpModal from '@components/DtpModal/DtpModal'
import { AggregationEnum } from '@enums/aggregationTypes'
import { getAggregationsStartEndDate } from '@helpers/dateFunctions'
import { createFileName, downloadFile } from '@helpers/downloadCSVFunctions'
import useDownloadMeasurementsV2 from '@hooks/useDownloadMeasurementsV2/useDownloadMeasurementsV2'
import { IAddOn } from '@hooks/useLocations/useLocationsData'
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Skeleton,
  useMediaQuery,
} from '@mui/material'
import Show from '@src/ui/wrappers/Show/Show'
import { OpUnitType } from 'dayjs'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import CustomLabel from './CustomLabel'

const modalStyles = {
  '.MuiPaper-root': {
    py: '24px',
    pr: {
      xs: '14px',
      sm: '24px',
    },
    pl: '24px',
    maxWidth: {
      xs: '100%',
      sm: '400px',
    },
    width: '100%',
    borderRadius: '24px',
    m: {
      xs: 2,
      sm: 'inherit',
    },
  },
}

export interface IDtpDownloadCsv2Props {
  customAggregation: boolean
  aggregation: AggregationEnum
  chartType: OpUnitType
  date: string
  startModalDate: string
  endModalDate: string
  addOn: IAddOn
  timestampStartOfPeriod?: string
  isDisabled?: boolean
  isLoading?: boolean
}
const DtpDownloadCsvV2 = ({
  customAggregation,
  aggregation,
  chartType,
  date,
  startModalDate,
  endModalDate,
  addOn,
  timestampStartOfPeriod,
  isDisabled,
  isLoading,
}: IDtpDownloadCsv2Props) => {
  const { t } = useTranslation()
  const [open, setOpen] = useState(false)
  const [errorModalOpen, setErrorModalOpen] = useState(false)
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const initialValue = t('power_consumption_page.energy_consumption_label')
  const [fileName, setFileName] = useState<string>(initialValue)
  const dateFormat = chartType === 'day' ? 'DD. MMM YYYY' : chartType === 'month' ? 'MMMM YYYY' : 'YYYY'
  const { meterId, locationId, contractId } = addOn
  const handleCloseDownload = () => {
    setOpen(false)
    setFileName(initialValue)
  }
  const handleOpenDownload = () => setOpen(true)

  const { startDate, endDate } = getAggregationsStartEndDate(
    date,
    chartType,
    customAggregation,
    startModalDate,
    endModalDate
  )

  const { downloadMeasurements, isLoadingDownloadAggregation } = useDownloadMeasurementsV2()

  const handleDownloadCsv = async () => {
    try {
      await downloadMeasurements(
        {
          meterId,
          aggregation: fileName.includes('15') ? AggregationEnum.FIFTEEN_MINUTES : aggregation,
          startDate,
          endDate,
          locationId,
          contractId,
        },
        {
          onSuccess: (data: File) => {
            downloadFile({
              data: data,
              fileName: createFileName(
                customAggregation,
                fileName,
                startModalDate,
                endModalDate,
                timestampStartOfPeriod,
                dateFormat
              ),
              fileType: 'text/csv',
            })

            setErrorModalOpen(false)
          },
        }
      )
    } catch (error) {
      setErrorModalOpen(true)
      setOpen(false)
    }
  }

  const handleDownloadValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFileName(e.target.value)
  }

  if (isLoading)
    return (
      <Box sx={{ padding: { xs: '14px 16px', md: '16px 20px' } }}>
        <Skeleton animation="wave" variant="rounded" width={150} height={28} />
      </Box>
    )
  return (
    <>
      <Button
        sx={{ Self: 'end' }}
        variant="text"
        startIcon={<DownloadIcon />}
        onClick={handleOpenDownload}
        disabled={isDisabled}
      >
        {t('power_consumption_page.download')}
      </Button>
      <DtpModal
        open={open}
        sx={modalStyles}
        title={t('power_consumption_page.download_data')}
        onClose={handleCloseDownload}
        closeOutside
      >
        <FormControl
          sx={{
            '& .MuiFormControlLabel-root': {
              alignItems: 'flex-start',
              mr: 0,
            },
          }}
        >
          <RadioGroup
            aria-labelledby="download-as-csv"
            value={fileName}
            name="download-as-csv"
            sx={{ mt: 2 }}
            onChange={handleDownloadValueChange}
          >
            <FormControlLabel
              value={t('power_consumption_page.energy_consumption_label').toString()}
              control={<Radio />}
              label={
                <CustomLabel
                  isMobile={isMobile}
                  label={t('power_consumption_page.energy_consumption_label')}
                  sublabel={t('power_consumption_page.download_selection_as_table_description')}
                />
              }
            />
            <FormControlLabel
              value={t('power_consumption_page.values_15_min').toString()}
              control={<Radio />}
              label={
                <CustomLabel
                  isMobile={isMobile}
                  label={t('power_consumption_page.values_15_min')}
                  sublabel={t('power_consumption_page.download_values_15_min_description')}
                />
              }
            />
            {/* TODO: uncomment this when BE finishes the download max power */}
            {/* <FormControlLabel
              value={t('power_consumption_page.download_peak_power').toString()}
              control={<Radio />}
              label={
                <CustomLabel
                isMobile={isMobile}
                  label={t('power_consumption_page.download_peak_power')}
                  sublabel={t('power_consumption_page.download_peak_power_description')}
                />
              }
             
            /> */}
          </RadioGroup>
        </FormControl>
        <Button
          variant="contained"
          type="submit"
          sx={{ mt: 2 }}
          onClick={handleDownloadCsv}
          disabled={isLoadingDownloadAggregation}
        >
          <Show when={isLoadingDownloadAggregation}>
            <CircularProgress size={30} color="inherit" />
          </Show>
          <Show when={!isLoadingDownloadAggregation}>{t('power_consumption_page.download')}</Show>
        </Button>
      </DtpModal>
      <DtpErrorModal
        errorTitle={t('power_consumption_page.download_error_title')}
        errorDescription={t('http_errors.general_error_description_retry')}
        open={errorModalOpen}
        onClose={() => setErrorModalOpen(false)}
        isLoading={isLoadingDownloadAggregation}
        onConfirm={handleDownloadCsv}
      />
    </>
  )
}
export default DtpDownloadCsvV2
