import { Typography } from '@mui/material'

const LabelText = ({ text }: { text: string }) => {
  return (
    <Typography display="inline" component="span" variant="caption">
      {text}
    </Typography>
  )
}

export default LabelText
