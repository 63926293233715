import { CheckIcon } from '@assets/icons'
import DtpInfoTooltip from '@components/DtpInfoTooltip/DtpInfoTooltip'
import { CardItem } from '@components/DtpInvoiceCard/CardItem'
import { DtpInvoiceCardWrapper } from '@components/DtpInvoiceCard/DtpInvoiceCard'
import { statusType } from '@components/DtpInvoiceCard/DtpInvoiceCardUtills'
import { invoiceStatus } from '@enums/invoiceStatus'
import { getFormattedBillingPeriod } from '@helpers/invoices'
import { Box, Stack, SxProps, Typography } from '@mui/material'
import Show from '@src/ui/wrappers/Show/Show'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'

import InvoiceStatus from './InvoiceStatus'

export interface IDtpInvoiceCardProps {
  invoiceId: string
  dueDate: string
  invoiceStatus: invoiceStatus
  eBill: boolean
  billingPeriodFromDate: string
  billingPeriodToDate: string
  sx?: SxProps
}

const DtpInvoiceDetailCard = ({
  invoiceId,
  dueDate,
  eBill,
  invoiceStatus,
  billingPeriodFromDate,
  billingPeriodToDate,
  sx,
}: IDtpInvoiceCardProps) => {
  const { t } = useTranslation()

  const billingPeriod = getFormattedBillingPeriod(billingPeriodFromDate, billingPeriodToDate, true)
  const status = invoiceStatus ? statusType[invoiceStatus] : undefined

  return (
    <DtpInvoiceCardWrapper
      role="open-invoice-card"
      sx={{
        cursor: 'default',
        '&:hover': {
          boxShadow: 'none',
        },
        ...sx,
      }}
    >
      <CardItem title={t('invoices.invoice_time_period')} value={billingPeriod} />
      <CardItem title={t('invoices.invoice_nr')} value={invoiceId} />
      <CardItem
        title={t('invoices.invoice_due_date')}
        value={dayjs(dueDate).format('DD.MM.YYYY')}
        color={status?.textColor}
      />
      <InvoiceStatus
        title={t('invoices.invoice_status')}
        invoiceStatus={invoiceStatus}
        slot={
          invoiceStatus === 'OPENCREDITNOTE' ? (
            <DtpInfoTooltip
              title={t('invoices.credit_note_explanation_title')}
              content={
                <Typography variant="bodyRegular" color="textPrimary">
                  {t('invoices.credit_note_explanation')}
                </Typography>
              }
              id="invoice_status"
            />
          ) : undefined
        }
      />
      <Show when={eBill}>
        <Stack flexDirection="row" alignItems="center" mt={2}>
          <Box mt={0.5}>
            <CheckIcon color="success" fontSize="small" />
          </Box>
          <Typography variant="bodyRegular" sx={{ my: 0, ml: 1 }}>
            {t('invoices.invoice_ebill')}
          </Typography>
        </Stack>
      </Show>
    </DtpInvoiceCardWrapper>
  )
}

export default DtpInvoiceDetailCard
