import { getDownloadAggregationMesurementsV2 } from '@api/queries'
import useAccounts from '@hooks/useAccounts/useAccounts'
import { IDownloadAggregationMeasurementsProps } from '@interfaces/measurements'
import { useMutation } from '@tanstack/react-query'

const useDownloadMeasurementsV2 = () => {
  const { activeAccount } = useAccounts()
  const gpnr = activeAccount.gpnr
  const { mutateAsync: downloadMeasurements, isPending: isLoadingDownloadAggregation } = useMutation({
    mutationKey: ['downloadCSVData'],
    mutationFn: (vals: Omit<IDownloadAggregationMeasurementsProps, 'gpnr'>) =>
      getDownloadAggregationMesurementsV2({ ...vals, gpnr }),
  })

  return { downloadMeasurements, isLoadingDownloadAggregation }
}

export default useDownloadMeasurementsV2
