import { MaxPowerIcon } from '@assets/icons'
import { Box, styled } from '@mui/material'

type LabelIndicator = {
  showSquare?: boolean
  showCircle?: boolean
  showMaxPower?: boolean
  color?: string
  border?: string
}

export const StyledSquare = styled(Box)({
  marginRight: 4,
  width: 9,
  height: 9,
  borderRadius: 2,
})

export const StyledCircle = styled(Box)({
  marginRight: 8,
  width: 8,
  height: 8,
  borderRadius: 50,
})

const LabelIndicator = ({ color, showCircle, showMaxPower, showSquare, border }: LabelIndicator) => {
  if (showSquare)
    return <StyledSquare sx={{ backgroundColor: color }} border={border && `1px dashed ${border}`} my={1} />

  if (showCircle) return <StyledCircle sx={{ backgroundColor: color }} mb={0.5} />

  if (showMaxPower) return <MaxPowerIcon sx={{ width: 12, height: 12, mr: 1 }} />

  return null
}

export default LabelIndicator
