import { Stack, Typography, Button, StackProps } from '@mui/material'
import Show from '@src/ui/wrappers/Show/Show'
import { useTranslation } from 'react-i18next'

const NoAccountControls = ({
  onClick,
  title,
  isExternal,
  stackProps,
}: {
  onClick?: () => void
  title?: string
  isExternal?: boolean
  stackProps?: StackProps
}) => {
  const { t } = useTranslation()
  return (
    <Stack direction="row" alignItems="center" mt={3} flexWrap="wrap" columnGap={1} {...stackProps}>
      <Typography variant="bodyRegular" sx={{ m: 0 }}>
        {title ?? t('authorization.do_you_need_help')}
      </Typography>
      <Show when={!isExternal}>
        <Button variant="text" disableTouchRipple size="large" sx={{ p: '0 !important' }} onClick={onClick}>
          {t('authorization.contact_support')}
        </Button>
      </Show>
      <Show when={!!isExternal}>
        <Button
          LinkComponent="a"
          variant="text"
          disableTouchRipple
          size="large"
          sx={{ p: '0 !important' }}
          target="_blank"
          href="https://www.ckw.ch/kontakt"
        >
          {t('authorization.contact_support')}
        </Button>
      </Show>
    </Stack>
  )
}

export default NoAccountControls
