import { chartPallete } from '@assets/themes/palette'
import theme from '@assets/themes/theme'
import { ChartNoData } from '@components/ChartNoData/ChartNoData'
import MaxPowerDot from '@components/DtpMaxPower/MaxPowerDot'
import ChartSkeleton from '@components/Skeletons/ChartSkeleton'
import { chartAxisEnum } from '@enums/chartAxisFormats'
import {
  handleSetTooltipPositionAndOpenArea,
  calculateTicks,
  calculateMaxMinPropertyDomain,
} from '@helpers/chartAreaFunctions'
import { isNil } from '@helpers/functional'
import { IMeasurement } from '@interfaces/measurements'
import { useMediaQuery } from '@mui/material'
import dayjs from 'dayjs'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  ResponsiveContainer,
  CartesianGrid,
  XAxis,
  YAxis,
  Label,
  Area,
  AreaChart,
  ReferenceDot,
  Tooltip,
} from 'recharts'
import { CategoricalChartState } from 'recharts/types/chart/types'

import MaxPowerTooltip from '../tooltips/MaxPowerTooltip'
import Dot from './components/Dot'
import MaxPowerDotLabel from './components/MaxPowerDotLabel'
import NoDataDot from './components/NoDataDot'
import { chartStyles, formatXAxisDate, xAxisInterval, addNoDataValues } from './utils'

type DtpMaxPowerChartProps = {
  xAxisDate: chartAxisEnum
  data: IMeasurement[]
  isLoading: boolean
  noDataDateValue?: string
  handleOnDateChange?: CallableFunction
  setTooltipPosition?: CallableFunction
  tooltipPosition: {
    x: number
    y: number
  }
  unit: string
}

const DtpMaxPowerChart = ({
  data = [],
  isLoading,
  unit,
  tooltipPosition,
  setTooltipPosition,
  noDataDateValue,
  handleOnDateChange,
  xAxisDate,
}: DtpMaxPowerChartProps) => {
  const { t, i18n } = useTranslation()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const isSmallMobile = useMediaQuery(theme.breakpoints.between('xs', 500))
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'lg'))
  const isWeb = useMediaQuery(theme.breakpoints.up('lg'))
  const formatInterval = xAxisInterval(xAxisDate, isMobile, isTablet, isWeb)
  const viewportWidth = Math.max(document.documentElement.clientWidth, window.innerWidth)
  const TOOLTIP_INITIAL_X = isMobile ? 0 : 500
  const TOOLTIP_INITIAL_Y = isMobile ? 0 : 10
  const wrapperStylePositionTooltip = { left: 40, top: 0 }
  const [tooltipOpen, setTooltipOpen] = useState(false)

  const tickFormatter = (value: string, index: number) => formatXAxisDate(value, index, xAxisDate, isMobile)

  const { min, max } = calculateMaxMinPropertyDomain(data, chartAxisEnum.HOUR ? 'maxPower' : 'maxPowerInMonth')

  const ticks = calculateTicks(min, max, 4)

  const chartMidHeight = (min + max) / 2
  const mappedData = addNoDataValues(data, xAxisDate, chartMidHeight)
  const handleChartClick = (e: CategoricalChartState) =>
    handleSetTooltipPositionAndOpenArea({
      e,
      isMobile,
      isTablet,
      isSmallMobile,
      viewportWidth,
      tooltipOpen,
      TOOLTIP_INITIAL_X,
      TOOLTIP_INITIAL_Y,
      setTooltipOpen,
      setTooltipPosition,
    })

  const maxPowerItem = mappedData.find((item) => item.maxPowerInMonth)
  const formatPerAggregation = xAxisDate === chartAxisEnum.HOUR ? 'HH' : 'DD'
  const labelPosition =
    Number(dayjs(maxPowerItem?.timestampStartOfPeriod).format(formatPerAggregation)) > (isMobile ? 12 : 20)
  const isGerman = i18n.language?.includes('de')
  const labelPlacement = labelPosition ? (isGerman ? -165 : -125) : 14

  if (isLoading) return <ChartSkeleton chartAxis="ChartArea" />
  if (!data.length) return <ChartNoData currentDate={dayjs(noDataDateValue).format('DD. MMMM YYYY')} />

  return (
    <ResponsiveContainer height={296} width="100%">
      <AreaChart
        role="aria-maxpower-graph"
        data={mappedData}
        margin={{ top: 50, bottom: 50, right: 0 }}
        onClick={handleChartClick}
        style={{ cursor: 'pointer' }}
        stackOffset="expand"
      >
        <CartesianGrid stroke={chartPallete.area.gridStroke} strokeWidth={1} strokeDasharray="0" vertical={false} />
        <YAxis ticks={ticks} domain={[min, max]} {...chartStyles(isMobile)}>
          <Label style={{ fontSize: isMobile ? 11 : 14 }} dy={-110} dx={18}>
            {unit}
          </Label>
        </YAxis>
        <XAxis
          tickMargin={15}
          id="timestampStartOfPeriod"
          dataKey="timestampStartOfPeriod"
          tickFormatter={tickFormatter}
          interval={formatInterval}
          {...chartStyles(isMobile)}
          padding={{ right: 20 }}
        />

        <Tooltip
          cursor={{ fill: 'transparent' }}
          content={
            tooltipPosition.x !== TOOLTIP_INITIAL_X || isSmallMobile ? (
              <MaxPowerTooltip
                tooltipPosition={tooltipPosition.x}
                setTooltipOpen={setTooltipOpen}
                unit={unit}
                handleOnDateChange={handleOnDateChange}
                xAxisDate={xAxisDate}
              />
            ) : undefined
          }
          position={tooltipPosition}
          allowEscapeViewBox={{ x: false, y: true }}
          trigger="click"
          active={tooltipOpen}
          animationDuration={300}
          wrapperStyle={isSmallMobile ? wrapperStylePositionTooltip : undefined}
          filterNull={false}
        />

        <Area
          dataKey={xAxisDate === chartAxisEnum.MONTH ? 'maxPowerInMonth' : 'maxPower'}
          stroke={theme.palette.gray['300']}
          strokeWidth={1}
          dot={({ key, ...rest }) => <Dot {...rest} key={key} />}
          activeDot={({ key, ...rest }) => <Dot {...rest} key={key} />}
          fill="transparent"
        />

        <Area
          dataKey={'noDataValue'}
          stroke={theme.palette.gray['300']}
          strokeWidth={1}
          dot={({ key, ...rest }) => <NoDataDot {...rest} key={key} />}
          activeDot={({ key, ...rest }) => <NoDataDot {...rest} key={key} />}
          fill="transparent"
        />

        {mappedData.map(({ maxPowerInMonth, timestampStartOfPeriod, noDataValue }) => {
          if (!isNil(maxPowerInMonth) && isNil(noDataValue)) {
            return (
              <ReferenceDot
                r={6}
                key={timestampStartOfPeriod}
                x={timestampStartOfPeriod}
                y={maxPowerInMonth}
                shape={<MaxPowerDot />}
                ifOverflow="extendDomain"
              />
            )
          }
        })}

        {xAxisDate !== chartAxisEnum.MONTH &&
          mappedData.map(({ maxPowerInMonth, timestampStartOfPeriod }) => {
            if (!isNil(maxPowerInMonth)) {
              return MaxPowerDotLabel({
                timestampStartOfPeriod,
                maxPowerInMonth,
                min,
                key: maxPowerInMonth,
                labelPlacement,
                t: t,
              })
            }
          })}
      </AreaChart>
    </ResponsiveContainer>
  )
}

export default DtpMaxPowerChart
