import { ArrowRightIcon, EBillIcon, QrCodeIcon, TransferIcon } from '@assets/icons'
import theme from '@assets/themes/theme'
import DtpInvoiceSettingsCardSkeleton from '@components/Skeletons/DtpInvoiceSettingsCardSkeleton'
import styled from '@emotion/styled'
import { Card, CardHeader, Chip, IconButton, Stack } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { To, useNavigate } from 'react-router-dom'

export interface IDtpInvoiceSettingsCardProps {
  type: 'EBILL' | 'LSV' | 'MANUELL' | 'none'
  status?: 'active' | 'recommended' | 'none'
  title?: string
  description?: string
  icon?: React.ReactNode
  link?: To
  isLoading?: boolean
}

const DtpInvoiceSettingsCard = ({
  type,
  status,
  title,
  description,
  icon,
  link,
  isLoading,
}: IDtpInvoiceSettingsCardProps) => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const StyledCard = styled(Card)(() => ({
    maxWidth: '550px',
    cursor: 'pointer',
    boxShadow: 'none',
    border: '1px solid',
    borderColor: status === 'active' ? theme.palette.primary.main : '#EFF0EF',
    backgroundColor: status === 'active' ? theme.palette.primary.light : '#FAFAFA',
  }))
  const StyledChip = styled(Chip)(() => ({
    color: status === 'active' ? theme.palette.primary.main : '#007DB2',
    fontFamily: 'GothamMedium',
    backgroundColor: status === 'active' ? theme.palette.primary.light : '#D7EEF7',
    border: '1px solid',
    borderColor: status === 'active' ? theme.palette.primary.main : '#007DB2',
    lineHeight: '140%',
    padding: theme.spacing(0, 1.5),
  }))

  const returnIconBasedOnType = () => {
    switch (type) {
      case 'EBILL':
        return <EBillIcon />
      case 'LSV':
        return <TransferIcon />
      case 'MANUELL':
        return <QrCodeIcon />
      default:
        return null
    }
  }

  const returnChipBasedOnStatus = () => {
    switch (status) {
      case 'active':
        return <StyledChip label={t('invoices.active')} size="small" />
      case 'recommended':
        return <StyledChip label={t('invoices.recommended')} size="small" />
      default:
        return null
    }
  }

  if (isLoading) return <DtpInvoiceSettingsCardSkeleton />

  return (
    <StyledCard onClick={() => navigate(link as To)}>
      <CardHeader
        avatar={icon ? icon : returnIconBasedOnType()}
        titleTypographyProps={{
          variant: 'bodyRegular',
          fontWeight: 500,
          fontFamily: 'GothamMedium',
        }}
        title={
          <Stack direction="row" spacing={1} gap={1}>
            {title} {returnChipBasedOnStatus()}
          </Stack>
        }
        subheader={description}
        action={
          link && (
            <IconButton
              sx={{
                color: theme.palette.text.primary,
                ':hover': {
                  background: 'none',
                },
              }}
              onClick={() => navigate(link as To)}
            >
              <ArrowRightIcon />
            </IconButton>
          )
        }
        sx={{
          '& .MuiCardHeader-action': {
            alignSelf: 'center',
          },
          '.MuiCardHeader-avatar': {
            alignSelf: 'start',
            maxWidth: '50px',
            mt: 1.2,
          },
        }}
      />
    </StyledCard>
  )
}

export default DtpInvoiceSettingsCard
