import theme from '@assets/themes/theme'
import DtpInfoTooltip from '@components/DtpInfoTooltip/DtpInfoTooltip'
import { Stack, Typography, Box, useMediaQuery } from '@mui/material'
import { styled } from '@mui/material/styles'
import Show from '@src/ui/wrappers/Show/Show'

interface ProsumerItemProps {
  label: string
  value?: string | number | null
  unit: string
  color?: string
  border?: string
  showSquare?: boolean
  showTooltip?: boolean
  tooltipContent?: React.ReactNode | string
  componentVariant?: 'ProsumerLable' | 'ChartTooltip'
  valueName?: string
}

const localNumberFormat = new Intl.NumberFormat('de-CH', {
  style: 'decimal',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
})

const ProsumerLableStyle = {
  padding: 0,
  display: 'flex',
  flexDirection: 'column',
  [theme.breakpoints.down('md')]: {
    minWidth: '130px',
    flexDirection: 'column-reverse',
    alignItems: 'center',
    textAlign: 'center',
  },
}
const ChartTooltipStyle = {
  marginTop: 0.5,
  marginBottom: 0.5,
}

const ChartTooltipLabelStyle = {
  fontSize: 16,
  fontWeight: '200',
  whiteSpace: 'nowrap',
}

const tooltipLabelErrorStyle = {
  fontSize: 16,
  fontWeight: '200',
  whiteSpace: 'wrap',
  ml: 1,
}

const StyledSquare = styled(Box)({
  marginRight: 4,
  width: 9,
  height: 9,
  borderRadius: 2,
})

const LabelComponent = ({
  unit,
  numberValue,
  isMobile,
}: {
  unit: string
  numberValue?: string | number | null
  isMobile: boolean
}) => {
  const unitElement = isMobile ? (
    <Typography display="inline" component="span" variant="caption" color={theme.palette.text.primary}>
      {unit}
    </Typography>
  ) : (
    <>{unit}</>
  )

  return unit === 'CHF' ? (
    <>
      {unitElement} {` ${numberValue}`}
    </>
  ) : (
    <>
      {`${numberValue} `} {unitElement}
    </>
  )
}

const ProsumerItem = ({
  label,
  value,
  unit,
  color,
  border,
  showSquare,
  componentVariant,
  showTooltip,
  tooltipContent,
  valueName,
}: ProsumerItemProps) => {
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const isNumber = typeof Number(value) === 'number' && !isNaN(Number(value))
  const numberValue = isNumber ? localNumberFormat.format(Number(value)) : value
  const isPositive = Number(numberValue) > 0
  const numberValueSign = isNumber && isPositive && valueName === 'balance' ? `+${numberValue}` : numberValue
  const consumptionSumMobile = isMobile && componentVariant === 'ProsumerLable'

  const displayValue = <LabelComponent unit={unit} numberValue={numberValueSign} isMobile={consumptionSumMobile} />

  return (
    <Stack direction="row" sx={{ ...(componentVariant === 'ProsumerLable' ? ProsumerLableStyle : ChartTooltipStyle) }}>
      <Stack direction="row" alignItems={'center'}>
        <Box flexDirection="row" alignContent="start" height="100%">
          <Show when={showSquare ?? false}>
            <StyledSquare sx={{ backgroundColor: color }} border={border && `1px dashed ${border}`} my="10px" />
          </Show>
          <Show when={!showSquare && componentVariant === 'ProsumerLable'}>
            <StyledSquare sx={{ width: 0, mx: 0 }} my="10px" />
          </Show>
        </Box>
        <Box
          flexDirection="row"
          alignContent={componentVariant === 'ProsumerLable' ? 'center' : 'space-between'}
          height="100%"
        >
          <Typography
            sx={{
              my: 0,
              ml: showSquare && componentVariant === 'ChartTooltip' ? 0.5 : 0,
              mt: 0.25,
              mr: componentVariant === 'ChartTooltip' ? 1 : 0,
              typography: componentVariant === 'ChartTooltip' ? 'bodyRegular' : 'body2',
              ...(componentVariant === 'ChartTooltip' ? ChartTooltipLabelStyle : ''),
              whiteSpace: 'wrap',
            }}
          >
            {label}
          </Typography>
        </Box>
        <Show when={componentVariant === 'ProsumerLable' && !!showTooltip}>
          <DtpInfoTooltip
            id={label}
            tooltipWidth="360px"
            title={
              <Stack direction="row" alignItems="center">
                <Show when={showSquare ?? false}>
                  <StyledSquare sx={{ backgroundColor: color }} border={border && `1px dashed ${border}`} my="10px" />
                </Show>
                <Typography sx={{ my: 0 }}>{label}</Typography>
              </Stack>
            }
            content={tooltipContent}
          />
        </Show>
      </Stack>
      <Box sx={componentVariant === 'ChartTooltip' ? { marginLeft: 'auto', my: 'auto' } : { marginLeft: 0, my: 0 }}>
        <Typography
          sx={{
            my: 0,
            width: 'max-content',
            typography: componentVariant === 'ChartTooltip' ? 'bodyTextEmphasized' : 'bodyRegularEmphasized',
            ...(componentVariant === 'ChartTooltip' ? ChartTooltipLabelStyle : ''),
            ...(value === null ? tooltipLabelErrorStyle : ''),
          }}
        >
          {value === null ? '― *' : displayValue}
        </Typography>
      </Box>
    </Stack>
  )
}

export default ProsumerItem
