import { AlertTriangleGreenIcon } from '@assets/icons'
import theme from '@assets/themes/theme'
import DtpCarousel from '@components/DtpCarousel/DtpCarousel'
import { DtpContractDropdown } from '@components/DtpContractDropdown/DtpContractDropdown'
import { ISelectItem } from '@components/DtpDropdown/DtpDropdown'
import DtpEnergyProduct from '@components/DtpEnergyProduct/DtpEnergyProduct'
import DtpEnergyProductModal from '@components/DtpEnergyProductModal/DtpEnergyProductModal'
import DtpProductInfo from '@components/DtpProductInfo/DtpProductInfo'
import EnergyProductSkeleton from '@components/Skeletons/EnergyProductSkeleton'
import { isNil } from '@helpers/functional'
import useLocationsData, { IAddOn } from '@hooks/useLocations/useLocationsData'
import useNotification from '@hooks/useNotification/useNotification'
import { useProduct } from '@hooks/useProduct/useProduct'
import { Box, Container, Skeleton, Typography, Stack, useMediaQuery } from '@mui/material'
import Show from '@src/ui/wrappers/Show/Show'
import dayjs from 'dayjs'
import { useEffect, useState, useCallback } from 'react'
import { Trans, useTranslation } from 'react-i18next'

const EnergyProduct = () => {
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const isTablet = useMediaQuery(theme.breakpoints.between('md', 'lg'))

  const { t } = useTranslation()
  const notification = useNotification()

  const [selectedItem, setSelectedItem] = useState<ISelectItem<IAddOn>>({ label: '', value: '' })
  const [modalState, setModalState] = useState<{ open: boolean; rateCategory: string; productKey: string }>({
    open: false,
    rateCategory: '',
    productKey: '',
  })

  const { activeLocations, isLoading: isLoadingContracts } = useLocationsData()

  const {
    products,
    isLoading: isLoadingProducts,
    updateProduct,
    isUpdatingProduct,
    contractStart,
    consumptionBase,
    powerBase,
  } = useProduct({ contractId: selectedItem.addOn?.contractId ?? '' })

  const consumptionContracts = activeLocations.filter(
    (contract) => contract.addOn?.contractId && contract.addOn.deliveryType !== 'feedIn'
  )

  const handleModal = useCallback((rateCategory = '', productKey = '', open = false) => {
    setModalState({ rateCategory, productKey, open })
  }, [])

  const handleChangeProduct = async () => {
    try {
      await updateProduct({ rateCategory: modalState.rateCategory })
      notification(
        'success',
        t('product.product_change_successful_title'),
        t('product.product_change_successful_description')
      )
      handleModal()
    } catch (error) {
      notification('error', t('product.saving_error_title'), t('product.saving_error_description'))
    }
  }

  useEffect(() => {
    if (consumptionContracts.length && !selectedItem.value) {
      setSelectedItem(consumptionContracts[0])
    }
  }, [consumptionContracts, selectedItem.value])

  const activeProductIndex = products.findIndex((product) => product.isCurrent)
  const noPrices = products.some((product) => isNil(product.amountPerYear) && isNil(product.amountPerMonth))

  const hasContracts = consumptionContracts.length > 1
  const showNoProducts = hasContracts && !products.length && !isLoadingContracts && !isLoadingProducts
  const showProducts = products.length && !isLoadingContracts && !isLoadingProducts
  const showCarouselCards = (isMobile || isTablet) && activeProductIndex !== -1
  const skeletonCount = isMobile ? 1 : isTablet ? 2 : 3
  const isLoading = isLoadingContracts || isLoadingProducts
  const contractStartedInPreviousYear = dayjs(contractStart).isSame(dayjs().subtract(1, 'year'), 'year')

  return (
    <Box pt={{ xs: 3, md: 9 }} sx={{ background: theme.palette.background.paper }}>
      <Box pb={{ xs: consumptionContracts.length ? 9 : 2, sm: 11 }}>
        <Container>
          <Typography variant={'heading2'}>{t('navigation.energy_product')}</Typography>

          <Show when={!isLoading && hasContracts}>
            <DtpContractDropdown
              activeContracts={consumptionContracts}
              expiredContracts={[]}
              value={selectedItem.value}
              onLocationsSelect={setSelectedItem}
              isLoading={isLoadingContracts}
            />
          </Show>

          <Show when={isLoading}>
            <Skeleton animation="wave" variant="rounded" width={320} height={46} />
          </Show>

          <Show when={showNoProducts}>
            <Box maxWidth={390} mx="auto" textAlign="center" mt={{ xs: 9, sm: 11 }}>
              <AlertTriangleGreenIcon sx={{ width: 64, height: 64 }} />
              <Typography variant="bodyRegular">{t('product.product_change_not_available')}</Typography>
            </Box>
          </Show>

          <Stack direction="row" justifyContent="space-between" gap={6} mt={{ xs: 3, sm: 6 }}>
            <Show when={!!showProducts && !isMobile && !isTablet}>
              {products.map(({ productKey, isCurrent, amountPerMonth, amountPerYear, rateCategory }) => {
                return (
                  <DtpEnergyProduct
                    key={productKey}
                    productKey={productKey}
                    isCurrent={isCurrent}
                    amountPerMonth={amountPerMonth ?? ''}
                    amountPerYear={amountPerYear ?? ''}
                    highlighted={isCurrent}
                    onClick={() => handleModal(rateCategory, productKey, true)}
                  />
                )
              })}
            </Show>

            <Show when={isLoading}>
              {Array.from({ length: skeletonCount }, (_, index) => (
                <EnergyProductSkeleton key={index} />
              ))}
            </Show>
          </Stack>

          <Show when={showCarouselCards}>
            <DtpCarousel slidesToShow={isMobile ? 1 : 2} initialSlide={isMobile ? activeProductIndex : 0}>
              {products.map(({ productKey, isCurrent, amountPerMonth, amountPerYear, rateCategory }) => {
                return (
                  <DtpEnergyProduct
                    key={productKey}
                    productKey={productKey}
                    isCurrent={isCurrent}
                    amountPerMonth={amountPerMonth ?? ''}
                    amountPerYear={amountPerYear ?? ''}
                    highlighted={isCurrent}
                    onClick={() => handleModal(rateCategory, productKey, true)}
                  />
                )
              })}
            </DtpCarousel>
          </Show>

          <Show when={isLoading}>
            <Stack gap={0.75} mt={8} maxWidth={416}>
              {Array.from({ length: 3 }, (_, index) => (
                <Skeleton key={index} animation="wave" variant="rounded" width={`${100 - index * 5}%`} height={14} />
              ))}
            </Stack>
          </Show>

          <Show when={products.length >= 1 && !isLoading && !noPrices}>
            <Typography variant="caption" sx={{ mt: 8, maxWidth: 416 }}>
              <Trans
                i18nKey="product.price_calculation_info"
                values={{
                  date: dayjs(contractStart).format('DD.MM.YYYY'),
                  consumption: consumptionBase,
                  output: powerBase,
                  additionalInfo: contractStartedInPreviousYear
                    ? t('product.additional_info_previous', { date: dayjs(contractStart).format('DD.MM.YYYY') })
                    : t('product.additional_info_other'),
                }}
              />
            </Typography>
          </Show>
        </Container>
      </Box>

      <Box bgcolor={theme.palette.gray['50']} pt={{ xs: 3, sm: 6 }} pb={{ xs: 5, sm: 8 }}>
        <DtpProductInfo />
      </Box>

      <DtpEnergyProductModal
        productKey={modalState.productKey}
        open={modalState.open}
        onClose={handleModal}
        onConfirm={handleChangeProduct}
        isUpdating={isUpdatingProduct}
      />
    </Box>
  )
}

export default EnergyProduct
