import { create } from 'zustand'
import { createJSONStorage, persist } from 'zustand/middleware'

interface IOnboarding {
  userEmail: string
  onboardingOpen: boolean
  infoAlert: boolean
  hasEmailChanged: boolean
}

interface IOnboardingStore {
  currentOnboarding: IOnboarding
  setOnboarding: (onboarding: IOnboarding) => void
}

const initialOnboarding = {
  userEmail: '',
  onboardingOpen: false,
  infoAlert: false,
  hasEmailChanged: false,
}

export const useOnboardingStore = create<IOnboardingStore>()(
  persist(
    (set) => ({
      currentOnboarding: initialOnboarding,
      setOnboarding: (onboarding) => set({ currentOnboarding: onboarding }),
    }),
    {
      name: 'onboarding-store',
      storage: createJSONStorage(() => localStorage),
    }
  )
)
