import theme from '@assets/themes/theme'
import dayjs from 'dayjs'

export const getSelectableDateRange = ({ lastInvoice, nextInvoice }: { lastInvoice: string; nextInvoice: string }) => {
  const lastInvoiceDate = dayjs(lastInvoice)
  const nextInvoiceDate = dayjs(nextInvoice)
  const today = dayjs()

  if (today.isSame(lastInvoiceDate, 'day')) {
    return { minDate: '', maxDate: '', isDisabled: true }
  }

  const minDate = lastInvoiceDate.add(1, 'day')
  const maxDate = today.isBefore(nextInvoiceDate, 'day') ? today : nextInvoiceDate.subtract(1, 'day')

  return {
    minDate: minDate.format('YYYY-MM-DD'),
    maxDate: maxDate.format('YYYY-MM-DD'),
    isDisabled: false,
  }
}

export const interimInvoiceModalStyles = {
  '.MuiPaper-root': {
    padding: theme.spacing(6, 8, 7, 8),
    margin: 3,
    maxHeight: '100%',
    maxWidth: 784,
    width: '100%',
    height: 'initial',
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(4, 2),
      margin: 0,
      height: '100vh',
      maxWidth: '100%',
    },
  },
}
