import * as Sentry from '@sentry/react'

/* eslint-disable */
process.env.REACT_APP_NODE_ENV !== 'development' &&
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: process.env.REACT_APP_NODE_ENV,
    integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
    tracesSampleRate: 0.2,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 0.2,
    ignoreErrors: [
      'NotFoundError',
      'Network Error',
      'vars.hotjar.com',
      'doubleclick.net',
      'Blocked a frame with origin',
      "TypeError: can't access dead object",
      'The object can not be found here.',
      'window.refreshUI is not a function',
      'Non-Error promise rejection captured',
      'Invalid call to runtime.sendMessage(). Tab not found.',
      "NotFoundError: Failed to execute 'removeChild' on 'Node': The node to be removed is not a child of this node.",
      "NotFoundError: Failed to execute 'insertBefore' on 'Node': The node before which the new node is to be inserted is not a child of this node.",
      'Object captured as promise rejection with keys: details, message, status',
    ],
    denyUrls: [
      /dtp-appc-ff\.azconfig\.io/i,
      /dtp-dev-appc-ff\.azconfig\.io/i,
      /googletagmanager\.com/i,
      /hotjar/i,
      /trk\.ckw\.ch/i,
      /eu-mobile\.events\.data\.microsoft\.com/i,
    ],
  })
