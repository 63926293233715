import { Stack, Typography } from '@mui/material'
import Show from '@src/ui/wrappers/Show/Show'

import RowIcon from './RowIcon'

export type TooltipContentRowProps = {
  label: string
  value: string | null
  unit: string
  lableIconColor: string
  lableIconVariant?: 'square' | 'circle' | 'noData' | 'forecast' | 'maxPower'
}

const TooltipContentRow = ({ lableIconColor, label, value, unit, lableIconVariant }: TooltipContentRowProps) => {
  const valueToLocaleString = Number(value).toLocaleString('de-CH', {
    style: 'decimal',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })

  const positionOfUnitAndValueBasedOnUnit =
    unit === 'CHF' ? `${unit} ${valueToLocaleString}` : `${valueToLocaleString} ${unit}`

  return (
    <Stack direction="row" alignItems={'center'} spacing={1} rowGap={1} justifyContent="space-between">
      <Stack direction="row" spacing={1} alignItems="center" justifyContent={'flex-start'}>
        <Show when={!!lableIconVariant}>
          <RowIcon lableIconColor={lableIconColor} lableIconVariant={lableIconVariant} />
        </Show>

        <Typography variant="bodyRegular" fontSize={16} lineHeight={'160%'}>
          {label}
        </Typography>
      </Stack>

      <Typography
        variant="bodyRegular"
        component="span"
        justifyItems="flex-end"
        fontFamily="GothamMedium"
        fontSize={16}
        lineHeight={'160%'}
      >
        {value !== null ? positionOfUnitAndValueBasedOnUnit : '-*'}
      </Typography>
    </Stack>
  )
}

export default TooltipContentRow
