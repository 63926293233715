import { EnvelopeIcon, SettingsIcon } from '@assets/icons'
import DtpErrorCard from '@components/DtpErrorCard/DtpErrorCard'
import DtpInvoiceCardGroup from '@components/DtpInvoiceCardGroup/DtpInvoiceCardGroup'
import DtpInvoiceExplanationLink from '@components/DtpInvoiceExplanationLink/DtpInvoiceExplanationLink'
import DtpNoOpenInvoices from '@components/DtpNoOpenInvoices/DtpNoOpenInvoices'
import DtpPaidInvoiceAccordionGroup from '@components/DtpPaidInvoiceAccordionGroup/DtpPaidInvoiceAccordionGroup'
import DtpInvoiceCardSkeleton from '@components/Skeletons/DtpInvoiceCardSkeleton'
import DtpPaidInvoicesAccordionSkeleton from '@components/Skeletons/DtpPaidInvoicesAccordionSkeleton'
import { useInvoices } from '@hooks/useInvoices/useInvoices'
import useLocationsData from '@hooks/useLocations/useLocationsData'
import { Button, Container, Divider, Skeleton, Stack, Typography } from '@mui/material'
import Show from '@src/ui/wrappers/Show/Show'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'

const Invoices = () => {
  const { t } = useTranslation()
  const { activeLocations, expiredLocations, isLoading: isLoadingLocations } = useLocationsData()
  const {
    invoices: openInvoicesData,
    isLoading: isLoadingOpenInvoices,
    isError: openInvoicesError,
    refetch: refetchOpenInvoices,
  } = useInvoices({ status: 'NOTPAID' })
  const {
    invoices: paidInvoicesData,
    isLoading: isLoadingPaidInvoices,
    isError: paidInvoicesError,
    refetch: refetchPaidInvoices,
  } = useInvoices({ status: 'PAID' })
  const navigate = useNavigate()
  const openInvoices = Object.values(openInvoicesData).flat()
  const paidInvoices = Object.entries(paidInvoicesData).reverse()

  return (
    <Container>
      <Typography variant="heading2" sx={{ mt: { xs: 1.5, sm: 5, md: 10 }, mb: { xs: 1, sm: 3, md: 6 } }}>
        {t('contracts.invoices')}
      </Typography>

      <Show when={isLoadingOpenInvoices}>
        <Stack justifyContent="center" minHeight={245}>
          <Skeleton variant="rounded" width={120} height={31} sx={{ mb: 4.75 }} />
          <DtpInvoiceCardSkeleton />
        </Stack>
      </Show>

      <Show when={!openInvoicesError && !isLoadingOpenInvoices}>
        <DtpInvoiceCardGroup invoices={openInvoices} />
        <Show when={!openInvoices.length}>
          <DtpNoOpenInvoices />
        </Show>
      </Show>

      <Show when={openInvoicesError}>
        <Stack mb={{ xs: 3, sm: 7 }} alignItems="center">
          <DtpErrorCard action={refetchOpenInvoices} />
        </Stack>
      </Show>

      <Divider sx={{ mb: 2 }} />

      <Stack width="fit-content" gap={0} ml={-1.5} sx={{ mb: { xs: 2, sm: 5 } }}>
        <Button
          variant="text"
          size="large"
          sx={{ justifyContent: 'start', pl: 1.5 }}
          onClick={() => navigate(t('navigation_url.interim_invoice'))}
          startIcon={<EnvelopeIcon />}
        >
          {t('invoices.request_interim_invoice')}
        </Button>

        <Button
          variant="text"
          size="large"
          sx={{ justifyContent: 'start', pl: 1.5 }}
          onClick={() => navigate(t('navigation_url.invoices_settings'))}
          startIcon={<SettingsIcon />}
        >
          {t('navigation.navi_invoices_settings')}
        </Button>
        <DtpInvoiceExplanationLink />
      </Stack>

      <Show when={isLoadingPaidInvoices}>
        <DtpPaidInvoicesAccordionSkeleton />
      </Show>

      <Show when={!paidInvoicesError && !isLoadingPaidInvoices && !isLoadingLocations && !!paidInvoices.length}>
        <DtpPaidInvoiceAccordionGroup
          activeLocations={activeLocations}
          expiredLocations={expiredLocations}
          invoices={paidInvoices}
        />
      </Show>

      <Show when={paidInvoicesError}>
        <Stack alignItems="center">
          <DtpErrorCard action={refetchPaidInvoices} />
        </Stack>
      </Show>
    </Container>
  )
}

export default Invoices
