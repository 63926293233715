import AccessCodeInput from '@components/DtpConnectAccountWizard/common/AccessCodeInput'
import NoAccountControls from '@components/DtpConnectAccountWizard/common/NoAccountControls'
import { FormSteps } from '@enums/authorization'
import CloseIcon from '@mui/icons-material/Close'
import { Box, Stack, Button, Typography, useTheme, useMediaQuery, IconButton } from '@mui/material'
import Show from '@src/ui/wrappers/Show/Show'
import { FormikTouched, FormikErrors } from 'formik'
import { useTranslation } from 'react-i18next'

export interface IAccessCodeProps {
  goTo: (step: string) => void
  evidence: string
  error: FormikErrors<string>
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  onBlur: (event: React.FocusEvent<HTMLInputElement>) => void
  onSubmit: (event: React.FormEvent<HTMLFormElement>) => void
  touched: FormikTouched<boolean>
  isLoading?: boolean
  onClose?: () => void
}

const AccessCode = ({
  goTo,
  evidence,
  error,
  onChange,
  touched,
  onSubmit,
  onBlur,
  isLoading,
  onClose,
}: IAccessCodeProps) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <form onSubmit={onSubmit} style={{ height: '100%' }}>
      <Stack height="100%">
        <Box mb={0.5}>
          <Stack mb={4} direction="row" justifyContent="space-between" alignItems="flex-start">
            <Typography variant="heading3" sx={{ my: 0, mt: 0.5 }}>
              {t('authorization.enter_access_code')}
            </Typography>

            <IconButton onClick={onClose} size="small">
              <CloseIcon />
            </IconButton>
          </Stack>

          <Typography variant="bodyRegular" sx={{ my: 0, mb: 4 }}>
            {t('authorization.account_access_code_sent')}
          </Typography>
        </Box>

        <Box>
          <AccessCodeInput
            value={evidence}
            onChange={onChange}
            onBlur={onBlur}
            helperText={touched ? error : undefined}
            error={touched && Boolean(error)}
          />

          <Show when={isMobile}>
            <NoAccountControls title={t('authorization.no_customer_number')} isExternal />
          </Show>
        </Box>

        <Stack direction={{ xs: 'column', sm: 'row' }} alignItems="center" mt={{ xs: 4, sm: 4 }} gap={2}>
          <Button variant="contained" size="large" type="submit" fullWidth={isMobile} disabled={!!error || isLoading}>
            {t('authorization.complete')}
          </Button>

          <Button
            variant="outlined"
            size="large"
            fullWidth={isMobile}
            onClick={() => goTo(FormSteps.REQUEST_ACCESS_CODE)}
            disabled={isLoading}
          >
            {t('back')}
          </Button>
        </Stack>

        <Show when={!isMobile}>
          <NoAccountControls title={t('authorization.no_customer_number')} isExternal />
        </Show>
      </Stack>
    </form>
  )
}

export default AccessCode
