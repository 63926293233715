import { Typography, Stack, Box, useTheme } from '@mui/material'

interface IMethodSelectCard {
  icon?: React.ReactNode
  title?: string
  description?: string | React.ReactNode
  descriptionColor?: string
}

const PostOption = ({ icon, title }: IMethodSelectCard) => {
  const theme = useTheme()
  return (
    <Stack
      direction="row"
      alignItems="center"
      bgcolor={theme.palette.gray['50']}
      border={`1px solid ${theme.palette.gray['100']}`}
      py={2.7}
      px={3}
      borderRadius={1}
      columnGap={2}
      my={4}
    >
      <Box mt={0.4}>{icon}</Box>
      <Box>
        <Typography variant="bodyRegularEmphasized">{title}</Typography>
      </Box>
    </Stack>
  )
}

export default PostOption
