import { chartPallete } from '@assets/themes/palette'
import theme from '@assets/themes/theme'
import ChartLabel from '@components/ChartLabel/ChartLabel'
import { TFunction } from 'i18next'
import { Label, ReferenceLine } from 'recharts'

type MaxPowerDotLabelProps = {
  timestampStartOfPeriod: string
  maxPowerInMonth: string | number
  min: number
  key: string | number
  labelPlacement: number
  t: TFunction
}

const MaxPowerDotLabel = ({
  timestampStartOfPeriod,
  maxPowerInMonth,
  min,
  key,
  labelPlacement,
  t,
}: MaxPowerDotLabelProps) => {
  if (maxPowerInMonth && timestampStartOfPeriod)
    return (
      <ReferenceLine
        key={key}
        segment={[
          { x: timestampStartOfPeriod, y: min },
          {
            x: timestampStartOfPeriod,
            y: maxPowerInMonth,
          },
        ]}
        strokeWidth={1}
        stroke={chartPallete.maxPower.bar}
        ifOverflow="extendDomain"
      >
        <Label
          dy={labelPlacement}
          position="insideTopRight"
          value={t('consumption.maxPower')}
          content={
            <ChartLabel
              color={theme.palette.text.secondary}
              borderColor={chartPallete.maxPower.stroke}
              font="GothamBook"
            />
          }
        />
      </ReferenceLine>
    )
  return null
}

export default MaxPowerDotLabel
