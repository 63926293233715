import ChartLoader from '@components/ChartLoader/ChartLoader'
import DtpChartTooltipWrapper from '@components/DtpChartTooltip/DtpChartTooltipWrapper'
import { chartAxisEnum } from '@enums/chartAxisFormats'
import { handleTooltipOpenAndPositionCompare } from '@helpers/chartBarFunctions'
import { IMeasurement, MeasurmentsType } from '@interfaces/measurements'
import { Box, Stack, Typography, useMediaQuery, useTheme } from '@mui/material'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Bar, BarChart, ReferenceLine, ResponsiveContainer, Tooltip, XAxis } from 'recharts'

import { ComparisonBar } from './ComparisonBar'

export interface IComparisonChartV2Props {
  data: (IMeasurement | undefined)[]
  measurementType: MeasurmentsType
  unit?: 'kWh' | 'CHF'
  isLoading?: boolean
  aggregationType?: 'month' | 'year'
  handleOnDateChange?: CallableFunction
}

export const tickFormatter = (value: string | number, type: MeasurmentsType, unit: 'kWh' | 'CHF') => {
  if (!value || !type || !unit) return ''

  const localStringPrefixSignForProsumer = type !== 'consumption' && Number(value) > 0 ? '+' : ''

  const localStringValue = Number(value).toLocaleString('de-CH', {
    style: 'decimal',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })

  const isProsumer = type === 'prosumer'

  return isProsumer
    ? `${localStringPrefixSignForProsumer}${localStringValue} ${unit}`
    : Number(value)
      ? `${localStringValue} ${unit}`
      : ''
}

const ComparisonChartV2 = ({
  data,
  unit = 'kWh',
  isLoading = false,
  aggregationType = 'month',
  handleOnDateChange,
  measurementType,
}: IComparisonChartV2Props) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const { t } = useTranslation()
  const viewportWidth = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0)
  const TOOLTIP_INITIAL_X = 1
  const TOOLTIP_INITIAL_Y = isMobile ? 0 : -240
  const [tooltipPosition, setTooltipPosition] = useState({ x: TOOLTIP_INITIAL_X, y: TOOLTIP_INITIAL_Y })
  const [tooltipOpen, setTooltipOpen] = useState(false)

  const [wrapperStylePositionTooltip, setWrapperStylePositionTooltip] = useState<{ left: number; top: number }>()
  const isSmallMobile = useMediaQuery(theme.breakpoints.between('xs', 500))

  const onMouseClick = (e: {
    tooltipPosition: object
    forecast: string
    timestampStartOfPeriod: string
    x: number
    y: number
    width: number
    height: number
  }) => {
    return handleTooltipOpenAndPositionCompare({
      e,
      tooltipOpen,
      setTooltipOpen,
      setTooltipPosition,
      isSmallMobile,
      setWrapperStylePositionTooltip,
      TOOLTIP_INITIAL_X,
      TOOLTIP_INITIAL_Y,
      viewportWidth,
    })
  }

  if (isLoading) {
    return <ChartLoader sx={{ minHeight: 200 }} />
  }
  if (!data.length)
    return (
      <Box pb={4.5}>
        <Stack
          justifyContent="center"
          alignItems="center"
          width="100%"
          height={165}
          textAlign="center"
          borderBottom="2px solid #BFC4BF"
        >
          <Typography color="#4F544F" variant="bodySmallEmphasized" width={isMobile ? '70%' : '50%'}>
            {t('power_consumption_page.error_message_no_data_available') +
              ', ' +
              t('power_consumption_page.comparison_no_for_combination')}
          </Typography>
        </Stack>
      </Box>
    )
  return (
    <ResponsiveContainer width="100%" height={200}>
      <BarChart data={data} barSize={41}>
        <XAxis
          tickLine={false}
          dataKey={'value'}
          padding="no-gap"
          fontSize={isMobile ? 14 : 16}
          fill="#BFC4BF"
          tickFormatter={(value) => tickFormatter(value, measurementType, unit)}
          axisLine={false}
        />
        <Tooltip
          cursor={{ fill: 'transparent' }}
          content={
            tooltipPosition.x !== TOOLTIP_INITIAL_X || isSmallMobile ? (
              <DtpChartTooltipWrapper
                handleOnDateChange={handleOnDateChange}
                setTooltipOpen={setTooltipOpen}
                unit={unit}
                chartType="comparison"
                aggregationType={aggregationType === 'month' ? chartAxisEnum.MONTH : chartAxisEnum.YEAR}
                id={`comparationTooltip-${aggregationType}`}
                mesurementsType={measurementType}
              />
            ) : undefined
          }
          position={tooltipPosition}
          allowEscapeViewBox={{ x: false, y: true }}
          trigger={'click'}
          active={tooltipOpen}
          animationDuration={300}
          wrapperStyle={isSmallMobile ? wrapperStylePositionTooltip : undefined}
        />

        <ReferenceLine y={0} stroke="#BFC4BF" />

        <Bar
          activeBar={false}
          dataKey={'value'}
          stackId="a"
          className="comparationBar"
          shape={
            <ComparisonBar aggregationType={aggregationType} measurementType={measurementType} isMobile={isMobile} />
          }
          onClick={onMouseClick}
        />

        <Bar
          dataKey="forecast"
          stackId="a"
          stroke="#B161A280"
          strokeDasharray="2 2"
          strokeWidth={1}
          fill="#B161A233"
          onClick={onMouseClick}
          cursor="pointer"
        />
      </BarChart>
    </ResponsiveContainer>
  )
}

export default ComparisonChartV2
