import { AppConfigurationClient } from '@azure/app-configuration'
import { createContext, useState, useEffect } from 'react'

const FEATURE_FLAG_KEYS = {
  storybook: '.appconfig.featureflag/dtp-portal-storybook',
  maintenance: '.appconfig.featureflag/.appconfig.featureflag/dtp-portal-maintenance',
  onboardingModal: '.appconfig.featureflag/dtp-portal-onboarding',
} as const

interface IFeatureFlags {
  [key: string]: boolean
}
interface FeatureToggleContextType {
  features: IFeatureFlags
}

export const FeatureToggleContext = createContext<FeatureToggleContextType>({
  features: {},
})

const connectionString = process.env.REACT_APP_CONFIG_CONNECTION_STRING as string
const client = new AppConfigurationClient(connectionString)

const FeatureToggleProvider = ({ children }: { children: React.ReactNode }) => {
  const [features, setFeatures] = useState<IFeatureFlags>({})

  useEffect(() => {
    const getConfig = async () => {
      try {
        const configSettings = []
        const featureFlags: IFeatureFlags = {}

        for await (const setting of client.listConfigurationSettings()) {
          configSettings.push(setting)
        }

        for (const [key, value] of Object.entries(FEATURE_FLAG_KEYS)) {
          const flag = configSettings.find((flag) => flag?.key === value)
          featureFlags[key] = flag?.value ? JSON.parse(flag.value).enabled : false
        }
        setFeatures(featureFlags)
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error)
      }
    }

    getConfig()
  }, [setFeatures])

  return <FeatureToggleContext.Provider value={{ features }}>{children}</FeatureToggleContext.Provider>
}

export default FeatureToggleProvider
