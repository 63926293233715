import { getComparisonDataV2 } from '@api/queries'
import { comparisonDataPrepack } from '@helpers/comparisonFunctions'
import {
  getAggregationsStartEndDate,
  getDefaultMinMaxDateFromContract,
  getDefaultComparationFromContract,
} from '@helpers/dateFunctions'
import useAccounts from '@hooks/useAccounts/useAccounts'
import useLocationsData from '@hooks/useLocations/useLocationsData'
import { IComparisonApiResponse, MeasurmentsType } from '@interfaces/measurements'
import { useQuery } from '@tanstack/react-query'
import dayjs from 'dayjs'
import { useMemo, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

type UseComparisonProps = {
  datePickerValue: string
  aggregationType: 'month' | 'year'
}

const useComparisonV2 = ({ datePickerValue, aggregationType }: UseComparisonProps) => {
  const { t } = useTranslation()
  const [period1, setPeriod1] = useState('')
  const [period2, setPeriod2] = useState('')

  const { activeAccount } = useAccounts()
  const { selectedItem } = useLocationsData()

  const gpnr = activeAccount?.gpnr
  const locationId = selectedItem.addOn?.locationId
  const contractId = selectedItem.addOn?.contractId
  const meterId = selectedItem.addOn?.meterId
  const contractStartDate = selectedItem.addOn?.supplyStartDate
  const contractEndDate = selectedItem.addOn?.supplyEndDate

  const params = {
    gpnr,
    locationId: locationId ?? undefined,
    meterId: meterId ?? undefined,
    contractId: contractId ?? undefined,
    aggregation: aggregationType.toUpperCase(),
    period1: { ...getAggregationsStartEndDate(period1, aggregationType) },
    period2: { ...getAggregationsStartEndDate(period2, aggregationType) },
  }

  const isEnabled =
    !!gpnr &&
    (!!locationId || (!!meterId && !!contractId)) &&
    !!datePickerValue &&
    !!dayjs(period1).isValid() &&
    !!dayjs(period2).isValid()

  const { data, isLoading: isLoadingComparation } = useQuery<IComparisonApiResponse>({
    queryKey: ['comparisonData', { ...params }],
    queryFn: () => getComparisonDataV2(params),
    enabled: isEnabled,
    gcTime: 0,
  })

  const measurementType = data?.body?.type ?? ('prosumer' as MeasurmentsType)

  const comparationData = useMemo(() => {
    if (!data?.body) return []
    return comparisonDataPrepack(data.body)
  }, [data])

  const { minDate, maxDate } = getDefaultMinMaxDateFromContract(
    aggregationType,
    datePickerValue,
    contractEndDate ?? '',
    contractStartDate ?? '',
    'YYYY-MM-DD'
  )
  const formatPeriod = (period: string) => dayjs(period).format(aggregationType === 'month' ? 'MMMM YYYY' : 'YYYY')

  const comparisonDescription = useMemo(
    () =>
      t('power_consumption_page.comparison_description.description', {
        consumptionType: t(`power_consumption_page.comparison_description.${measurementType}`),
        date1: formatPeriod(period1),
        date2: formatPeriod(period2),
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [measurementType, period1, period2]
  )

  useEffect(() => {
    if (contractEndDate && contractStartDate && maxDate) {
      const { firstPeriod, secondPeriod } = getDefaultComparationFromContract(
        contractStartDate,
        contractEndDate,
        maxDate
      )
      setPeriod1(firstPeriod)
      setPeriod2(secondPeriod)
    }
  }, [contractEndDate, contractStartDate, maxDate, meterId])

  return {
    comparationData,
    isLoadingComparation,
    measurementType,
    period1,
    setPeriod1,
    period2,
    setPeriod2,
    minDate,
    maxDate,
    comparisonDescription,
  }
}

export default useComparisonV2
