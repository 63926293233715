import ChartArea from '@components/ChartArea/ChartArea'
import DtpDateButton from '@components/DtpDateButton/DtpDateButton'
import { DtpDatePicker } from '@components/DtpDatePicker/DtpDatePicker'
import DtpDownloadCsvV2 from '@components/DtpDownloadCsvV2/DtpDownloadCsvV2'
import DtpSwitch from '@components/DtpSwitch/DtpSwitch'
import { EnergyConsumptionLabel } from '@components/EnergyConsumptionLabel/EnergyConsumptionLabel'
import { FeedInLabel } from '@components/FeedInLabel/FeedInLabel'
import { ProsumerLable } from '@components/ProsumerLable/ProsumerLable'
import ConsumptionSumSkeleton from '@components/Skeletons/ConsumptionSumSkeleton'
import ChartBarV2 from '@components/charts/ChartBarV2/ChartBarV2'
import DtpMaxPowerChart from '@components/charts/DtpMaxPowerChart/DtpMaxPowerChart'
import DtpReactivePowerAreaChart from '@components/charts/DtpReactivePowerAreaChart/DtpReactivePowerAreaChart'
import DtpReactivePowerBarChart from '@components/charts/DtpReactivePowerBarChart/DtpReactivePowerBarChart'
import { AggregationEnum } from '@enums/aggregationTypes'
import { chartAxisEnum } from '@enums/chartAxisFormats'
import { IAddOn } from '@hooks/useLocations/useLocationsData'
import { IAggregationMeasurements, MeasurmentsType } from '@interfaces/measurements'
import { Stack, Skeleton } from '@mui/material'
import Grid from '@mui/material/Grid2'
import { DateView } from '@mui/x-date-pickers'
import Show from '@src/ui/wrappers/Show/Show'
import dayjs from 'dayjs'
import { useState } from 'react'

export type ExtendedDateView = DateView | 'years'

interface IChartControlV2Props {
  addOn: IAddOn
  contractStartDate?: string
  contractEndDate?: string
  chartType: ExtendedDateView
  startModalDate: string
  endModalDate: string
  handleModalOpen: () => void
  customAggregation?: boolean
  setActiveTab?: (activeTab: number) => void
  setSelectedFromConsumptionDetail?: (selectedFromConsumptionDetail: string) => void
  isLoading: boolean
  isLoadingAggregation: boolean
  aggregationData?: IAggregationMeasurements
  aggregationType: AggregationEnum
  selectedFromComparison?: string
  setSelectedFromComparison?: CallableFunction
  date: string
  setDate: (date: string) => void
  minimumDatePickerDate: string
  maximumDatePickerDate: string
  datePickerValue: string
  type: MeasurmentsType
  hasChfValues: boolean
  onLabelClick: () => void
  graphType: string | null
}

interface IChartComponents {
  [key: string]: {
    graph: {
      consumption: React.ReactNode
      maxPower?: React.ReactNode
      reactivePower?: React.ReactNode
    }
    consumptionSum: React.ReactNode
    prosumerSum: React.ReactNode
    feedInSum: React.ReactNode
  }
}

const ChartControlV2 = ({
  addOn,
  minimumDatePickerDate,
  maximumDatePickerDate,
  datePickerValue,
  chartType,
  setActiveTab,
  startModalDate,
  endModalDate,
  customAggregation = false,
  handleModalOpen,
  setSelectedFromConsumptionDetail,
  isLoading,
  aggregationType,
  selectedFromComparison,
  setSelectedFromComparison,
  aggregationData,
  isLoadingAggregation,
  date,
  setDate,
  type,
  hasChfValues,
  onLabelClick,
  graphType,
}: IChartControlV2Props) => {
  const initialTooltipPosition = { x: 500, y: -80 }
  const [tooltipPosition, setTooltipPosition] = useState(initialTooltipPosition)

  const customAggregationNoDataLabel = customAggregation
    ? startModalDate
    : maximumDatePickerDate || aggregationData?.measurements[0].timestampStartOfPeriod

  const noDataLabel = date.length > 0 ? dayjs(date).format('YYYY-MM-DD') : customAggregationNoDataLabel

  const maxPowerPerMonth = aggregationData?.maxPowerPerMonth

  const handleOnDateChange = (date: string) => {
    setTooltipPosition(initialTooltipPosition)
    setDate(date)
  }

  const consumptionDetailClick = (value: string, tab: number) => {
    setSelectedFromConsumptionDetail && setSelectedFromConsumptionDetail(value)
    setActiveTab && setActiveTab(tab)
  }

  const tooltipTabIndexYear = chartType === 'year' ? 1 : 0
  const tooltipTabIndex = chartType === 'years' ? 2 : tooltipTabIndexYear

  const graphParams = {
    data: aggregationData?.measurements ?? [],
    unit: 'kWh' as 'kWh' | 'CHF',
    isLoading: isLoadingAggregation || isLoading,
    noDataDateValue: noDataLabel,
    averageConsumption: aggregationData?.averageConsumption,
    handleOnDateChange: (value: string) => consumptionDetailClick(value, tooltipTabIndex),
    setTooltipPosition: setTooltipPosition,
    tooltipPosition: tooltipPosition,
    measurementType: type,
  }
  const labelParams = {
    data: aggregationData,
    unit: 'kWh' as 'kWh' | 'CHF',
    isLoading: isLoadingAggregation,
    date: date,
    chartType: chartType,
  }
  const showLabels = aggregationData?.measurements && aggregationData?.measurements.length > 0
  const chartComponents: IChartComponents = {
    day: {
      graph: {
        consumption: (
          <ChartArea
            data={aggregationData?.measurements}
            unit="kWh"
            noDataDateValue={noDataLabel}
            isLoading={isLoadingAggregation || isLoading}
            setTooltipPosition={setTooltipPosition}
            tooltipPosition={tooltipPosition}
            measurementType={type}
            maxPowerPerMonth={maxPowerPerMonth?.[0]}
          />
        ),
        maxPower: <DtpMaxPowerChart {...graphParams} xAxisDate={chartAxisEnum.HOUR} unit="kW" />,
        reactivePower: (
          <DtpReactivePowerAreaChart
            data={aggregationData?.measurements}
            unit="kvarh"
            noDataDateValue={noDataLabel}
            isLoading={isLoadingAggregation || isLoading}
            setTooltipPosition={setTooltipPosition}
            tooltipPosition={tooltipPosition}
            xAxisDate={chartAxisEnum.HOUR}
          />
        ),
      },
      consumptionSum: showLabels && <EnergyConsumptionLabel {...labelParams} onClick={onLabelClick} />,
      prosumerSum: showLabels && <ProsumerLable {...labelParams} />,
      feedInSum: showLabels && <FeedInLabel {...labelParams} />,
    },
    month: {
      graph: {
        consumption: (
          <ChartBarV2 {...graphParams} xAxisDate={chartAxisEnum.DAY} maxPowerPerMonth={maxPowerPerMonth?.[0]} />
        ),
        maxPower: <DtpMaxPowerChart {...graphParams} xAxisDate={chartAxisEnum.DAY} unit="kW" />,
        reactivePower: <DtpReactivePowerBarChart {...graphParams} xAxisDate={chartAxisEnum.DAY} unit="kvarh" />,
      },
      consumptionSum: showLabels && <EnergyConsumptionLabel {...labelParams} onClick={onLabelClick} />,
      prosumerSum: showLabels && <ProsumerLable {...labelParams} />,
      feedInSum: showLabels && <FeedInLabel {...labelParams} />,
    },
    year: {
      graph: {
        consumption: <ChartBarV2 {...graphParams} xAxisDate={chartAxisEnum.MONTH} />,
        maxPower: <DtpMaxPowerChart {...graphParams} xAxisDate={chartAxisEnum.MONTH} unit="kW" />,
        reactivePower: <DtpReactivePowerBarChart {...graphParams} xAxisDate={chartAxisEnum.MONTH} unit="kvarh" />,
      },
      consumptionSum: showLabels && <EnergyConsumptionLabel {...labelParams} showAverageMaxPower />,
      prosumerSum: showLabels && <ProsumerLable {...labelParams} />,
      feedInSum: showLabels && <FeedInLabel {...labelParams} />,
    },
    years: {
      graph: {
        consumption: <ChartBarV2 {...graphParams} xAxisDate={chartAxisEnum.YEAR} />,
      },
      consumptionSum: showLabels && <EnergyConsumptionLabel {...labelParams} />,
      prosumerSum: showLabels && <ProsumerLable {...labelParams} />,
      feedInSum: showLabels && <FeedInLabel {...labelParams} />,
    },
  }

  return (
    <Stack alignItems="flex-start" direction="column" gap={2}>
      <Grid container alignItems="center" width="100%">
        <Grid size={{ xs: 9, sm: 9, md: 3 }} order={{ xs: 1, sm: 1 }} mb={0}>
          <Show when={isLoading}>
            <Skeleton animation="wave" variant="rounded" width={210} height={35} sx={{ mb: 3, mt: 1 }} />
          </Show>
          <Show when={!isLoading}>
            <Show when={customAggregation}>
              <DtpDateButton startDate={startModalDate} endDate={endModalDate} onClick={handleModalOpen} />
            </Show>
            <Show when={chartType !== 'years' && !customAggregation}>
              <DtpDatePicker
                viewType={chartType as DateView}
                value={datePickerValue}
                onChange={handleOnDateChange}
                maxDate={maximumDatePickerDate}
                minDate={minimumDatePickerDate}
                withPrevNext
                disabled={isLoadingAggregation}
                selectedFromComparison={selectedFromComparison}
                setSelectedFromComparison={setSelectedFromComparison}
              />
            </Show>
          </Show>
        </Grid>
        <Grid
          size={{ xs: 12, md: 'grow' }}
          order={{ xs: 3, md: 3 }}
          container
          justifyContent={{ xs: 'center', md: 'end' }}
        >
          <Show when={type === 'feedIn'}>{chartComponents[chartType].feedInSum}</Show>
          <Show when={type === 'prosumer'}>{chartComponents[chartType].prosumerSum}</Show>
          <Show when={type === 'consumption'}>{chartComponents[chartType].consumptionSum}</Show>
          <Show when={isLoadingAggregation || isLoading}>
            <ConsumptionSumSkeleton />
          </Show>
        </Grid>
        <Show when={hasChfValues}>
          <Grid size={{ xs: 3, md: 2, lg: 1.8 }} order={{ xs: 2, md: 3 }} container justifyContent="end">
            <DtpSwitch
              switchOptions={[
                { value: 'kWh', label: 'kWh' },
                { value: 'chf', label: 'CHF' },
              ]}
            />
          </Grid>
        </Show>
        <Grid size={12} p={0} order={{ xs: 2, md: 3 }} height={300}>
          {chartComponents[chartType].graph[graphType as 'consumption' | 'maxPower' | 'reactivePower']}
        </Grid>
        <Grid size={12} order={{ xs: 4, sm: 4 }} display="flex" justifyContent={{ xs: 'start', sm: 'end' }}>
          <DtpDownloadCsvV2
            customAggregation={customAggregation}
            aggregation={aggregationType}
            chartType={chartType}
            startModalDate={startModalDate}
            endModalDate={endModalDate}
            date={date}
            addOn={addOn}
            timestampStartOfPeriod={aggregationData?.measurements[0]?.timestampStartOfPeriod}
            isDisabled={!aggregationData?.measurements.length || graphType === 'maxPower'}
            isLoading={isLoading || isLoadingAggregation}
          />
        </Grid>
      </Grid>
    </Stack>
  )
}

export default ChartControlV2
