import { updateEnergyProduct } from '@api/mutations'
import { getProducts } from '@api/queries'
import useAccounts from '@hooks/useAccounts/useAccounts'
import { useMutation, useQuery } from '@tanstack/react-query'

export const useProduct = ({ contractId }: { contractId: string }) => {
  const { activeAccount } = useAccounts()
  const gpnr = activeAccount?.gpnr

  const {
    data,
    isLoading,
    isFetching,
    isPending,
    refetch: refetchProducts,
  } = useQuery({
    queryKey: ['aggregationYear', gpnr, contractId],
    queryFn: () => getProducts({ gpnr, contractId }),
    enabled: !!gpnr && !!contractId,
    gcTime: 0,
  })

  const products = data?.body?.products ?? []
  const contractStart = data?.body?.contractStart
  const consumptionBase = data?.body?.consumptionBase
  const powerBase = data?.body?.powerBase

  const { mutateAsync: updateProduct, isPending: isUpdatingProduct } = useMutation({
    mutationKey: ['updateProduct'],
    mutationFn: ({ rateCategory }: { rateCategory: string }) => updateEnergyProduct({ gpnr, contractId, rateCategory }),
    onSuccess: refetchProducts,
  })

  return {
    products,
    isLoading,
    isFetching,
    isPending,
    updateProduct,
    isUpdatingProduct,
    contractStart,
    consumptionBase,
    powerBase,
  }
}
