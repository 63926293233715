import { MessageIcon, EmailIcon, MailEnvelopeIcon } from '@assets/icons'
import CodeDeliveryOptionsSkeleton from '@components/Skeletons/CodeDeliveryOptionsSkeleton'
import { AuthOptions } from '@enums/authorization'
import { Typography, Stack } from '@mui/material'
import Show from '@src/ui/wrappers/Show/Show'
import React from 'react'
import { useTranslation } from 'react-i18next'

import MethodSelectCard from './MethodSelectCard'
import PostOption from './PostOption'
import SelectDeliveryOption from './SelectDeliveryOption'

interface ICodeDeliveryOptionsProps {
  availableDeliveryOptions: {
    [key: string]: boolean | string
  }
  authOptions: Partial<Record<AuthOptions, string>>
  selectedOption: AuthOptions
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  isLoading: boolean
}

const CodeDeliveryOptions = ({
  availableDeliveryOptions,
  authOptions,
  selectedOption,
  onChange,
  isLoading,
}: ICodeDeliveryOptionsProps) => {
  const { t } = useTranslation()

  if (isLoading) return <CodeDeliveryOptionsSkeleton />

  return (
    <>
      <Show when={!availableDeliveryOptions.none}>
        <Typography variant="bodyRegular" sx={{ m: 0 }}>
          {t(
            `authorization.${availableDeliveryOptions.single ? 'explanation_account_access_code' : 'explanation_account_access_code_multiple_options'}`
          )}
        </Typography>
      </Show>

      <Show when={!!availableDeliveryOptions.multiple}>
        <Stack mt={4} mb={2}>
          <SelectDeliveryOption authOptions={authOptions} selectedOption={selectedOption} onChange={onChange} />
        </Stack>
      </Show>

      <Show when={availableDeliveryOptions.single === AuthOptions.MOBILE_NUMBER}>
        <MethodSelectCard
          icon={<MessageIcon />}
          title={t('authorization.by_sms')}
          description={`${t('authorization.to')} ${authOptions.mobileNumber}`}
        />
      </Show>

      <Show when={availableDeliveryOptions.single === AuthOptions.PHONE_ORGANIZATION}>
        <MethodSelectCard
          icon={<MessageIcon />}
          title={t('authorization.by_sms')}
          description={`${t('authorization.to')} ${authOptions.phoneOrganization}`}
        />
      </Show>

      <Show when={availableDeliveryOptions.single === AuthOptions.USER_EMAIL}>
        <MethodSelectCard
          icon={<EmailIcon />}
          title={t('authorization.by_e_mail')}
          description={`${t('authorization.to')} ${authOptions.userEmail}`}
        />
      </Show>

      <Show when={availableDeliveryOptions.single === AuthOptions.INVOICE_EMAIL}>
        <MethodSelectCard
          icon={<EmailIcon />}
          title={t('authorization.by_e_mail')}
          description={`${t('authorization.to')} ${authOptions.invoiceEmail}`}
        />
      </Show>

      <Show when={!!availableDeliveryOptions.post}>
        <Typography variant="bodyRegular" sx={{ mb: 0 }}>
          {t('authorization.explanation_account_access_code_letter')}
        </Typography>
        <PostOption icon={<MailEnvelopeIcon />} title={t('authorization.by_post')} />
      </Show>

      <Show when={!!availableDeliveryOptions.none && !availableDeliveryOptions.post}>
        <Typography variant="bodyRegular" sx={{ opacity: 0.7, mb: 4 }}>
          {t('authorization.no_access_code_options')}
        </Typography>
      </Show>
    </>
  )
}

export default CodeDeliveryOptions
