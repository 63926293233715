import MeinRegioStrom from '@assets/images/ckw-solardach-luzern.webp'
import ClassicStrom from '@assets/images/ckw-stausee-goschenen.webp'
import BudgetStrom from '@assets/images/ckw-unterwerk-mettlen.webp'
import { switchComponentBackground } from '@assets/themes/palette'
import theme from '@assets/themes/theme'
import {
  Box,
  Button,
  Container,
  List,
  ListItem,
  Stack,
  styled,
  Tab,
  Tabs,
  Typography,
  useMediaQuery,
} from '@mui/material'
import { useEffect, useRef, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useLocation } from 'react-router'

interface ITabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

type CustomRefType =
  | (HTMLDivElement & {
      children: HTMLElement[]
    })
  | null

const CustomTabPanel = (props: ITabPanelProps) => {
  const { children, value, index, ...other } = props

  return (
    <Box role="tabpanel" hidden={value !== index} id={`tabpanel-${index}`} aria-labelledby={`tab-${index}`} {...other}>
      {value === index && <>{children}</>}
    </Box>
  )
}

const CustomTabControl = styled(Tabs)({
  position: 'relative',
  borderRadius: 28,
  '& .MuiTabs-flexContainer': {
    borderRadius: 28,
    padding: 8,
    height: 56,
    gap: 8,
    width: 'max-content',
  },
  '& .MuiTabs-indicator': {
    backgroundColor: 'transparent',
  },
  '&::before,&::after': {
    content: '""',
    pointerEvents: 'none',
    zIndex: 3,
    width: '29px',
    display: 'block',
    position: 'absolute',
    top: 0,
    bottom: 0,
  },
  '&::before': {
    background: `linear-gradient(90deg, ${switchComponentBackground} 60%, transparent 100%)`,
    left: 0,
  },
  '&::after': {
    background: `linear-gradient(270deg, ${switchComponentBackground} 60%, transparent 100%)`,
    right: 0,
  },
})

const CustomTab = styled((props: { label: string }) => <Tab disableRipple {...props} />)(({ theme }) => ({
  borderRadius: theme.borderRadius.large,
  fontSize: theme.typography.pxToRem(16),
  padding: '8px 24px',
  minHeight: 40,
  textTransform: 'none',
  ':hover': {
    background: theme.palette.primary.light,
  },
  '&.Mui-selected': {
    zIndex: 4,
    color: theme.palette.primary.contrastText,
    backgroundImage: theme.palette.background.gradient,
    ':hover': {
      backgroundImage: theme.palette.background.gradient,
    },
  },
}))

const CustomParagraph = <Typography variant="bodyRegular"></Typography>

const CustomList = <List sx={{ listStyleType: 'disc', listStylePosition: 'inside' }}></List>

const CustomListItem = (
  <ListItem
    sx={{
      display: 'list-item',
      fontFamily: 'GothamBook',
      fontWeight: 400,
      fontSize: 18,
    }}
  ></ListItem>
)

const CustomButton = ({ children }: { children: string }) => (
  <Button
    href="https://www.ckw.ch/energie/strom/stromprodukte/privat"
    target="_blank"
    rel="noopener"
    variant="outlined"
    size="medium"
    sx={{ px: '24px', height: '48px' }}
  >
    {children}
  </Button>
)

const TabButton = styled(Button)(({ theme }) => ({
  width: '100%',
  '&.MuiButton-root': {
    borderRadius: theme.borderRadius.large,
    fontSize: '16px',
    padding: '8px',
    minHeight: 40,
    textTransform: 'none',
    minWidth: 'max-content',
    color: theme.palette.text.secondary,
  },
  '&:hover': {
    background: theme.palette.primary.light,
  },
  '&:active': {
    color: theme.palette.primary.contrastText,
    backgroundImage: theme.palette.background.gradient,
  },
  '&.MuiButton-contained': {
    color: theme.palette.primary.contrastText,
    '&:hover': {
      color: theme.palette.primary.contrastText,
      background: theme.palette.background.gradient,
    },
    '&:active': {
      color: theme.palette.primary.contrastText,
      backgroundImage: theme.palette.background.gradient,
    },
  },
}))

export const getProductTab = (productName: string) => {
  switch (productName) {
    case 'BudgetStorm':
      return 0
    case 'ClassicStrom':
      return 1
    case 'MeinRegioStrom':
      return 2
    default:
      return 0
  }
}

const DtpProductInfo = () => {
  const { t } = useTranslation()
  const { state } = useLocation()
  const [activeTab, setActiveTab] = useState(1)
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const scrollerRef = useRef<CustomRefType>(null)
  const scrollIntoViewRef = useRef<HTMLDivElement>(null)
  const handleChange = (_: React.SyntheticEvent, value: number) => {
    setActiveTab(value)
  }

  const handleScrollToItem = (index: number) => {
    if (!scrollerRef.current) return
    setActiveTab(index)
    const container = scrollerRef.current
    const item = scrollerRef.current.children[index]

    if (container && item) {
      const containerWidth = container.offsetWidth
      const itemWidth = item.offsetWidth

      const itemOffsetLeft = item.offsetLeft
      const scrollPosition = itemOffsetLeft - containerWidth / 2 + itemWidth / 2

      scrollerRef?.current.scrollTo({
        left: scrollPosition,
        behavior: 'smooth',
      })
    }
  }

  useEffect(() => {
    if (state) {
      const tab = getProductTab(state.productName)
      setActiveTab(tab)
      setTimeout(() => {
        scrollIntoViewRef.current?.scrollIntoView({ behavior: 'smooth' })
      }, 100)
    }
  }, [state])

  return (
    <Container>
      <Box maxWidth={784} mx="auto" ref={scrollIntoViewRef}>
        <Typography textAlign="center" variant="heading2">
          {t('product.electricity_products_title')}
        </Typography>
        {isMobile ? (
          <Box
            sx={{
              my: '32px',
              position: 'relative',
              width: '100%',
              overflow: 'hidden',
              backgroundColor: switchComponentBackground,
              borderRadius: 28,
              border: '1px solid #D5D8D5',
              '&::before,&::after': {
                content: '""',
                pointerEvents: 'none',
                zIndex: 3,
                width: '29px',
                display: 'block',
                position: 'absolute',
                top: 0,
                bottom: 0,
              },
              '&::before':
                (activeTab !== 0 && {
                  background: `linear-gradient(90deg, ${switchComponentBackground} 60%, transparent 100%)`,
                  left: 0,
                }) ||
                '',
              '&::after':
                (activeTab !== 2 && {
                  background: `linear-gradient(270deg, ${switchComponentBackground} 60%, transparent 100%)`,
                  right: 0,
                }) ||
                '',
            }}
          >
            <Stack
              ref={scrollerRef}
              flexDirection="row"
              id="scrollContainer"
              gap={1}
              p={1}
              sx={{
                overflowX: 'auto',
                scrollBehavior: 'smooth',
                scrollbarWidth: 'none',
                '&::-webkit-scrollbar': { display: 'none' },
              }}
            >
              <TabButton
                disableRipple
                size="small"
                id={activeTab === 0 ? 'active' : 'tab0'}
                variant={activeTab === 0 ? 'contained' : 'text'}
                onClick={() => handleScrollToItem(0)}
              >
                {t('product.product_budgetstrom')}
              </TabButton>
              <TabButton
                disableRipple
                size="small"
                id={activeTab === 1 ? 'active' : 'tab1'}
                variant={activeTab === 1 ? 'contained' : 'text'}
                onClick={() => handleScrollToItem(1)}
              >
                {t('product.product_classicstrom')}
              </TabButton>
              <TabButton
                disableRipple
                size="small"
                id={activeTab === 2 ? 'active' : 'tab2'}
                variant={activeTab === 2 ? 'contained' : 'text'}
                onClick={() => handleScrollToItem(2)}
              >
                {t('product.product_meinregiostrom')}
              </TabButton>
            </Stack>
          </Box>
        ) : (
          <Box
            mx="auto"
            my="32px"
            maxWidth="max-content"
            sx={{
              backgroundColor: switchComponentBackground,
              borderRadius: 28,
              border: '1px solid #D5D8D5',
            }}
          >
            <CustomTabControl
              value={activeTab}
              onChange={handleChange}
              variant="scrollable"
              scrollButtons={false}
              selectionFollowsFocus
            >
              <CustomTab label={t('product.product_budgetstrom')} />
              <CustomTab label={t('product.product_classicstrom')} />
              <CustomTab label={t('product.product_meinregiostrom')} />
            </CustomTabControl>
          </Box>
        )}
        <CustomTabPanel key={0} value={activeTab} index={0}>
          <Box
            component="img"
            width="100%"
            height={234}
            src={BudgetStrom}
            style={{ objectFit: 'cover' }}
            alt="CKW Unterwerk Mettlen"
          />
          <Box sx={{ my: 3 }}>
            <Trans
              i18nKey="product.budgetstrom_description"
              components={{
                p: CustomParagraph,
                ul: CustomList,
                li: CustomListItem,
                sub: <sub></sub>,
              }}
            />
          </Box>
          <Box>
            <Typography variant="heading4">{t('product.budgetstrom_section2_title')}</Typography>
            <Typography sx={{ my: 3 }} variant="bodyRegular">
              {t('product.budgetstrom_section2_text')}
            </Typography>
            <CustomButton>{t('product.more_information')}</CustomButton>
          </Box>
        </CustomTabPanel>
        <CustomTabPanel key={1} value={activeTab} index={1}>
          <Box
            component="img"
            width="100%"
            height={234}
            src={ClassicStrom}
            style={{ objectFit: 'cover' }}
            alt="CKW Stausee Göschenen"
          />
          <Box sx={{ my: 3 }}>
            <Trans
              i18nKey="product.classicstrom_description"
              components={{
                p: CustomParagraph,
                ul: CustomList,
                li: CustomListItem,
                sub: <sub></sub>,
              }}
            />
          </Box>
          <Box>
            <Typography variant="heading4">{t('product.classicstrom_section2_title')}</Typography>
            <Typography sx={{ my: 3 }} variant="bodyRegular">
              {t('product.classicstrom_section2_text')}
            </Typography>
            <CustomButton>{t('product.more_information')}</CustomButton>
          </Box>
        </CustomTabPanel>
        <CustomTabPanel key={2} value={activeTab} index={2}>
          <Box
            component="img"
            width="100%"
            height={234}
            src={MeinRegioStrom}
            style={{ objectFit: 'cover' }}
            alt="CKW Solardach Luzern"
          />
          <Box sx={{ my: 3 }}>
            <Trans
              i18nKey="product.meinregiostrom_description"
              components={{
                p: CustomParagraph,
                ul: CustomList,
                li: CustomListItem,
              }}
            />
          </Box>
          <Box>
            <Typography variant="heading4">{t('product.meinregiostrom_section2_title')}</Typography>
            <Typography sx={{ my: 3 }} variant="bodyRegular">
              {t('product.meinregiostrom_section2_text')}
            </Typography>
            <CustomButton>{t('product.more_information')}</CustomButton>
          </Box>
        </CustomTabPanel>
      </Box>
    </Container>
  )
}

export default DtpProductInfo
