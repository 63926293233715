import { Typography, Button, Box } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'

const ContractsUnavailable = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  return (
    <Box maxWidth={678}>
      <Typography variant="bodyRegular" sx={{ mt: 0, mb: { xs: 5, sm: 6 } }}>
        {t('interim_invoice.no_active_contracts_interim_invoice_message')}
      </Typography>
      <Button variant="contained" size="large" onClick={() => navigate(t('navigation_url.invoices'))}>
        {t('interim_invoice.back_to_overview')}
      </Button>
    </Box>
  )
}

export default ContractsUnavailable
