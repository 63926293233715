import ArrowForward from '@mui/icons-material/ArrowForward'
import CloseIcon from '@mui/icons-material/Close'
import { Stack, Box, Typography, Button, IconButton, SxProps } from '@mui/material'
import Show from '@src/ui/wrappers/Show/Show'
import React from 'react'

export interface IDtpInfoAlertProps {
  handleClose: () => void
  icon?: React.ReactNode
  message?: string | React.ReactNode
  learnMoreLink?: string
  learnMoreText?: string
  wrapperSx?: SxProps
  linkSx?: SxProps
}

const DtpInfoAlert = ({
  handleClose,
  icon,
  linkSx,
  wrapperSx,
  message,
  learnMoreLink,
  learnMoreText,
}: IDtpInfoAlertProps) => {
  return (
    <Stack
      sx={
        wrapperSx ?? {
          flexDirection: 'row',
          justifyContent: 'space-between',
          bgcolor: '#FFF7D7',
          display: 'flex',
          alignItems: 'center',
          px: 3,
          py: 1,
          my: 2,
          fontFamily: 'Montserrat',
        }
      }
    >
      <Stack direction="row" gap={2} alignItems="center">
        {icon}
        <Stack
          direction="row"
          alignItems="center"
          gap={{
            xs: 1,
            md: 3,
          }}
          flexWrap="nowrap"
          fontSize="18px"
        >
          <Typography component="span" variant="bodyRegular" color="inherit" fontFamily="inherit">
            {message}
          </Typography>

          <Show when={!!learnMoreLink && !!learnMoreText}>
            <Button
              variant="text"
              color="inherit"
              endIcon={<ArrowForward />}
              LinkComponent="a"
              href={learnMoreLink ?? ''}
              target="_blank"
              sx={linkSx ?? { padding: { xs: 0 }, justifyContent: 'start', fontSize: 'inherit' }}
            >
              {learnMoreText}
            </Button>
          </Show>
        </Stack>
      </Stack>
      <Box width="fit-content" display="inline-flex">
        <IconButton color="inherit" onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </Box>
    </Stack>
  )
}

export default DtpInfoAlert
