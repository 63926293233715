import { requestInterimInvoiceInfo } from '@api/mutations'
import { getInterimInvoice } from '@api/queries'
import useAccounts from '@hooks/useAccounts/useAccounts'
import { IRequestInterimInfoProps } from '@interfaces/invoices'
import { useMutation, useQuery } from '@tanstack/react-query'

export const useInterimInvoice = ({ premiseId }: { premiseId: string }) => {
  const { activeAccount } = useAccounts()
  const gpnr = activeAccount.gpnr

  const {
    data: interimInvoice,
    isLoading,
    refetch: refetchInterimInvoice,
  } = useQuery({
    queryKey: ['interimInvoice', gpnr, premiseId],
    queryFn: () => getInterimInvoice({ gpnr, premiseId }),
    enabled: !!gpnr && !!premiseId,
    gcTime: 0,
  })

  const { mutateAsync: requestInterimInvoice, isPending: isRequestingInterimInvoice } = useMutation({
    mutationKey: ['requestInterimInvoice'],
    mutationFn: (values: Omit<IRequestInterimInfoProps, 'gpnr'>) => requestInterimInvoiceInfo({ ...values, gpnr }),
    onSuccess: refetchInterimInvoice,
  })

  return { interimInvoice: interimInvoice?.body, isLoading, requestInterimInvoice, isRequestingInterimInvoice }
}
