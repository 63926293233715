import theme from '@assets/themes/theme'
import { Box, Stack, Skeleton, styled, useMediaQuery, Paper, Divider } from '@mui/material'

const EnergyProductCard = styled(Box)(({ theme }) => ({
  position: 'relative',
  marginTop: 25,
  width: '100%',
  [theme.breakpoints.down('sm')]: {
    maxWidth: 'unset',
  },
}))

const EnergyProductSkeleton = () => {
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  return (
    <EnergyProductCard sx={{ height: isMobile ? '550px' : '630px' }}>
      <Paper
        sx={{
          display: 'flex',
          flexDirection: 'column',
          mt: 0,
          pt: 5,
          px: 4,
          pb: 4,
          border: '1px solid #D5D8D5',
          height: '100%',
        }}
      >
        <Skeleton width={'70%'} height={65} />
        <Stack gap={2} mt={4}>
          <Skeleton variant="rounded" width={'89%'} height={18} />
          <Skeleton variant="rounded" width={'89%'} height={18} />
          <Skeleton variant="rounded" width={'70%'} height={18} />
          <Skeleton variant="rounded" width={'75%'} height={18} />
          <Skeleton variant="rounded" width={'75%'} height={18} />
        </Stack>
        <Stack direction="row" gap={2} mt={'auto'}>
          <Skeleton variant="rounded" width={'70%'} height={18} />
          <Skeleton variant="rounded" width={65} height={18} />
        </Stack>
        <Divider sx={{ my: 2 }} />
        <Stack direction="row-reverse" gap={2} mb={2}>
          <Skeleton variant="rounded" width={50} height={15} />
          <Skeleton variant="rounded" width={50} height={15} />
        </Stack>
        <Stack direction="row-reverse" gap={2}>
          <Skeleton variant="rounded" width={30} height={10} />
          <Skeleton variant="rounded" width={30} height={10} />
        </Stack>
        <Skeleton variant="rectangular" height={48} sx={{ borderRadius: '99px', marginTop: 'auto' }} />
      </Paper>
    </EnergyProductCard>
  )
}

export default EnergyProductSkeleton
