import { comparisonPalette, prosumerPalette } from '@assets/themes/palette'
import { IMeasurement, MeasurmentsType } from '@interfaces/measurements'
import { useTranslation } from 'react-i18next'
import { Rectangle } from 'recharts'

export type ComparisonBarProps = {
  x?: number
  y?: number
  width?: number
  height?: number
  payload?: IMeasurement
  aggregationType?: 'month' | 'year'
  isMobile: boolean
  measurementType: MeasurmentsType
}

export const ComparisonBar = ({
  x = 0,
  y = 0,
  width = 0,
  height = 0,
  payload,
  aggregationType,
  isMobile,
  measurementType,
}: ComparisonBarProps) => {
  const { t } = useTranslation()

  const consumptionBarColor =
    Number(payload?.value) < 0 ? prosumerPalette.prosumerFeedInNegative : comparisonPalette.consumption.fill

  const barColor = measurementType === 'feedIn' ? prosumerPalette.prosumerFeedIn : consumptionBarColor

  const renderNoDataText = () => {
    const centerX = x + width / 2 || 200
    const centerY = y + height / 2 || 100

    const aggregationText =
      aggregationType === 'year'
        ? t('power_consumption_page.comparison_no_data_year')
        : t('power_consumption_page.comparison_no_data_month')

    const noDataText = `${t('power_consumption_page.error_message_no_data_available')}, ${aggregationText}`
    const words = noDataText.split(' ')
    const chunkSize = isMobile ? 2 : 3

    return words.reduce<React.ReactNode[]>((acc, _, i) => {
      if (i % chunkSize === 0) {
        acc.push(
          <text
            key={i}
            x={centerX}
            y={centerY - 85}
            dy={i === 0 ? -25 : -25 + i * 10}
            fill="#4F544F"
            textAnchor="middle"
            fontSize={isMobile ? 14 : 16}
            dominantBaseline="middle"
          >
            {words.slice(i, i + chunkSize).join(' ')}
          </text>
        )
      }
      return acc
    }, [])
  }

  if (!payload || !width || !height) {
    return <>{renderNoDataText()}</>
  }

  return <Rectangle x={x} y={y} width={width} height={height} fill={barColor} cursor="pointer" />
}
