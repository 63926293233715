import { chartPallete } from '@assets/themes/palette'
import theme from '@assets/themes/theme'
import { ExtendedDateView } from '@components/ChartControlV2/ChartControlV2'
import ConsumptionItemV2 from '@components/ConsumptionItemV2/ConsumptionItemV2'
import ConsumptionSumSkeleton from '@components/Skeletons/ConsumptionSumSkeleton'
import { isNil } from '@helpers/functional'
import { IAggregationMeasurements } from '@interfaces/measurements'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import { Stack, Divider, Paper, useMediaQuery, Typography, styled, Button, Box } from '@mui/material'
import Show from '@src/ui/wrappers/Show/Show'
import dayjs from 'dayjs'
import { ReactElement } from 'react'
import { Trans, useTranslation } from 'react-i18next'

interface IEnergyConsumptionLabelProps {
  date?: string
  data?: Partial<IAggregationMeasurements>
  unit: 'CHF' | 'kWh'
  isLoading?: boolean
  onClick?: () => void
  showAverageMaxPower?: boolean
  chartType: ExtendedDateView
}

const CustomButton = styled(Button)({
  width: 'max-content',
  fontSize: '16px',
  letterSpacing: '0em',
  padding: 0,
  marginTop: theme.spacing(2),
  justifyContent: 'flex-start',
  [theme.breakpoints.down('md')]: {
    padding: 0,
  },
})

const TextField = ({ text }: { text: string | ReactElement }) => {
  return (
    <Typography sx={{ my: 0 }} color={theme.palette.text.primary} fontFamily="GothamBook" variant="bodySmall">
      {text}
    </Typography>
  )
}

export const EnergyConsumptionLabel = ({
  date,
  data,
  unit,
  isLoading,
  onClick,
  showAverageMaxPower,
  chartType,
}: IEnergyConsumptionLabelProps) => {
  const { t } = useTranslation()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const sumEnergy = data?.sumEnergy
  const maxPowerValue = data?.maxPowerPerMonth && data?.maxPowerPerMonth[0].maxPower
  const maxPowerDate = data?.maxPowerPerMonth && data?.maxPowerPerMonth[0].timestamp
  const averageMaxPower = data?.averageMaxPower

  const sumReactivePower = data?.sumReactivePower
  const hasReactivePower = data?.reactivePowerAvailable

  const isSameDay = !isNil(maxPowerDate) && !isNil(date) ? dayjs(maxPowerDate).isSame(dayjs(date), 'day') : false

  if (isLoading) return <ConsumptionSumSkeleton />
  return (
    <Paper
      variant={isMobile ? 'outlined' : 'elevation'}
      elevation={0}
      sx={{
        backgroundColor: 'transparent',
        borderRadius: 0,
        [theme.breakpoints.down('md')]: {
          backgroundColor: theme.palette.grey[50],
          p: 1,
          mx: 0,
          borderRadius: 4,
          width: '100%',
        },
      }}
    >
      <Stack
        direction="row"
        justifyContent={isMobile ? 'space-evenly' : 'end'}
        flexWrap="wrap"
        spacing={isMobile ? 0.5 : 2}
      >
        <ConsumptionItemV2
          label={t('power_consumption_page.energy_consumption_label')}
          value={sumEnergy}
          unit={unit}
          showSquare={true}
          color={chartPallete.bar.highRate.fill}
          showTooltip
          tooltipContent={<TextField text={t('consumption.energy_consumption_explanation')} />}
        />

        <Show when={(!!averageMaxPower || !!maxPowerValue) && !showAverageMaxPower}>
          <Divider orientation="vertical" flexItem />
        </Show>

        <Show when={!!averageMaxPower && !!showAverageMaxPower}>
          <ConsumptionItemV2
            label={`Ø ${t('consumption.max_avg_power')}`}
            value={averageMaxPower}
            unit={unit === 'kWh' ? 'kW' : 'CHF'}
            showTooltip
            tooltipContent={<TextField text={<Trans i18nKey="consumption.average_power_peak_explanation" />} />}
          />
        </Show>

        <Show when={!!maxPowerValue && !showAverageMaxPower}>
          <ConsumptionItemV2
            label={t('power_consumption_page.max_power_peak')}
            value={
              isSameDay || chartType === 'month'
                ? maxPowerValue
                : `${t('consumption.on_date')} ${dayjs(maxPowerDate).format('DD.MM.YY')}`
            }
            unit={unit === 'kWh' ? 'kW' : 'CHF'}
            showMaxPower
            showTooltip
            tooltipContent={<TextField text={<Trans i18nKey="consumption.maximum_power_peek_explanation" />} />}
            customLabelValue={
              !isSameDay && chartType !== 'month' ? (
                <>
                  <TextField
                    text={
                      <Trans
                        i18nKey="consumption.maximum_power_peek_explanation_on_date_time"
                        values={{
                          date: dayjs(maxPowerDate).format('D. MMMM YYYY'),
                          time: dayjs(maxPowerDate).format('HH:mm'),
                        }}
                      />
                    }
                  />
                  <CustomButton variant={'text'} startIcon={<ArrowForwardIcon />} disableTouchRipple onClick={onClick}>
                    {t('consumption.go_to_max_power_peek')}
                  </CustomButton>
                </>
              ) : undefined
            }
          />
        </Show>

        <Show when={!!hasReactivePower && !isMobile}>
          <Divider orientation="vertical" flexItem />
        </Show>

        <Show when={!!hasReactivePower}>
          <Box width={isMobile ? '100%' : 'auto'}>
            <Show when={isMobile}>
              <Divider orientation="horizontal" sx={{ width: '100%', my: '6px' }} />
            </Show>
            <ConsumptionItemV2
              label={t('power_consumption_page.reactive_power')}
              value={sumReactivePower}
              unit={unit === 'kWh' ? 'kvarh' : 'CHF'}
              showSquare
              color="#4F544F"
              showTooltip
              tooltipContent={
                <TextField text={<Trans i18nKey="power_consumption_page.reactive_power_description" />} />
              }
            />
          </Box>
        </Show>
      </Stack>
    </Paper>
  )
}
