import { chartPallete } from '@assets/themes/palette'
import ReferenceAreaForNullValues from '@components/ChartArea/ReferenceAreaForNullValues'
import ReferenceLineForNullValues from '@components/ChartArea/ReferenceLineForNullValues'
import { ChartNoData } from '@components/ChartNoData/ChartNoData'
import ChartSkeleton from '@components/Skeletons/ChartSkeleton'
import { chartAxisEnum } from '@enums/chartAxisFormats'
import {
  handleSetTooltipPositionAndOpenArea,
  calculateMaxMinPropertyDomain,
  calculateTicks,
  groupNullIndexes,
} from '@helpers/chartAreaFunctions'
import { formatTimestamp } from '@helpers/dateFunctions'
import { IMeasurement } from '@interfaces/measurements'
import { useMediaQuery } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import dayjs from 'dayjs'
import { useState, useMemo } from 'react'
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, ResponsiveContainer, Label, Tooltip } from 'recharts'
import { CategoricalChartState } from 'recharts/types/chart/types'

import { chartStyles } from '../DtpMaxPowerChart/utils'
import ReactivePowerTooltip from '../tooltips/ReactivePowerTooltip'

type DtpReactivePowerAreaChart = {
  data?: IMeasurement[]
  unit: 'kvarh'
  noDataDateValue?: string
  isLoading?: boolean
  setTooltipPosition?: CallableFunction
  tooltipPosition: {
    x: number
    y: number
  }
  xAxisDate: chartAxisEnum
}

const DtpReactivePowerAreaChart = ({
  data = [],
  unit,
  noDataDateValue,
  isLoading,
  tooltipPosition,
  setTooltipPosition,
  xAxisDate,
}: DtpReactivePowerAreaChart) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const tickFormatter = (value: string) => formatTimestamp(value)
  const isSmallMobile = useMediaQuery(theme.breakpoints.between('xs', 500))
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'lg'))
  const viewportWidth = Math.max(document.documentElement.clientWidth, window.innerWidth)
  const TOOLTIP_INITIAL_X = isMobile ? 0 : 500
  const TOOLTIP_INITIAL_Y = isMobile ? 0 : 10
  const wrapperStylePositionTooltip = { left: 40, top: 0 }
  const [tooltipOpen, setTooltipOpen] = useState(false)

  const { min, max } = useMemo(() => calculateMaxMinPropertyDomain(data, 'reactivePower'), [data])
  const ticks = useMemo(() => calculateTicks(min, max, 4), [min, max])

  const groupedNullIndexes = useMemo(() => groupNullIndexes(data, ['reactivePower']), [data])

  const handleChartClick = (e: CategoricalChartState) =>
    handleSetTooltipPositionAndOpenArea({
      e,
      isMobile,
      isTablet,
      isSmallMobile,
      viewportWidth,
      tooltipOpen,
      TOOLTIP_INITIAL_X,
      TOOLTIP_INITIAL_Y,
      setTooltipOpen,
      setTooltipPosition,
    })

  if (isLoading) return <ChartSkeleton chartAxis="ChartArea" />
  if (!data.length) return <ChartNoData currentDate={dayjs(noDataDateValue).format('DD. MMMM YYYY')} />
  return (
    <ResponsiveContainer height={296}>
      <AreaChart
        data={data}
        margin={{ top: 50, bottom: 50, left: 0, right: 0 }}
        onClick={handleChartClick}
        style={{ cursor: 'pointer' }}
        stackOffset="expand"
      >
        <CartesianGrid stroke={chartPallete.area.gridStroke} strokeWidth={1} strokeDasharray="0" vertical={false} />
        <XAxis
          tickMargin={15}
          id="timestampStartOfPeriod"
          dataKey="timestampStartOfPeriod"
          tickFormatter={tickFormatter}
          interval={isMobile ? 3 : 1}
          {...chartStyles(isMobile)}
        />
        <YAxis ticks={ticks} domain={[min, max]} type="number" {...chartStyles(isMobile)}>
          <Label style={{ fontSize: isMobile ? 11 : 14 }} dy={-110}>
            {unit}
          </Label>
        </YAxis>

        <Tooltip
          cursor={{ fill: 'transparent' }}
          content={
            tooltipPosition.x !== TOOLTIP_INITIAL_X || isSmallMobile ? (
              <ReactivePowerTooltip
                tooltipPosition={tooltipPosition.x}
                setTooltipOpen={setTooltipOpen}
                unit={unit}
                xAxisDate={xAxisDate}
              />
            ) : undefined
          }
          position={tooltipPosition}
          allowEscapeViewBox={{ x: false, y: true }}
          trigger="click"
          active={tooltipOpen}
          animationDuration={300}
          wrapperStyle={isSmallMobile ? wrapperStylePositionTooltip : undefined}
          filterNull={false}
        />

        <Area
          type="monotone"
          dataKey="reactivePower"
          stroke={'#4F544F'}
          strokeWidth={isMobile ? 2 : 3}
          fill={'#4F544F33'}
          activeDot={{ r: 6, strokeWidth: 0 }}
          connectNulls={false}
        />
        {ReferenceLineForNullValues({ groupedNullIndexes, data, ticks })}
        {ReferenceAreaForNullValues({ groupedNullIndexes, data, ticks })}
      </AreaChart>
    </ResponsiveContainer>
  )
}

export default DtpReactivePowerAreaChart
