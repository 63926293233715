import DtpPhoneInput from '@components/DtpPhoneInput/DtpPhoneInput'
import DtpTextField from '@components/DtpTextField/DtpTextField'
import { Box, Button, Stack, Typography } from '@mui/material'
import Show from '@src/ui/wrappers/Show/Show'
import { FormikErrors } from 'formik'
import React from 'react'
import { useTranslation } from 'react-i18next'

import LoginSecurityCard from './LoginSecurityCard'

interface IAccountFormProps {
  accountType: 'Person' | 'Organisation'
  isUpdatingAccountDetails: boolean
  firstName1?: string
  customerName?: string
  companyAddition?: string
  disabled: boolean
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  onSubmit: (event: React.FormEvent<HTMLFormElement>) => void
  values: {
    lastName1?: string | null
    firstName2?: string | null
    lastName2?: string | null
    mobile: string | null
    landline: string | null
    email: string | null
  }
  errors: FormikErrors<{
    lastName1: string
    firstName2: string
    lastName2: string
    mobile: string
    landline: string
    email: string
  }>
  setFieldValue: (field: string, value: React.ChangeEvent) => void
}

const AccountForm = ({
  accountType,
  isUpdatingAccountDetails,
  onChange,
  values,
  onSubmit,
  errors,
  setFieldValue,
  firstName1,
  customerName,
  companyAddition,
  disabled,
}: IAccountFormProps) => {
  const { t } = useTranslation()

  const isPerson = accountType === 'Person'
  const isOrganisation = accountType === 'Organisation'
  return (
    <form onSubmit={onSubmit}>
      <Stack gap={2} mt={2}>
        <Show when={isOrganisation}>
          <DtpTextField
            defaultValue={customerName}
            label={t('personal_data.personal_data_company_name')}
            fullWidth
            disabled
            required
          />

          <DtpTextField
            defaultValue={companyAddition}
            label={t('personal_data.personal_data_company_addition')}
            fullWidth
            disabled
            required
          />
        </Show>

        <Show when={isPerson}>
          <DtpTextField
            defaultValue={firstName1}
            label={`${t('personal_data.personal_data_first_name')} (${t('personal_data.not_editable')})`}
            fullWidth
            disabled
            required
          />

          <DtpTextField
            defaultValue={values.lastName1}
            label={requiredLabel(t('personal_data.personal_data_last_name'))}
            name="lastName1"
            onChange={onChange}
            error={Boolean(errors.lastName1)}
            helperText={errors.lastName1}
            fullWidth
          />

          <DtpTextField
            defaultValue={values.firstName2}
            label={`${t('personal_data.personal_data_first_name')} (${t('personal_data.personal_data_additional_person')})`}
            name="firstName2"
            onChange={onChange}
            placeholder={t('personal_data.hint_first_name_additional_person')}
            error={Boolean(errors.firstName2)}
            helperText={errors.firstName2}
            fullWidth
          />

          <DtpTextField
            defaultValue={values.lastName2}
            label={`${t('personal_data.personal_data_last_name')} (${t('personal_data.personal_data_additional_person')})`}
            name="lastName2"
            onChange={onChange}
            placeholder={t('personal_data.hint_last_name_additional_person')}
            error={Boolean(errors.lastName2)}
            helperText={errors.lastName2}
            fullWidth
          />
        </Show>

        <DtpPhoneInput
          defaultCountry="CH"
          name="landline"
          value={values.landline ?? ''}
          label={`${t('personal_data.personal_data_telephone')} (${t('personal_data.landline')})`}
          onChange={(value: string) => setFieldValue('landline', value as unknown as React.ChangeEvent<Element>)}
          id="landline"
          error={Boolean(errors.landline)}
          helperText={errors.landline}
          disableDropdown
          fullWidth
          onlyCountries={['CH']}
        />

        <DtpPhoneInput
          defaultCountry="CH"
          name="mobile"
          value={values.mobile ?? ''}
          label={`${t('personal_data.personal_data_telephone')} (${t('personal_data.mobile')})`}
          onChange={(value: string) => setFieldValue('mobile', value as unknown as React.ChangeEvent<Element>)}
          id="mobile"
          error={Boolean(errors.mobile)}
          helperText={errors.mobile}
          disableDropdown
          fullWidth
          onlyCountries={['CH']}
        />

        <DtpTextField
          type="email"
          defaultValue={values.email}
          label={t('personal_data.personal_data_email')}
          name="email"
          onChange={onChange}
          error={Boolean(errors.email)}
          helperText={errors.email}
          fullWidth
          required
        />

        <LoginSecurityCard />

        <Box>
          <Button type="submit" size="small" disabled={disabled || isUpdatingAccountDetails}>
            {t('save')}
          </Button>
        </Box>
      </Stack>
    </form>
  )
}

export default AccountForm

const requiredLabel = (label: string) => (
  <>
    {label}
    <Typography component="span" color="error">
      &nbsp; *
    </Typography>
  </>
)
