import { IAccountDetails } from '@interfaces/accounts'
import { IAuthorizeUserApiResponse, Medium } from '@interfaces/authorization'
import { IRequestInterimInfoProps } from '@interfaces/invoices'

import { apiUser, apiInvoice, apiAccount, apiAuthorization, apiProduct } from './index'

export const setMainAccount = async ({ gpnr }: { gpnr: string }) => {
  const { data } = await apiUser.post(
    '/v1/user',
    {
      configuration: {
        mainAccount: gpnr,
      },
    },
    {
      headers: { 'x-ckw-gpnr': gpnr },
    }
  )
  return data
}

export const downloadInvoice = async ({ gpnr, invoiceId }: { gpnr: string; invoiceId: string }) => {
  const { data } = await apiInvoice.post(
    '/v1/invoice/pdf',
    { invoiceId },
    { responseType: 'blob', headers: { 'x-ckw-gpnr': gpnr } }
  )
  return data
}

export const updateAccountFields = async ({
  gpnr,
  updatedDetails,
}: {
  gpnr: string
  updatedDetails: IAccountDetails
}): Promise<IAccountDetails> => {
  const { data } = await apiAccount.put('/v1/account/details', updatedDetails, { headers: { 'x-ckw-gpnr': gpnr } })
  return data
}

export const updateInvoiceSettings = async ({
  gpnr,
  contractAccountId,
  paymentDeliveryType,
}: {
  gpnr: string
  contractAccountId: string
  paymentDeliveryType: string
}) => {
  const { data } = await apiInvoice.put(
    '/v1/invoice/settings',
    { contractAccountId, paymentDeliveryType },
    { headers: { 'x-ckw-gpnr': gpnr } }
  )
  return data
}

export const deleteAccount = async ({ gpnr }: { gpnr: string }) => {
  const { data } = await apiAuthorization.delete('/v1/authorization/gpnr', { headers: { 'x-ckw-gpnr': gpnr } })
  return data
}

export const invoiceEmailInitiation = async () => {
  const { data } = await apiAuthorization.post('/v1/authorization/via-invoice-email-initiation')
  return data
}

export const invoiceEmailConfirmation = async ({ otp }: { otp: string }) => {
  const { data } = await apiAuthorization.post('/v1/authorization/via-invoice-email-confirmation', { otp })
  return data
}

export const requestInterimInvoiceInfo = async ({
  gpnr,
  premiseId,
  contractAccountId,
  contractId,
  dateTime,
}: IRequestInterimInfoProps) => {
  const { data } = await apiInvoice.post(
    '/v1/invoice/interim/info',
    { premiseId, contractAccountId, dateTime, contractId },
    { headers: { 'x-ckw-gpnr': gpnr } }
  )
  return data
}

export const authorizeUserAccount = async ({
  gpnr,
  evidence,
}: {
  gpnr: string
  evidence: string
}): Promise<IAuthorizeUserApiResponse> => {
  const { data } = await apiAuthorization.post(
    '/v1/authorization/validation',
    { evidence },
    { headers: { 'x-ckw-gpnr': gpnr } }
  )
  return data
}

export const requestAccessCodeMethod = async ({ gpnr, medium }: { gpnr: string; medium: Medium }) => {
  const { data } = await apiAuthorization.post(
    '/v1/authorization/self-service',
    { medium },
    { headers: { 'x-ckw-gpnr': gpnr } }
  )
  return data
}

export const requestAccessCodeByPostMethod = async ({ gpnr }: { gpnr: string }) => {
  const { data } = await apiAuthorization.post('v1/authorization/request-access-code-via-letter', { gpnr })
  return data
}

export const updateEnergyProduct = async ({
  gpnr,
  contractId,
  rateCategory,
}: {
  gpnr: string
  contractId: string
  rateCategory: string
}) => {
  const { data } = await apiProduct.put(
    '/v1/product',
    { contractId, rateCategory },
    { headers: { 'x-ckw-gpnr': gpnr } }
  )
  return data
}
