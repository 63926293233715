import { getInvoiceContent } from '@api/queries'
import useAccounts from '@hooks/useAccounts/useAccounts'
import { useQuery } from '@tanstack/react-query'
import { useMemo } from 'react'

const useInvoiceContent = (invoiceId: string) => {
  const { activeAccount } = useAccounts()

  const { data, isLoading, isError, refetch } = useQuery({
    queryKey: ['invoiceContent', activeAccount.gpnr, invoiceId],
    queryFn: () => getInvoiceContent({ invoiceId: invoiceId, gpnr: activeAccount.gpnr }),
    enabled: !!activeAccount.gpnr && !!invoiceId,
  })

  const invoiceContent = useMemo(() => data?.body || {}, [data])
  const invoiceContentStatus = useMemo(() => data?.status || {}, [data])
  return { invoiceContent, invoiceContentStatus, isLoading, isError, refetch }
}
export default useInvoiceContent
