import { ArrowBackSliderIcon, ArrowNextSliderIcon } from '@assets/icons'
import {
  onboardingBackground1,
  onboardingBackground2,
  onboardingBackground3,
  onboardingBackground4,
  onboardingImage1,
  onboardingImage2,
  onboardingImage3,
  onboardingImage4,
} from '@assets/images/onboarding/index'
import theme from '@assets/themes/theme'
import DtpCarousel from '@components/DtpCarousel/DtpCarousel'
import { Box, IconButton, Stack, SxProps, Typography, useMediaQuery } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { CustomArrowProps } from 'react-slick'

const wrapperStyle = {
  '& .slick-track > div:not(:last-of-type) > div': {
    paddingRight: '0px',
  },
  '& .slick-slider': {
    overflowY: 'hidden',
  },
}

const contentWrapperStyle = {
  display: 'flex',
  flexDirection: {
    xs: 'column-reverse',
    md: 'row',
  },
  justifyContent: {
    md: 'center',
    xs: 'space-between',
  },
  alignItems: 'center',
  height: {
    xs: 300,
    md: 400,
  },
  gap: {
    xs: 0,
    md: 4,
  },
}
const OnboardingCarousel = () => {
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const isTablet = useMediaQuery(theme.breakpoints.only('sm'))
  const { t } = useTranslation()
  const onboardingData: {
    description: string
    image: string
    background: string
    imageStyle: React.CSSProperties
    descriptionStyle: SxProps
  }[] = [
    {
      description: 'onboarding.description_1',
      image: onboardingImage1,
      background: onboardingBackground1,
      imageStyle: {
        height: isMobile ? 'auto' : '322px',
        maxWidth: isTablet ? '400px' : isMobile ? '100%' : '484px',
        paddingTop: '24px',
        position: 'relative',
        left: isMobile ? 0 : '-5%',
      },
      descriptionStyle: {
        width: {
          xs: '100%',
          md: '320px',
        },
        display: 'flex',
        justifySelf: {
          xs: 'center',
          md: 'end',
        },
        alignSelf: {
          md: 'center',
          xs: 'flex-end',
        },
        pl: {
          xs: 0,
          md: 5,
        },
      },
    },
    {
      description: 'onboarding.description_2',
      image: onboardingImage2,
      background: onboardingBackground2,
      imageStyle: {
        height: isMobile ? 'auto' : '367px',
        maxWidth: isTablet ? '400px' : isMobile ? '100%' : '476px',
        paddingTop: isMobile ? '0' : '24px',
        position: 'relative',
        left: isMobile ? 0 : '-20%',
      },
      descriptionStyle: {
        width: {
          xs: '100%',
          md: '350px',
        },
        position: 'relative',
        right: {
          xs: '0',
          md: '-10%',
          xl: '10%',
        },
        top: {
          md: '10%',
        },
        pl: {
          xs: 0,
          md: 3,
        },
      },
    },
    {
      description: 'onboarding.description_3',
      image: onboardingImage3,
      background: onboardingBackground3,
      imageStyle: {
        height: isMobile ? '304px' : '377px',
        maxWidth: isTablet ? '400px' : isMobile ? 'auto' : '476px',
        paddingTop: '0px',
        position: 'relative',
        left: isMobile ? '0' : '-10%',
      },
      descriptionStyle: {
        width: {
          xs: '100%',
          md: '320px',
        },
        display: {
          xs: 'block',
          md: 'flex',
        },
        margin: {
          xs: '0',
          md: 'auto',
        },
        pl: {
          xs: 0,
          md: 3,
        },
      },
    },
    {
      description: 'onboarding.description_4',
      image: onboardingImage4,
      background: onboardingBackground4,
      imageStyle: {
        maxHeight: isMobile ? 'auto' : '465px',
        width: isTablet ? '400px' : isMobile ? '100%' : '336px',
        position: 'relative',
        left: 0,
        bottom: isMobile ? '0' : '-10px',
      },
      descriptionStyle: {
        width: {
          xs: '100%',
          md: '350px',
        },
        position: 'relative',
        right: {
          xs: '0',
          sm: '0',
          md: '-15%',
        },
        top: {
          sm: '0',
          md: '10%',
        },
        pl: {
          xs: 0,
          md: 0,
        },
      },
    },
  ]
  const CustomArrowLeftButton = ({ currentSlide, slideCount, ...props }: CustomArrowProps) => (
    <IconButton
      {...props}
      sx={{
        position: 'absolute',
        left: 20,
        top: '50%',
        transform: 'translateY(-50%)',
        zIndex: 1111,
        svg: {
          width: 30,
          height: 30,
        },
        '::before': {
          display: 'none',
        },
        '&:hover': {
          backgroundColor: 'transparent',
        },
      }}
      className={'slick-prev slick-arrow' + (slideCount && currentSlide === 0 ? ' slick-disabled' : '')}
      aria-hidden="true"
      disabled={currentSlide === 0 ? true : false}
    >
      <ArrowBackSliderIcon />
    </IconButton>
  )
  const CustomArrowRightButton = ({ currentSlide, slideCount, ...props }: CustomArrowProps) => (
    <IconButton
      {...props}
      sx={{
        position: 'absolute',
        right: 40,
        top: '50%',
        transform: 'translateY(-50%)',
        zIndex: 1111,
        svg: {
          width: 30,
          height: 30,
        },
        '::before': {
          display: 'none',
        },
        '&:hover': {
          backgroundColor: 'transparent',
        },
      }}
      size="medium"
      className={'slick-next slick-arrow' + (slideCount && currentSlide === slideCount - 1 ? ' slick-disabled' : '')}
      aria-hidden="true"
      disabled={slideCount && currentSlide === slideCount - 1 ? true : false}
    >
      <ArrowNextSliderIcon />
    </IconButton>
  )

  return (
    <DtpCarousel
      arrows={isMobile ? false : true}
      prevArrow={<CustomArrowLeftButton />}
      nextArrow={<CustomArrowRightButton />}
      initialSlide={0}
      slidesToShow={1}
      slidesToScroll={1}
      sliderWrapperStyle={wrapperStyle}
    >
      {onboardingData.map(({ background, image, imageStyle, description, descriptionStyle }, index) => (
        <Box
          key={index}
          sx={{
            backgroundImage: `url(${!isMobile && background})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
          }}
        >
          <Stack
            sx={contentWrapperStyle}
            my={{
              xs: index === 2 ? 2 : 4,
              md: 0,
            }}
          >
            <Box
              display="inline-flex"
              height="100%"
              maxWidth={isMobile ? '100%' : '43%'}
              position="relative"
              justifyContent="end"
            >
              <Typography variant="heading4" color="primary" sx={descriptionStyle}>
                {t(description)}
              </Typography>
            </Box>
            <Box display="inline-flex" maxWidth={isMobile ? '100%' : '55%'} position="relative">
              <img src={image} alt={`onboardingImage${index}`} style={imageStyle} />
            </Box>
          </Stack>
        </Box>
      ))}
    </DtpCarousel>
  )
}

export default OnboardingCarousel
