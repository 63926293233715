import DtpPersonalDataFields from '@components/DtpPersonalDataFields/DtpPersonalDataFields'
import { SuspenseLoading } from '@components/SuspenseLoading/SuspenseLoading'
import { useAccountDetails } from '@hooks/useAccountDetails/useAccountDetails'
import useAccounts from '@hooks/useAccounts/useAccounts'
import { Box, Container } from '@mui/material'
import Show from '@src/ui/wrappers/Show/Show'

const PersonalData = () => {
  const { refetch, isLoading: isLoadingAccounts } = useAccounts()
  const { accountData, isLoading, updateAccountDetails, refetchAccountDetails, isUpdatingAccountDetails } =
    useAccountDetails()

  if (isLoading || isLoadingAccounts) return <SuspenseLoading />

  return (
    <Container>
      <DtpPersonalDataFields
        {...accountData}
        updateAccountDetails={updateAccountDetails}
        refetchAccountDetails={refetchAccountDetails}
        refetchAccounts={refetch}
        isUpdatingAccountDetails={isUpdatingAccountDetails}
      />

      <Show when={isUpdatingAccountDetails}>
        <Box
          sx={{
            position: 'fixed',
            left: 0,
            top: 0,
            width: '100%',
            bgcolor: 'rgba(255, 255, 255, 0.5)',
            zIndex: 1,
          }}
        >
          <SuspenseLoading />
        </Box>
      </Show>
    </Container>
  )
}

export default PersonalData
