import { PowerCableIcon, PlantLeafIcon, PaidInvoicesIcon, HighVoltagePoleIcon, SolarIcon } from '@assets/icons'
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp'
import { Box, Divider, Stack, styled, Typography, useTheme } from '@mui/material'
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion'
import MuiAccordionDetails from '@mui/material/AccordionDetails'
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary'
import Show from '@src/ui/wrappers/Show/Show'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import ContentItem from './ContentItem'
import { hasValue } from './DtpInvoiceDetailsUtills'
import HighlightedContent from './HighlightedContent'

interface IDtpInvoiceDetailsProps {
  gpnr?: string
  billingPeriodFromDate?: string
  billingPeriodToDate?: string
  invoiceID?: string
  invoiceStatusID?: string
  contractID?: string
  currency?: string
  amountBasis?: string
  amountEnergyDelivery?: string
  amountEdHt?: string
  consumptionHT?: string
  amountReducHt?: string
  amountEdNt?: string
  consumptionNT?: string
  amountReducNt?: string
  amountEdEt?: string
  amountReducEt?: string
  consumptionET?: string
  consumption?: string
  amountNetworkAccess?: string
  amountNaHt?: string
  amountNaNt?: string
  amountNaEt?: string
  amountPower?: string
  amountSwgr?: string
  amountReserve?: string
  consumptionPower?: string
  amountReactive?: string | null
  amountReHt?: string | null
  consumptionReHt?: string | null
  amountReNt?: string | null
  amountReEt?: string | null
  consumptionReNt?: string | null
  consumptionReEt?: string | null
  amountFee?: string
  amountFeeB?: string
  amountFeeKa?: string
  amountFeeMwst?: string
  amountPostFee?: string
  amountDunningFee?: string
  amountServices?: string
  amountWorkCompensation?: string
  rounding?: string | null
  amountTotal?: string
  amountCredit?: string | null
  invoiceContentStatusCode?: string
  amountRe?: string
  amountReHT?: string
  amountReNT?: string
  amountReHKN?: string
  roundingRe?: string
  amountReET?: string
}

const hoverAndActiveStyles = {
  background: '#365F10',
  color: '#fff',
  borderRadius: '50%',
}

const Accordion = styled((props: AccordionProps) => <MuiAccordion disableGutters elevation={0} square {...props} />)({
  border: 'none',
  '&::before': {
    display: 'none',
  },
})

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary expandIcon={<ArrowForwardIosSharpIcon sx={{ width: 24, padding: 0.5 }} />} {...props} />
))(({ theme }) => ({
  flexDirection: 'row-reverse',
  padding: 0,
  '&:hover': {
    '& .MuiAccordionSummary-expandIconWrapper': {
      ...hoverAndActiveStyles,
    },
    '& .MuiAccordionSummary-content p': {
      fontWeight: 600,
    },
  },
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
    ...hoverAndActiveStyles,
  },
  '& .MuiAccordionSummary-content': {
    margin: 0,
    marginLeft: theme.spacing(1),
    '&.Mui-expanded p': {
      fontWeight: 600,
    },
  },
}))

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  marginLeft: theme.spacing(3),
  paddingLeft: 0,
  paddingRight: 0,
}))

const DtpInvoiceDetails = (props: IDtpInvoiceDetailsProps) => {
  const {
    amountBasis,
    amountEnergyDelivery,
    amountEdHt,
    amountReducHt,
    consumptionHT,
    consumptionNT,
    amountEdNt,
    amountReducNt,
    amountNetworkAccess,
    consumptionReHt,
    consumptionReNt,
    consumptionReEt,
    consumptionET,
    amountEdEt,
    amountReHt,
    amountReNt,
    amountReEt,
    amountReducEt,
    amountFee,
    amountFeeB,
    amountFeeKa,
    amountCredit,
    amountFeeMwst,
    amountPostFee,
    amountDunningFee,
    amountNaHt,
    amountNaNt,
    amountNaEt,
    amountPower,
    amountSwgr,
    amountReserve,
    consumptionPower,
    amountTotal,
    amountWorkCompensation,
    rounding,
    invoiceContentStatusCode,
    amountRe,
    amountReHT,
    amountReNT,
    amountReHKN,
    roundingRe,
    amountReET,
  } = props

  const theme = useTheme()
  const { t } = useTranslation()

  const [expanded, setExpanded] = useState<string | false>(false)

  const handleChange = (panel: string) => (_: React.SyntheticEvent, newExpanded: boolean) => {
    setExpanded(newExpanded && panel)
  }

  const isCreditNote = amountTotal?.includes('-') ?? false

  return (
    <Box
      sx={{
        '& .MuiDivider-root + .MuiDivider-root': {
          display: 'none',
        },
      }}
      width={1}
    >
      <Typography variant="heading4" sx={{ mb: 4 }}>
        {t('invoices.invoice_cost_composition')}
      </Typography>

      <Show when={invoiceContentStatusCode === 'I_301'}>
        <Stack alignItems="center" py={3}>
          <Box>
            <PaidInvoicesIcon sx={{ width: 64, height: 64 }} />
          </Box>
          <Typography maxWidth="389px" variant="bodyRegular">
            {t('invoices.ic_invoice_content_not_displayable')}
          </Typography>
        </Stack>
        <Divider sx={{ mt: 6, bgcolor: theme.palette.divider }} />
      </Show>

      <Show when={invoiceContentStatusCode === 'I_200'}>
        <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
          <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
            <ContentItem label={t('invoices.invoice_energy_consumption')} value={amountEnergyDelivery} />
          </AccordionSummary>
          <AccordionDetails>
            <HighlightedContent
              icon={<PowerCableIcon sx={{ color: 'transparent', mx: 2 }} />}
              label={t('invoices.invoice_explanation_energy')}
            />
            <Show when={hasValue(amountBasis)}>
              <ContentItem label={t('invoices.ic_base_rate')} value={amountBasis} />
            </Show>
            <Show when={hasValue(amountPower)}>
              <ContentItem label={`${t('invoices.ic_power_tariff')}: ${consumptionPower} kWh`} value={amountPower} />
            </Show>
            <Show when={hasValue(amountEdHt)}>
              <ContentItem label={`${t('invoices.ic_highrate')}: ${consumptionHT} kWh`} value={amountEdHt} />
            </Show>
            <Show when={hasValue(amountReducHt)}>
              <ContentItem label={t('invoices.ic_high_rate_reductions')} value={amountReducHt} />
            </Show>
            <Show when={hasValue(amountEdNt)}>
              <ContentItem label={`${t('invoices.ic_lowrate')}: ${consumptionNT} kWh`} value={amountEdNt} />
            </Show>
            <Show when={hasValue(amountReducNt)}>
              <ContentItem label={t('invoices.ic_low_rate_reductions')} value={amountReducNt} />
            </Show>
            <Show when={hasValue(amountEdEt)}>
              <ContentItem label={`${t('invoices.ic_single_rate')}: ${consumptionET} kWh`} value={amountEdEt} />
            </Show>
            <Show when={hasValue(amountReducEt)}>
              <ContentItem label={t('invoices.ic_single_rate_reductions')} value={amountReducEt} />
            </Show>
          </AccordionDetails>
        </Accordion>

        <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
          <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
            <ContentItem label={t('invoices.invoice_grid_usage')} value={amountNetworkAccess} />
          </AccordionSummary>
          <AccordionDetails>
            <HighlightedContent
              icon={<HighVoltagePoleIcon sx={{ color: 'transparent', mx: 2 }} />}
              label={t('invoices.invoice_explanation_grid')}
            />
            <Show when={hasValue(amountNaHt)}>
              <ContentItem
                label={`${t('invoices.ic_high_rate_grid_usage')}: ${consumptionHT} kWh`}
                value={amountNaHt}
              />
            </Show>
            <Show when={hasValue(amountNaNt)}>
              <ContentItem label={`${t('invoices.ic_low_rate_grid_usage')}: ${consumptionNT} kWh`} value={amountNaNt} />
            </Show>
            <Show when={hasValue(amountNaEt)}>
              <ContentItem
                label={`${t('invoices.ic_single_rate_grid_usage')}: ${consumptionET} kWh`}
                value={amountNaEt}
              />
            </Show>
            <Show when={hasValue(amountReHt)}>
              <ContentItem
                label={`${t('invoices.ic_high_rate_reactive_power')}: ${consumptionReHt} kvar`}
                value={amountReHt}
              />
            </Show>
            <Show when={hasValue(amountReNt)}>
              <ContentItem
                label={`${t('invoices.ic_low_rate_reactive_power')}: ${consumptionReNt} kvar`}
                value={amountReNt}
              />
            </Show>
            <Show when={hasValue(amountReEt)}>
              <ContentItem
                label={`${t('invoices.ic_single_rate_reactive_power')}: ${consumptionReEt} kvar`}
                value={amountReEt}
              />
            </Show>
            <Show when={hasValue(amountSwgr)}>
              <ContentItem label={t('invoices.ic_system_service_swissgrid')} value={amountSwgr} />
            </Show>
            <Show when={hasValue(amountReserve)}>
              <ContentItem label={t('invoices.ic_winter_reserve')} value={amountReserve} />
            </Show>
          </AccordionDetails>
        </Accordion>

        <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
          <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
            <ContentItem label={t('invoices.invoice_public_charges')} value={amountFee} />
          </AccordionSummary>
          <AccordionDetails>
            <HighlightedContent
              icon={<PlantLeafIcon sx={{ color: 'transparent', mx: 2 }} />}
              label={t('invoices.invoice_explanation_community_taxes')}
            />
            <Show when={hasValue(amountFeeB)}>
              <ContentItem label={t('invoices.invoice_federal_taxes')} value={amountFeeB} />
            </Show>
            <Show when={hasValue(amountFeeKa)}>
              <ContentItem label={t('invoices.invoice_concession_fee')} value={amountFeeKa} />
            </Show>
          </AccordionDetails>
        </Accordion>

        <Show when={hasValue(amountRe)}>
          <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
            <AccordionSummary aria-controls="panel4d-content" id="panel4d-header">
              <ContentItem
                label={t('invoices.invoice_feed_in')}
                value={amountRe}
                valueSx={{ color: theme.palette.background.primaryActive }}
              />
            </AccordionSummary>
            <AccordionDetails>
              <HighlightedContent
                icon={<SolarIcon sx={{ color: 'transparent', mx: 2 }} />}
                label={t('invoices.invoice_explanation_feed_in')}
              />
              <Show when={hasValue(amountReHT)}>
                <ContentItem
                  label={t('invoices.ic_highrate')}
                  value={amountReHT}
                  valueSx={{ color: theme.palette.background.primaryActive }}
                />
              </Show>
              <Show when={hasValue(amountReNT)}>
                <ContentItem
                  label={t('invoices.ic_lowrate')}
                  value={amountReNT}
                  valueSx={{ color: theme.palette.background.primaryActive }}
                />
              </Show>
              <Show when={hasValue(amountReET)}>
                <ContentItem
                  label={t('invoices.ic_single_rate')}
                  value={amountReET}
                  valueSx={{ color: theme.palette.background.primaryActive }}
                />
              </Show>
              <Show when={hasValue(amountReHKN)}>
                <ContentItem
                  label={t('invoices.ic_hkn')}
                  value={amountReHKN}
                  valueSx={{ color: theme.palette.background.primaryActive }}
                />
              </Show>
              <Show when={hasValue(roundingRe)}>
                <ContentItem
                  label={t('invoices.invoice_rounding_difference')}
                  value={roundingRe}
                  valueSx={{ color: theme.palette.background.primaryActive }}
                />
              </Show>
            </AccordionDetails>
          </Accordion>
        </Show>

        <Divider sx={{ mb: 2.5, mt: 1.5, bgcolor: theme.palette.divider }} />

        <Show when={hasValue(amountCredit)}>
          <ContentItem
            label={t('invoices.ic_credit_balance')}
            value={amountCredit}
            valueSx={{ color: theme.palette.background.primaryActive }}
          />
        </Show>
        <Show when={hasValue(amountFeeMwst)}>
          <ContentItem label={t('invoices.invoice_vat')} value={amountFeeMwst} />
        </Show>
        <Show when={hasValue(amountPostFee)}>
          <ContentItem label={t('invoices.invoice_postal_deposit_fees')} value={amountPostFee} />
        </Show>
        <Show when={hasValue(amountDunningFee)}>
          <ContentItem label={t('invoices.ic_reminder_fees')} value={amountDunningFee} />
        </Show>
        <Show when={hasValue(amountWorkCompensation)}>
          <ContentItem label={t('invoices.ic_work_compensation')} value={amountWorkCompensation} />
        </Show>
        <Show when={hasValue(rounding)}>
          <ContentItem
            label={t('invoices.ic_rounding_difference')}
            value={rounding}
            valueSx={{ color: hasValue(amountRe) ? theme.palette.background.primaryActive : '' }}
          />
        </Show>

        <Divider sx={{ my: 2.5, bgcolor: theme.palette.text.secondary }} />
        <ContentItem
          label={t('invoices.invoice_amount')}
          value={amountTotal ? `CHF ${amountTotal}` : ''}
          labelSx={{ fontWeight: '700', fontSize: 22 }}
          valueSx={{
            fontWeight: '700',
            fontSize: 24,
            color: isCreditNote ? theme.palette.primary.main : theme.palette.text.primary,
          }}
        />
      </Show>
    </Box>
  )
}

export default DtpInvoiceDetails
