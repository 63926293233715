import LabelText from './LabelText'

type LabelValue = {
  unit: string
  numberValue: string | null
  isMobile: boolean
}

const LabelValue = ({ unit, numberValue, isMobile }: LabelValue) => {
  const unitElement = isMobile ? <LabelText text={unit} /> : <>{unit}</>

  return unit === 'CHF' ? (
    <>
      {unitElement} {` ${numberValue}`}
    </>
  ) : (
    <>
      {`${numberValue} `} {unitElement}
    </>
  )
}

export default LabelValue
