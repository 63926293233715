import { SuspenseLoading } from '@components/SuspenseLoading/SuspenseLoading'
import { Wizard } from '@components/Wizard/Wizard'
import { FormSteps } from '@enums/authorization'
import useAuthOptions from '@hooks/useAuthOptions/useAuthOptions'
import useAuthorizeUser from '@hooks/useAuthorizeUser/useAuthorizeUser'
import { useSearchParams } from 'react-router'

import AccessCode from './steps/AccessCode/AccessCode'
import AccountNumber from './steps/AccountNumber/AccountNumber'
import ContactSupport from './steps/ContactSupport/ContactSupport'
import OneTimePassword from './steps/OneTimePassword/OneTimePassword'
import RequestNewCode from './steps/RequestNewCode/RequestNewCode'
import SendVerificationEmail from './steps/SendVerificationEmail/SendVerificationEmail'

const DtpConnectAccountWizard = () => {
  const [searchParams] = useSearchParams()
  const code = searchParams.get('code') ?? ''

  const {
    canAuthorizeAutomatically,
    isLoadingAutomaticAuth,
    emailIntiation,
    emailConfirmation,
    authorizeUserForm,
    isAuthorizingAccount,
  } = useAuthorizeUser()

  const {
    authOptions,
    isLoadingAuthOptions,
    selectedOption,
    setSelectedOption,
    availableDeliveryOptions,
    requestAccessCode,
    requestAccessCodeByPost,
    isRequestingAccessCode,
    isRequestingAccessCodeByPost,
  } = useAuthOptions(authorizeUserForm.values.gpnr)

  const hasCode = code ? 5 : 4
  const shouldAuthenticateAutomatically = canAuthorizeAutomatically ? hasCode : 0

  if (isLoadingAutomaticAuth) return <SuspenseLoading />

  return (
    <Wizard currentStep={shouldAuthenticateAutomatically}>
      <Wizard.Step
        id={FormSteps.ACCOUNT_NUMBER_STEP}
        component={AccountNumber}
        onChange={authorizeUserForm.handleChange}
        onBlur={authorizeUserForm.handleBlur}
        gpnr={authorizeUserForm.values.gpnr}
        error={authorizeUserForm.errors.gpnr}
        touched={authorizeUserForm.touched.gpnr}
      />
      <Wizard.Step
        id={FormSteps.ACCESS_CODE_STEP}
        component={AccessCode}
        onChange={authorizeUserForm.handleChange}
        onBlur={authorizeUserForm.handleBlur}
        onSubmit={authorizeUserForm.handleSubmit}
        evidence={authorizeUserForm.values.evidence}
        error={authorizeUserForm.errors.evidence}
        touched={authorizeUserForm.touched.evidence}
        isLoading={isAuthorizingAccount}
      />
      <Wizard.Step
        id={FormSteps.REQUEST_ACCESS_CODE}
        component={RequestNewCode}
        isLoading={isLoadingAuthOptions}
        authOptions={authOptions}
        requestAccessCode={requestAccessCode}
        requestAccessCodeByPost={requestAccessCodeByPost}
        selectedOption={selectedOption}
        setSelectedOption={setSelectedOption}
        availableDeliveryOptions={availableDeliveryOptions}
        isUpdating={isRequestingAccessCode || isRequestingAccessCodeByPost}
      />
      <Wizard.Step id={FormSteps.SUPPORT} component={ContactSupport} accountNumber={authorizeUserForm.values.gpnr} />

      <Wizard.Step id={FormSteps.AUTOMATIC_AUTH} component={SendVerificationEmail} emailInitiation={emailIntiation} />
      <Wizard.Step
        id={FormSteps.AUTOMATIC_AUTH_OTP}
        component={OneTimePassword}
        otp={code}
        emailConfirmation={emailConfirmation}
      />
    </Wizard>
  )
}

export default DtpConnectAccountWizard
