import { Skeleton, Stack, Box, styled } from '@mui/material'

const Wrapper = styled(Stack)({
  flexDirection: 'row',
  maxWidth: 550,
  padding: 17,
  paddingLeft: 24,
  columnGap: 16,
  marginBottom: 16,
  border: '1px solid #EFF0EF',
})

const CodeDeliveryOptionsSkeleton = () => {
  return (
    <Box mt={4} mb={4}>
      <Wrapper>
        <Skeleton variant="circular" width={24} height={24} />
        <Box width="80%">
          <Skeleton width="60%" />
          <Skeleton width="40%" />
        </Box>
      </Wrapper>
      <Wrapper>
        <Skeleton variant="circular" width={24} height={24} />
        <Box width="80%">
          <Skeleton width="60%" />
          <Skeleton width="40%" />
        </Box>
      </Wrapper>
    </Box>
  )
}

export default CodeDeliveryOptionsSkeleton
