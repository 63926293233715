import { CompareIcon } from '@assets/icons'
import ComparisonChartV2 from '@components/ComparisonChartV2/ComparisonChartV2'
import { DtpDatePicker } from '@components/DtpDatePicker/DtpDatePicker'
import CompareCardSkeleton from '@components/Skeletons/CompareCardSkeleton'
import useComparisonV2 from '@hooks/useComparisonV2/useComparisonV2'
import { Card, CardContent, CardHeader, Stack, Typography } from '@mui/material'

export interface IDtpCompareCardV2Props {
  datePickerValue: string
  title: string
  aggregationType: 'month' | 'year'
  unit?: 'kWh' | 'CHF'
  isLoading?: boolean
  onBarClick: (value: string, index: number) => void
}

const DtpCompareCardV2 = ({
  datePickerValue,
  title,
  unit = 'kWh',
  aggregationType,
  isLoading = false,
  onBarClick,
}: IDtpCompareCardV2Props) => {
  const {
    comparationData,
    isLoadingComparation,
    measurementType,
    period1,
    setPeriod1,
    period2,
    setPeriod2,
    minDate,
    maxDate,
    comparisonDescription,
  } = useComparisonV2({
    datePickerValue,
    aggregationType,
  })

  const tooltipTabIndexYear = aggregationType === 'year' ? 2 : 0
  const tooltipTabIndex = aggregationType === 'month' ? 1 : tooltipTabIndexYear

  if (isLoading || isLoadingComparation) return <CompareCardSkeleton chartType={aggregationType} />

  return (
    <Card
      sx={{
        boxShadow: 0,
        border: '1px solid #D5D8D5',
        borderRadius: '24px',
        px: { xs: 1, md: 6 },
        py: { xs: 2, md: 4 },
        overflow: 'visible',
        height: '420px',
      }}
    >
      <CardHeader
        sx={{ p: { xs: 1, md: 0 } }}
        title={
          <Typography sx={{ m: '0 0 8px 0' }} variant="heading4">
            {title}
          </Typography>
        }
        subheader={
          <Typography
            sx={{
              m: {
                xs: '0 8px 0 0',
              },
              minHeight: 48,
            }}
            variant="body1"
            fontFamily="GothamBook"
            color="text.secondary"
          >
            {comparisonDescription}
          </Typography>
        }
      />
      <CardContent sx={{ paddingBottom: 0, '&:last-child': { paddingBottom: 0 } }}>
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          sx={{ gap: { sm: 0, md: 1 }, '& div, & button': { width: '100%' } }}
          mb={3}
        >
          <DtpDatePicker
            value={period1}
            onChange={setPeriod1}
            viewType={aggregationType}
            maxDate={maxDate}
            minDate={minDate}
            variant="outlined"
          />

          <CompareIcon fontSize="large" />

          <DtpDatePicker
            value={period2}
            onChange={setPeriod2}
            viewType={aggregationType}
            maxDate={maxDate}
            minDate={minDate}
            variant="outlined"
          />
        </Stack>

        <ComparisonChartV2
          data={comparationData}
          measurementType={measurementType}
          isLoading={isLoadingComparation}
          unit={unit}
          aggregationType={aggregationType}
          handleOnDateChange={(value: string) => onBarClick(value, tooltipTabIndex)}
        />
      </CardContent>
    </Card>
  )
}

export default DtpCompareCardV2
