import { requestAccessCodeMethod, requestAccessCodeByPostMethod } from '@api/mutations'
import { getAuthOptions } from '@api/queries'
import { AuthOptions } from '@enums/authorization'
import { removeBlankSpaces } from '@helpers/functional'
import { Medium } from '@interfaces/authorization'
import { useQuery, useMutation } from '@tanstack/react-query'
import { useMemo, useEffect, useState } from 'react'

const useAuthOptions = (customerNumber: string) => {
  const [selectedOption, setSelectedOption] = useState<AuthOptions | null>(null)

  const gpnr = removeBlankSpaces(customerNumber)

  const { data: authOptionsData, isFetching: isLoadingAuthOptions } = useQuery({
    queryKey: ['auth-options', gpnr],
    queryFn: () => getAuthOptions(gpnr),
    enabled: !!gpnr && gpnr.length === 7,
    gcTime: 0,
  })

  const { mutateAsync: requestAccessCode, isPending: isRequestingAccessCode } = useMutation({
    mutationKey: ['requestAccessCode'],
    mutationFn: (medium: Medium) => requestAccessCodeMethod({ gpnr, medium }),
  })

  const { mutateAsync: requestAccessCodeByPost, isPending: isRequestingAccessCodeByPost } = useMutation({
    mutationKey: ['requestAccessCodeByPost'],
    mutationFn: () => requestAccessCodeByPostMethod({ gpnr }),
  })

  const authOptions = useMemo(() => authOptionsData?.body ?? {}, [authOptionsData])

  const invalidGpnr = authOptionsData?.status?.code === 'E_304'

  const { mobileNumber, invoiceEmail, userEmail, phoneOrganization } = authOptions

  const options = {
    mobileNumber: !!mobileNumber,
    phoneOrganization: !!phoneOrganization,
    invoiceEmail: !!invoiceEmail,
    userEmail: !!userEmail,
  }

  const activeOptions = Object.keys(options).filter((key) => options[key as keyof typeof options])

  const availableDeliveryOptions = {
    ...options,
    multiple: activeOptions.length > 1,
    single: activeOptions.length === 1 ? activeOptions[0] : null,
    none: activeOptions.length === 0,
    post: activeOptions.length === 0 && !invalidGpnr,
  } as const

  useEffect(() => {
    if (!selectedOption || !activeOptions.includes(selectedOption)) {
      const option = activeOptions.length > 0 ? (activeOptions[0] as AuthOptions) : null
      setSelectedOption(option)
    }
  }, [activeOptions, selectedOption])

  return {
    availableDeliveryOptions,
    authOptions,
    isLoadingAuthOptions,
    selectedOption,
    setSelectedOption,
    requestAccessCode,
    requestAccessCodeByPost,
    isRequestingAccessCode,
    isRequestingAccessCodeByPost,
  }
}

export default useAuthOptions
