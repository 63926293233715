import DtpCardSelect, { DtpCardSelectItem } from '@components/DtpCardSelect'
import { AuthOptions } from '@enums/authorization'
import { isNil } from '@helpers/functional'
import { Typography } from '@mui/material'
import Show from '@src/ui/wrappers/Show/Show'
import { useTranslation } from 'react-i18next'

interface ISelectDeliveryOptionProps {
  authOptions: Partial<Record<AuthOptions, string>>
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  selectedOption: AuthOptions
}

const SelectDeliveryOption = ({ authOptions, onChange, selectedOption }: ISelectDeliveryOptionProps) => {
  const { t } = useTranslation()

  return (
    <DtpCardSelect value={selectedOption} onChange={onChange}>
      <Show when={!isNil(authOptions.mobileNumber)}>
        <DtpCardSelectItem
          label={
            <>
              <Typography sx={{ m: 0 }} variant="bodyRegularEmphasized">
                {t('authorization.by_sms')}
              </Typography>
              <Typography variant="bodyRegular" sx={{ m: 0 }}>
                {`${t('authorization.to')} ${authOptions.mobileNumber}`}
              </Typography>
            </>
          }
          checked={selectedOption === AuthOptions.MOBILE_NUMBER}
          value={AuthOptions.MOBILE_NUMBER}
        />
      </Show>

      <Show when={!isNil(authOptions.phoneOrganization)}>
        <DtpCardSelectItem
          label={
            <>
              <Typography sx={{ m: 0 }} variant="bodyRegularEmphasized">
                {t('authorization.by_sms')}
              </Typography>
              <Typography variant="bodyRegular" sx={{ m: 0 }}>
                {`${t('authorization.to')} ${authOptions.phoneOrganization}`}
              </Typography>
            </>
          }
          checked={selectedOption === AuthOptions.PHONE_ORGANIZATION}
          value={AuthOptions.PHONE_ORGANIZATION}
        />
      </Show>

      <Show when={!isNil(authOptions.userEmail)}>
        <DtpCardSelectItem
          label={
            <>
              <Typography sx={{ m: 0 }} variant="bodyRegularEmphasized">
                {t('authorization.by_e_mail')}
              </Typography>
              <Typography variant="bodyRegular" sx={{ m: 0 }}>
                {`${t('authorization.to')} ${authOptions.userEmail}`}
              </Typography>
            </>
          }
          checked={selectedOption === AuthOptions.USER_EMAIL}
          value={AuthOptions.USER_EMAIL}
        />
      </Show>

      <Show when={!isNil(authOptions.invoiceEmail)}>
        <DtpCardSelectItem
          label={
            <>
              <Typography sx={{ m: 0 }} variant="bodyRegularEmphasized">
                {t('authorization.by_e_mail')}
              </Typography>
              <Typography variant="bodyRegular" sx={{ m: 0 }}>
                {`${t('authorization.to')} ${authOptions.invoiceEmail}`}
              </Typography>
            </>
          }
          checked={selectedOption === AuthOptions.INVOICE_EMAIL}
          value={AuthOptions.INVOICE_EMAIL}
        />
      </Show>
    </DtpCardSelect>
  )
}

export default SelectDeliveryOption
