import { CheckIcon } from '@assets/icons'
import { invoiceStatus } from '@enums/invoiceStatus'
import { getFormattedBillingPeriod } from '@helpers/invoices'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import { Box, styled, Stack, Typography } from '@mui/material'
import Show from '@src/ui/wrappers/Show/Show'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'

import CardDotItem from './CardDotItem'
import { CardItem } from './CardItem'

export const DtpInvoiceCardWrapper = styled(Stack)(({ theme }) => ({
  flexDirection: 'column',
  padding: theme.spacing(3),
  marginBottom: theme.spacing(2),
  borderRadius: theme.spacing(3),
  border: '1px solid #D5D8D5',
  flex: 1,
  maxWidth: 369,
  minWidth: 335,
  backgroundColor: theme.palette.grey[50],
  cursor: 'pointer',
  transitionTimingFunction: 'cubic-bezier(0, 0, 0.2, 1)',
  transition: 'all 0.4s ease',
  '&:hover': {
    boxShadow: '0px 4px 6px -2px #0000000D, 0px 10px 15px -3px #0000001A',
  },
}))

export interface IDtpInvoiceCardProps {
  invoiceId: string
  dueDate: string
  invoiceStatus: invoiceStatus
  amountTotal: string
  currency: string
  contractId?: string[]
  contractDescription?: string[]
  eBill: boolean
  billingPeriodFromDate: string
  billingPeriodToDate: string
}

const DtpInvoiceCard = ({
  contractDescription,
  invoiceId,
  dueDate,
  amountTotal,
  currency,
  eBill,
  invoiceStatus,
  billingPeriodFromDate,
  billingPeriodToDate,
}: IDtpInvoiceCardProps) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const isCreditNote = amountTotal?.includes('-')
  const billingPeriod = getFormattedBillingPeriod(billingPeriodFromDate, billingPeriodToDate)
  const contracts = contractDescription?.map((contract, index) => (index === 0 ? contract : ` | ${contract}`))
  return (
    <DtpInvoiceCardWrapper
      role="open-invoice-card"
      onClick={() => navigate(`${t('navigation_url.invoices')}/${invoiceId}`)}
    >
      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ m: 0 }}>
        <Box>
          <Typography variant="bodyRegular" sx={{ my: 0, mr: 2, letterSpacing: 0, fontWeight: 400 }}>
            <Typography
              component="span"
              variant="leadEmphasized"
              sx={{ fontSize: '18px', letterSpacing: 0, fontWeight: 600 }}
            >
              {t(isCreditNote ? 'invoices.invoice_payout' : 'invoices.invoice')}
            </Typography>
            {` ${billingPeriod}`}
          </Typography>
        </Box>
        <ArrowForwardIcon color="primary" fontSize="small" />
      </Stack>
      <Typography variant="caption" sx={{ mt: 0, mb: 1.5 }} noWrap>
        {contracts}
      </Typography>
      <CardItem title={t('invoices.invoice_nr')} value={invoiceId} sx={{ mt: 'auto' }} />
      <CardDotItem
        title={t('invoices.invoice_due_date')}
        value={dayjs(dueDate).format('DD.MM.YYYY')}
        invoiceStatus={invoiceStatus}
      />
      <Show when={!!amountTotal}>
        <CardItem
          isCreditNote={isCreditNote}
          title={t('invoices.invoice_amount')}
          value={isCreditNote ? `- ${currency} ${amountTotal.slice(1)}` : `${currency} ${amountTotal}`}
          bold
        />
      </Show>
      <Show when={eBill}>
        <Stack flexDirection="row" alignItems="center" mt={2}>
          <Box mt={0.5}>
            <CheckIcon color="success" fontSize="small" />
          </Box>
          <Typography variant="bodyRegular" sx={{ my: 0, ml: 1 }}>
            {t('invoices.invoice_ebill')}
          </Typography>
        </Stack>
      </Show>
    </DtpInvoiceCardWrapper>
  )
}

export default DtpInvoiceCard
