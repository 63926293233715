import DtpInvoiceCardSkeleton from '@components/Skeletons/DtpInvoiceCardSkeleton'
import { Box, Stack, Skeleton } from '@mui/material'

import DtpInvoiceDetailsSkeleton from './DtpInvoiceDetailsSkeleton'

const InvoiceDetailsContentSkeleton = () => {
  return (
    <Stack direction={{ xs: 'column-reverse', md: 'row' }} justifyContent="space-between" columnGap={4}>
      <Box maxWidth={{ xs: '100%', md: 687 }} width="100%">
        <DtpInvoiceDetailsSkeleton />
      </Box>
      <Box>
        <DtpInvoiceCardSkeleton />
        <Skeleton height={23} width="30%" />
        <Skeleton height={23} width="50%" />
        <Skeleton height={48} />
        <Skeleton height={48} />
      </Box>
    </Stack>
  )
}

export default InvoiceDetailsContentSkeleton
