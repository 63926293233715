import { chartPallete, prosumerPalette } from '@assets/themes/palette'
import { ChartNoData } from '@components/ChartNoData/ChartNoData'
import DtpChartTooltipWrapper from '@components/DtpChartTooltip/DtpChartTooltipWrapper'
import MaxPowerDot from '@components/DtpMaxPower/MaxPowerDot'
import ChartSkeleton from '@components/Skeletons/ChartSkeleton'
import {
  handleSetTooltipPositionAndOpenArea,
  calculateMaxMinDomain,
  calculateTicks,
  groupNullIndexes,
} from '@helpers/chartAreaFunctions'
import { formatTimestamp } from '@helpers/dateFunctions'
import { IMeasurement, MaxPowerType, MeasurmentsType } from '@interfaces/measurements'
import { useMediaQuery } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import dayjs from 'dayjs'
import { useState, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
  Label,
  Tooltip,
  ReferenceDot,
} from 'recharts'
import { CategoricalChartState } from 'recharts/types/chart/types'

import MaxPowerReferenceLine from './MaxPowerReferenceLine'
import ReferenceAreaForNullValues from './ReferenceAreaForNullValues'
import ReferenceLineForNullValues from './ReferenceLineForNullValues'

export interface IChartAreaProps {
  data?: IMeasurement[]
  unit: 'CHF' | 'kWh'
  noDataDateValue?: string
  isLoading?: boolean
  setTooltipPosition?: CallableFunction
  tooltipPosition: {
    x: number
    y: number
  }
  measurementType?: MeasurmentsType
  maxPowerPerMonth?: MaxPowerType
}

const ChartArea = ({
  data = [],
  unit,
  noDataDateValue,
  isLoading,
  tooltipPosition,
  setTooltipPosition,
  measurementType,
  maxPowerPerMonth,
}: IChartAreaProps) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const tickFormatter = (value: string) => formatTimestamp(value)
  const isSmallMobile = useMediaQuery(theme.breakpoints.between('xs', 500))
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'lg'))
  const viewportWidth = Math.max(document.documentElement.clientWidth, window.innerWidth)
  const TOOLTIP_INITIAL_X = isMobile ? 0 : 500
  const TOOLTIP_INITIAL_Y = isMobile ? 0 : 10
  const wrapperStylePositionTooltip = { left: 40, top: 0 }
  const [tooltipOpen, setTooltipOpen] = useState(false)
  const { t, i18n } = useTranslation()
  const chartStyles = {
    fontSize: isMobile ? 11 : 14,
    lineHeight: 21,
    stroke: chartPallete.area.axisColor,
    tickLine: false,
    tick: { stroke: 'transparent' },
    axisLine: { stroke: 'transparent' },
    fontFamily: 'Montserrat',
  }

  const { min, max } = useMemo(() => calculateMaxMinDomain(data), [data])
  const ticks = useMemo(() => calculateTicks(min, max, 4), [min, max])
  const isEnergy = data.some((measurement) => measurement.energy)

  const maxPowerAvailable =
    !!maxPowerPerMonth?.timestamp &&
    dayjs(maxPowerPerMonth?.timestamp).isSame(dayjs(data[0].timestampStartOfPeriod), 'day')

  const groupedNullIndexes = useMemo(() => groupNullIndexes(data, ['energy', 'feedIn']), [data])

  const handleChartClick = (e: CategoricalChartState) =>
    handleSetTooltipPositionAndOpenArea({
      e,
      isMobile,
      isTablet,
      isSmallMobile,
      viewportWidth,
      tooltipOpen,
      TOOLTIP_INITIAL_X,
      TOOLTIP_INITIAL_Y,
      setTooltipOpen,
      setTooltipPosition,
    })

  const maxPowerInMonthFormated = {
    timestamp: dayjs(maxPowerPerMonth?.timestamp).format('YYYY-MM-DDTHH:00'),
    maxPower: String(max),
  }

  const labelPosition = Number(dayjs(maxPowerPerMonth?.timestamp).format('HH')) > (isMobile ? 12 : 18)
  const isGerman = i18n.language?.includes('de')
  const labelPlacement = labelPosition ? (isGerman ? -165 : -125) : 14

  if (isLoading) return <ChartSkeleton chartAxis="ChartArea" />
  if (!data.length) return <ChartNoData currentDate={dayjs(noDataDateValue).format('DD. MMMM YYYY')} />
  return (
    <ResponsiveContainer height={296}>
      <AreaChart
        data={data}
        margin={{ top: 50, bottom: 50, left: 0, right: 5 }}
        onClick={handleChartClick}
        style={{ cursor: 'pointer' }}
        stackOffset="expand"
      >
        <CartesianGrid stroke={chartPallete.area.gridStroke} strokeWidth={1} strokeDasharray="0" vertical={false} />
        <XAxis
          tickMargin={15}
          id="timestampStartOfPeriod"
          dataKey="timestampStartOfPeriod"
          tickFormatter={tickFormatter}
          interval={isMobile ? 3 : 1}
          {...chartStyles}
        />
        <YAxis ticks={ticks} domain={[min, max]} type="number" {...chartStyles}>
          <Label style={{ fontSize: isMobile ? 11 : 14 }} dy={-110} dx={18}>
            {unit}
          </Label>
        </YAxis>

        <Tooltip
          cursor={{ fill: 'transparent' }}
          content={
            tooltipPosition.x !== TOOLTIP_INITIAL_X || isSmallMobile ? (
              <DtpChartTooltipWrapper
                setTooltipOpen={setTooltipOpen}
                mesurementsType={measurementType ?? 'consumption'}
                maxPower={maxPowerPerMonth}
                unit={unit}
                chartType="area"
                aggregationType="hour"
              />
            ) : undefined
          }
          position={tooltipPosition}
          allowEscapeViewBox={{ x: false, y: true }}
          trigger={'click'}
          active={tooltipOpen}
          animationDuration={300}
          wrapperStyle={isSmallMobile ? wrapperStylePositionTooltip : undefined}
          filterNull={false}
        />
        {(measurementType === 'consumption' || measurementType === 'balance') && !isEnergy && (
          <Area
            type="monotone"
            dataKey={measurementType === 'balance' ? 'consumption' : 'sumOfRates'}
            stroke={chartPallete.area.stroke}
            strokeWidth={2}
            fill={chartPallete.area.area}
            activeDot={{ r: 6, strokeWidth: 0, display: tooltipOpen ? 'block' : 'none' }}
          />
        )}
        {(measurementType === 'consumption' || measurementType === 'prosumer') && isEnergy && (
          <Area
            type="monotone"
            dataKey="energy"
            stroke={chartPallete.area.stroke}
            strokeWidth={2}
            fill={chartPallete.area.area}
            activeDot={{ r: 6, strokeWidth: 0, display: tooltipOpen ? 'block' : 'none' }}
          />
        )}

        {measurementType !== 'consumption' && (
          <Area
            type="monotone"
            dataKey="feedIn"
            stroke={prosumerPalette.prosumerFeedIn}
            strokeWidth={2}
            fill={prosumerPalette.prosumerFeedInFill}
            activeDot={{ r: 6, strokeWidth: 0, display: tooltipOpen ? 'block' : 'none' }}
          />
        )}
        {ReferenceAreaForNullValues({ groupedNullIndexes, data, ticks })}
        {ReferenceLineForNullValues({ groupedNullIndexes, data, ticks })}
        {MaxPowerReferenceLine({
          maxPowerPerMonth: maxPowerInMonthFormated,
          maxPowerAvailable,
          min,
          labelPlacement,
          t,
        })}
        {maxPowerAvailable && maxPowerInMonthFormated?.maxPower && maxPowerInMonthFormated?.timestamp && (
          <ReferenceDot
            r={6}
            x={maxPowerInMonthFormated.timestamp}
            y={String(maxPowerInMonthFormated.maxPower)}
            shape={<MaxPowerDot />}
            ifOverflow="extendDomain"
          />
        )}
      </AreaChart>
    </ResponsiveContainer>
  )
}

export default ChartArea
