import { QuestionIcon, StorybookIcon } from '@assets/icons'
import AccountsAccordion from '@components/AccountsAccordion/AccountsAccordion'
import useAccounts from '@hooks/useAccounts/useAccounts'
import useRoleAccess from '@hooks/useRoleAccess/useRoleAccess'
import { useToggleState } from '@hooks/useToggleState/useToggleState'
import CloseIcon from '@mui/icons-material/Close'
import MenuIcon from '@mui/icons-material/Menu'
import { IconButton, Divider, Drawer, Box, List, ListItem, ListItemText, ListItemIcon, useTheme } from '@mui/material'
import FeatureToggle from '@src/ui/wrappers/FeatureToggle/FeatureToggle'
import Show from '@src/ui/wrappers/Show/Show'
import { useTranslation } from 'react-i18next'

import { navRoutes, profileRoutes, settingsRoutes } from '../../../router/routes.config'
import { LogoutButton } from '../LogoutButton/LogoutButton'
import { NavItem } from './DesktopMenu'
import RenderMenuItem from './RenderMenuItem'

const MobileMenu = () => {
  const [open, toggleDrawer] = useToggleState(false)
  const { t } = useTranslation()
  const { accounts, activeAccount, switchAccount } = useAccounts()
  const theme = useTheme()
  const { disabledStyles, isEndUser } = useRoleAccess()

  const NavItems = () => (
    <List sx={{ paddingTop: 10 }}>
      {navRoutes.map(({ path, name, icon }) => {
        if (!name) return
        return (
          <ListItem key={name} sx={Object.assign({ p: 0, marginTop: 1 }, path !== '/' ? disabledStyles : null)}>
            <NavItem to={path} onClick={toggleDrawer}>
              <ListItemIcon sx={{ width: 10, minWidth: 36 }}>{icon}</ListItemIcon>
              <ListItemText sx={{ margin: 0 }}>{t(name)}</ListItemText>
            </NavItem>
          </ListItem>
        )
      })}
      <NavItem to={'https://www.ckw.ch/kontakt/fragen-und-antworten'} target="_blank" onClick={toggleDrawer}>
        <ListItemIcon sx={{ width: 10, minWidth: 36 }}>
          <QuestionIcon />
        </ListItemIcon>
        <ListItemText>{t('help')}</ListItemText>
      </NavItem>
      <FeatureToggle flag="storybook">
        <NavItem to={'/storybook'} target="_blank" onClick={toggleDrawer}>
          <ListItemIcon sx={{ width: 10, minWidth: 36 }}>
            <StorybookIcon />
          </ListItemIcon>
          <ListItemText>Storybook</ListItemText>
        </NavItem>
      </FeatureToggle>
    </List>
  )

  return (
    <Box sx={{ display: { xs: 'flex', md: 'none' }, flex: 1, justifyContent: 'flex-end' }}>
      <IconButton
        size="large"
        aria-label="account of current user"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={toggleDrawer}
        color="inherit"
        sx={{ mt: 1, p: 1 }}
      >
        {open ? <CloseIcon fontSize="inherit" /> : <MenuIcon fontSize="inherit" />}
      </IconButton>
      <Drawer id="mobileNavDrawer" anchor={'top'} open={open} onClose={toggleDrawer}>
        <NavItems />
        <Divider sx={{ mb: 2 }} />
        <RenderMenuItem
          title={t('navigation.navi_my_profile')}
          routes={profileRoutes}
          itemStyles={disabledStyles}
          toggleDrawer={toggleDrawer}
        />
        <RenderMenuItem
          title={t('navigation.navi_settings')}
          routes={settingsRoutes}
          itemStyles={disabledStyles}
          toggleDrawer={toggleDrawer}
          showAdditionalLink
        />
        <Show when={accounts && accounts.length > 1 && isEndUser}>
          <AccountsAccordion
            currentAccount={activeAccount}
            accounts={accounts}
            switchAccount={switchAccount}
            onClick={toggleDrawer}
          />
        </Show>
        <Divider sx={{ mb: 2 }} />
        <Box mb={2}>
          <LogoutButton
            fullWidth
            sx={{
              marginLeft: 0,
              justifyContent: 'flex-start',
              padding: { xs: theme.spacing(0, 4.625) },
              '&:hover': {
                backgroundColor: 'transparent',
              },
            }}
          />
        </Box>
      </Drawer>
    </Box>
  )
}

export default MobileMenu
