import { deliveryType } from '@enums/deliveryType'
import { prepackLocationData, formatContracts } from '@helpers/locationsFunctions'
import useAccounts from '@hooks/useAccounts/useAccounts'
import { getContracts } from '@src/api/queries'
import { useLocationStore } from '@src/stores/locations/useLocationStore'
import { useQuery } from '@tanstack/react-query'
import { useEffect, useMemo } from 'react'
import { useLocation } from 'react-router-dom'

export interface IAddOn {
  gpnr: string
  supplyStartDate: string
  supplyEndDate: string
  meterId?: string
  locationId?: string
  contractId?: string
  hasMoreThanOneContract?: boolean
  isFeedInLocation?: boolean
  parentLocation?: string
  locationOrContractId?: string
  contractType?: string
  hasFeedInContractsInLocation?: boolean
  premiseId?: string
  isFeedInContract?: boolean
  contractAccountId?: string
  deliveryType?: deliveryType
}

const useLocationsData = () => {
  const { state } = useLocation()
  const { activeAccount } = useAccounts()
  const { gpnr } = activeAccount

  const selectedItem = useLocationStore((state) => state.selectedItem)
  const setSelectedItem = useLocationStore((state) => state.setSelectedItem)

  const showExpiredContracts = useLocationStore((state) => state.showExpiredContracts)
  const setShowExpiredContracts = useLocationStore((state) => state.setExpiredContracts)

  const handleChangeExpireContracts = () => {
    if (showExpiredContracts) {
      setSelectedItem(activeLocations[0])
      setShowExpiredContracts(!showExpiredContracts)
    }
    setShowExpiredContracts(!showExpiredContracts)
  }
  const { data, isLoading, isError } = useQuery({
    queryKey: ['locations', gpnr],
    queryFn: () => getContracts(gpnr),
    enabled: !!gpnr,
  })
  const activeLocations = useMemo(
    () => prepackLocationData(data?.body.locationsContractActive || [], gpnr),
    [data, gpnr]
  )

  const expiredLocations = useMemo(
    () => prepackLocationData(data?.body.locationsContractTerminated || [], gpnr),
    [data, gpnr]
  )

  const allLocations = useMemo(() => [...activeLocations, ...expiredLocations], [activeLocations, expiredLocations])

  const activeContracts = useMemo(() => formatContracts(data?.body.locationsContractActive || []), [data])
  const expiredContracts = useMemo(() => formatContracts(data?.body.locationsContractTerminated || []), [data])
  const noContracts = useMemo(
    () => !activeContracts.length && !expiredContracts.length,
    [activeContracts, expiredContracts]
  )

  useEffect(() => {
    if (allLocations.length) {
      if (!activeLocations.length) {
        setShowExpiredContracts(true)
        setSelectedItem(expiredLocations[1])
      } else {
        setSelectedItem(activeLocations[0])
      }
      if (state && state?.contractId) {
        const { contractId } = state
        const selectedContract = allLocations.find((item) => item.value === contractId)
        setSelectedItem(selectedContract)
      }
    }
  }, [allLocations, activeLocations, expiredLocations, setShowExpiredContracts, setSelectedItem, state])

  return {
    activeLocations,
    expiredLocations,
    allLocations,
    activeContracts,
    expiredContracts,
    noContracts,
    selectedItem,
    setSelectedItem,
    isError,
    isLoading,
    showExpiredContracts,
    activeAccount,
    setShowExpiredContracts,
    handleChangeExpireContracts,
  }
}

export default useLocationsData
