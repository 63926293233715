import { ArrowRightIcon } from '@assets/icons'
import CodeDeliveryOptions from '@components/DtpConnectAccountWizard/common/CodeDeliveryOptions'
import NoAccountControls from '@components/DtpConnectAccountWizard/common/NoAccountControls'
import { AuthOptions, FormSteps } from '@enums/authorization'
import useNotification from '@hooks/useNotification/useNotification'
import { Medium } from '@interfaces/authorization'
import CloseIcon from '@mui/icons-material/Close'
import { Typography, Button, Stack, useTheme, useMediaQuery, IconButton, Box, CircularProgress } from '@mui/material'
import Show from '@src/ui/wrappers/Show/Show'
import { useTranslation } from 'react-i18next'

const mediumMap = {
  invoiceEmail: 'E-mail',
  mobileNumber: 'SMS',
  phoneOrganization: 'SMS',
  userEmail: 'E-mail',
}

export interface IRequestNewCodeProps {
  authOptions: Partial<Record<AuthOptions, string>>
  goTo: (step: string) => void
  requestAccessCode: (medium: Medium) => Promise<{ body: null }>
  requestAccessCodeByPost: () => Promise<{ body: null }>
  onClose?: () => void
  setSelectedOption: (method: AuthOptions) => void
  selectedOption: AuthOptions
  isUpdating: boolean
  isLoading: boolean
  availableDeliveryOptions: {
    [key: string]: boolean | string
  }
}

const RequestNewCode = ({
  goTo,
  authOptions,
  requestAccessCode,
  requestAccessCodeByPost,
  onClose,
  selectedOption,
  setSelectedOption,
  availableDeliveryOptions,
  isUpdating,
  isLoading,
}: IRequestNewCodeProps) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const notification = useNotification()

  const handleRequestAccessCode = async () => {
    try {
      if (availableDeliveryOptions.post) {
        await requestAccessCodeByPost()
        notification(
          'success',
          t('authorization.success_account_access_code_letter_requested_title'),
          t('authorization.success_account_access_code_letter_requested_description')
        )
      } else {
        await requestAccessCode(selectedOption)
        notification(
          'success',
          t('authorization.access_code_sent_title'),
          t('authorization.access_code_sent_description', { medium: mediumMap[selectedOption] })
        )
      }
      goTo(FormSteps.ACCESS_CODE_STEP)
    } catch (error) {
      notification('error')
    }
  }

  return (
    <Stack height="100%">
      <Stack mb={4} direction="row" justifyContent="space-between" alignItems="center">
        <Typography variant="heading3" sx={{ my: 0 }}>
          {t('authorization.request_access_code')}
        </Typography>

        <IconButton onClick={onClose} size="small">
          <CloseIcon />
        </IconButton>
      </Stack>

      <CodeDeliveryOptions
        isLoading={isLoading}
        authOptions={authOptions}
        availableDeliveryOptions={availableDeliveryOptions}
        selectedOption={selectedOption}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSelectedOption(e.target.value as AuthOptions)}
      />

      <Stack height="100%" direction={{ xs: 'column-reverse', sm: 'column' }}>
        <Stack direction={{ xs: 'column', sm: 'row' }} alignItems="center" gap={2} mt={'auto'}>
          <Show when={!availableDeliveryOptions.none || !!availableDeliveryOptions.post}>
            <Button
              variant="contained"
              size="large"
              fullWidth={isMobile}
              onClick={handleRequestAccessCode}
              disabled={isUpdating}
            >
              <Show when={!isUpdating}>{t('authorization.request_code')}</Show>
              <Show when={isUpdating}>
                <CircularProgress size={30} sx={{ color: '#fff' }} />
              </Show>
            </Button>
          </Show>
          <Button
            variant="outlined"
            size="large"
            fullWidth={isMobile}
            onClick={() => goTo(FormSteps.ACCOUNT_NUMBER_STEP)}
          >
            {t('back')}
          </Button>
        </Stack>
        <Box>
          <Show when={!!availableDeliveryOptions.post}>
            <Typography variant="bodyRegularEmphasized" sx={{ my: 0, mb: 0.5, mt: { xs: 0, sm: 4 } }}>
              {t('authorization.already_have_account_access_code')}
            </Typography>
            <Button
              variant="text"
              disableTouchRipple
              size="large"
              startIcon={<ArrowRightIcon sx={{ mb: 0.25 }} />}
              sx={{ p: '0 !important' }}
              onClick={() => goTo(FormSteps.ACCESS_CODE_STEP)}
            >
              {t('authorization.enter_code')}
            </Button>
          </Show>
          <NoAccountControls
            stackProps={{ marginTop: isMobile && !availableDeliveryOptions.post ? 0 : 3 }}
            isExternal
          />
        </Box>
      </Stack>
    </Stack>
  )
}

export default RequestNewCode
