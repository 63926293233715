import NoAccountControls from '@components/DtpConnectAccountWizard/common/NoAccountControls'
import TooltipLabel from '@components/DtpConnectAccountWizard/common/TooltipLabel'
import DtpTextField from '@components/DtpTextField/DtpTextField'
import { FormSteps } from '@enums/authorization'
import CloseIcon from '@mui/icons-material/Close'
import { Box, Button, Typography, Stack, IconButton, useTheme, useMediaQuery } from '@mui/material'
import { useMask } from '@react-input/mask'
import { FormikErrors, FormikTouched } from 'formik'
import { useTranslation } from 'react-i18next'

export interface IAccountNumberProps {
  goTo: (step: string) => void
  gpnr: string
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  onBlur: (event: React.FocusEvent<HTMLInputElement>) => void
  error: FormikErrors<string>
  touched: FormikTouched<boolean>
  onClose?: () => void
}

const AccountNumber = ({ goTo, gpnr, error, onChange, onBlur, touched, onClose }: IAccountNumberProps) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const accountNumberRef = useMask({ mask: '_ ___ ___ ___', replacement: '_' })

  return (
    <Stack height="100%">
      <Stack mb={4} direction="row" justifyContent="space-between" alignItems="flex-start">
        <Typography variant="heading3" sx={{ my: 0, mt: 0.5 }}>
          {t('accounts.add_new_account')}
        </Typography>
        <IconButton onClick={onClose} size="small">
          <CloseIcon />
        </IconButton>
      </Stack>

      <DtpTextField
        value={gpnr}
        onChange={onChange}
        onBlur={onBlur}
        name="gpnr"
        inputRef={accountNumberRef}
        label={
          <TooltipLabel
            label={t('authorization.ckw_customer_number')}
            tooltipLabel={t('authorization.tooltip_authorization_customer_number')}
          />
        }
        inputMode="tel"
        inputProps={{
          maxLength: 9,
        }}
        helperText={touched ? error : undefined}
        error={!!error}
        autoComplete="off"
        placeholder="x xxx xxx"
        fullWidth
      />

      <Stack direction={{ xs: 'column-reverse', sm: 'column' }} mt={{ xs: 0, sm: 4 }} height="100%">
        <Stack marginTop="auto" rowGap={2}>
          <Box>
            <Button
              size="large"
              onClick={() => goTo(FormSteps.REQUEST_ACCESS_CODE)}
              fullWidth={isMobile}
              disabled={!!error || gpnr.length !== 9}
            >
              {t('button_next')}
            </Button>
          </Box>
          <Button size="large" variant="outlined" onClick={onClose} sx={{ display: { xs: 'flex', sm: 'none' } }}>
            {t('cancel')}
          </Button>
        </Stack>
        <NoAccountControls title={t('authorization.no_customer_number')} isExternal />
      </Stack>
    </Stack>
  )
}

export default AccountNumber
