import theme from '@assets/themes/theme'
import { getTodayAndYesterday, validateChangeDate } from '@helpers/dateFunctions'
import { ChevronLeft, ChevronRight } from '@mui/icons-material'
import { Stack, Typography, TypographyProps, styled, IconButton, Box, Button, useMediaQuery } from '@mui/material'
import { DateView, MobileDatePicker } from '@mui/x-date-pickers'
import dayjs, { Dayjs } from 'dayjs'
import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { ArrowDownIcon, CalendarIcon } from '../../../assets/icons'
import CustomHeader from './DtpDatePickerCustomHeader'

const StartIconTypography = styled((props: TypographyProps) => <Typography {...props} variant="body1" />)(
  ({ theme }) => ({
    fontWeight: 500,
    fontSize: theme.typography.pxToRem(18),
    color: theme.palette.text.primary,
    padding: 0,
    margin: 0,
    alignSelf: 'inherit',
  })
)

export interface IDtpDatePickerProps {
  value?: string | Dayjs
  viewType: DateView
  onChange: (date: string) => void
  maxDate?: string | number
  minDate?: string
  disabled?: boolean
  withPrevNext?: boolean
  variant?: 'outlined' | 'standard'
  isEmpty?: boolean
  icon?: React.ReactNode
  selectedFromComparison?: string
  setSelectedFromComparison?: CallableFunction
}

interface IPickerViewProps {
  format: string
  views: DateView[]
}
export const DtpDatePicker = ({
  value,
  viewType,
  onChange,
  maxDate,
  minDate,
  disabled,
  withPrevNext = false,
  variant = 'standard',
  isEmpty,
  icon,
  selectedFromComparison,
  setSelectedFromComparison,
}: IDtpDatePickerProps) => {
  const { t } = useTranslation()
  const [open, setOpen] = useState(false)
  const [date, setDate] = useState<Dayjs>(dayjs(value))
  const [labelValue, setLabelValue] = useState<Dayjs>(dayjs(value))
  const valueThatChanges = !open ? labelValue : date

  const dateFormat = 'DD. MMM YYYY'
  const isItToday: boolean = getTodayAndYesterday(labelValue).isItToday
  const isItYesterday: boolean = getTodayAndYesterday(labelValue).isItYesterday
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const disableNextLabelButton: boolean = validateChangeDate(
    labelValue,
    dayjs(maxDate),
    viewType
  ).isAfterLastAvailableDay
  const disablePrevLabelButton: boolean = validateChangeDate(
    labelValue,
    dayjs(minDate),
    viewType
  ).isBeforeLastAvailableDay
  const labelTextIfYesterday = isItYesterday ? 'yesterday' : ''
  const labelText = (isItToday || isItYesterday) && `${t(isItToday ? 'today' : labelTextIfYesterday)}, `

  useEffect(() => {
    !isEmpty && !open && handleOnChange(date)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date, open, isEmpty])

  const handleClose = () => {
    setOpen(false)
    !isEmpty && handleOnChange(labelValue)
  }
  const handleOpen = () => setOpen(true)

  const handleOnChange = (value: dayjs.Dayjs | null) => {
    if (!value) return
    setLabelValue(value)
    onChange(value.format())
    setDate(value)
    setOpen(false)
  }

  const handleChangeDate = (changeType: 'add' | 'subtract') => {
    const tempDate: Dayjs = valueThatChanges[changeType](1, viewType === 'month' && open ? 'year' : viewType)
    if (open) {
      setDate(tempDate)
      return
    }
    handleOnChange(tempDate)
  }

  useEffect(() => {
    if (selectedFromComparison && selectedFromComparison?.length > 0 && setSelectedFromComparison) {
      handleOnChange(dayjs(selectedFromComparison))
      setSelectedFromComparison('')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFromComparison])

  const getViewsFormat = useCallback((): IPickerViewProps => {
    switch (viewType) {
      case 'month':
        return {
          format: isMobile && variant === 'outlined' ? 'MMM YYYY' : 'MMMM YYYY',
          views: ['month'],
        }
      case 'year':
        return {
          format: 'YYYY',
          views: ['year'],
        }
      default:
        return {
          format: dateFormat,
          views: ['year', 'month', 'day'],
        }
    }
  }, [viewType, isMobile, variant])

  return (
    <Stack flexDirection={'row'} fontSize={'1.2rem'} gap={2} alignItems="center">
      {withPrevNext && !disablePrevLabelButton && (
        <IconButton disabled={disabled} onClick={() => handleChangeDate('subtract')}>
          <ChevronLeft />
        </IconButton>
      )}
      <Box
        sx={{
          ':hover': { cursor: 'pointer' },
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        {variant === 'standard' ? (
          <>
            <CalendarIcon sx={{ mr: 1, fill: 'transparent' }} onClick={handleOpen} className="openDatepickerButton" />
            {labelText && viewType === 'day' && (
              <StartIconTypography
                sx={{ mr: 1 }}
                aria-disabled={disabled}
                onClick={handleOpen}
                className="openDatepickerButton"
              >
                {labelText}
              </StartIconTypography>
            )}
            <StartIconTypography onClick={handleOpen} aria-disabled={disabled} className="openDatepickerButton" noWrap>
              {labelValue?.format(getViewsFormat().format)}
            </StartIconTypography>
          </>
        ) : (
          <Button
            variant="outlinedSelect"
            onClick={handleOpen}
            endIcon={icon ?? <ArrowDownIcon fontSize="inherit" />}
            className="openDatepickerButton"
          >
            {!value ? 'TT.MM.JJJJ' : labelValue?.format(getViewsFormat().format)}
          </Button>
        )}

        <MobileDatePicker
          localeText={{ cancelButtonLabel: t('cancel') }}
          value={value ? date : null}
          open={open}
          disabled={disabled}
          onClose={() => setOpen(false)}
          onOpen={handleOpen}
          onAccept={handleOnChange}
          onError={(err) => {
            if (err === 'maxDate') {
              setDate(dayjs(maxDate))
            } else if (err === 'minDate') {
              setDate(dayjs(minDate))
            }
          }}
          closeOnSelect
          sx={{
            display: 'none',
          }}
          slots={{
            toolbar: undefined,
            calendarHeader: (props) =>
              CustomHeader({
                caledarHeaderProps: props,
                date,
                viewType,
                minDate,
                maxDate,
                handleChangeDate,
                validateChangeDate,
              }),
          }}
          view={viewType}
          shouldDisableMonth={() => viewType === 'year'}
          slotProps={{
            layout: {
              sx: {
                '.MuiDateCalendar-root': {
                  height: viewType === 'month' ? 'auto' : 'inherit',
                  py: 2,
                },
              },
            },
            actionBar: {
              actions: ['cancel'],
              onClick: () => handleClose(),
              id: 'calendarActionBar',
              sx: {
                mx: 2.5,
                my: 1.5,
                borderTop: '1px solid #D5D8D5',
                '.MuiButtonBase-root': {
                  color: '#365F10',
                  minWidth: '2rem',
                  px: 0,
                  background: 'transparent',
                  ':hover': {
                    background: 'transparent',
                  },
                },
              },
            },
            dialog: {
              onBackdropClick: () => handleOnChange(labelValue),
              onClose: () => handleOnChange(labelValue),
            },
          }}
          yearsPerRow={3}
          {...{ views: getViewsFormat().views }}
          {...{ format: getViewsFormat().format }}
          {...(minDate && { minDate: dayjs(minDate) })}
          {...(maxDate && { maxDate: dayjs(maxDate) })}
        />
      </Box>
      {withPrevNext && !disableNextLabelButton && (
        <IconButton disabled={disabled} onClick={() => handleChangeDate('add')}>
          <ChevronRight />
        </IconButton>
      )}
    </Stack>
  )
}
