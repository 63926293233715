import { Stack, Box, Skeleton } from '@mui/material'

const SkeletonItem = () => {
  return (
    <Box mb={3}>
      <Skeleton height={15} />
      <Stack direction="row" justifyContent="space-between" my={1}>
        <Skeleton width={50} />
        <Skeleton width={30} />
      </Stack>
      <Skeleton height={15} />
    </Box>
  )
}

const SkeletonItemOpen = () => {
  return (
    <Box mb={3}>
      <Skeleton height={15} />
      {Array.from({ length: 1 }, (_, index) => (
        <Stack direction="row" justifyContent="space-between" my={1} key={index}>
          <Stack direction="row" justifyContent="space-between" columnGap={2}>
            <Skeleton width={20} />
            <Skeleton width={100} />
          </Stack>
          <Skeleton width={30} />
          <Skeleton width={50} />
          <Skeleton width={30} />
        </Stack>
      ))}
      <Skeleton height={15} />
    </Box>
  )
}

const DtpPaidInvoicesAccordionSkeleton = () => {
  return (
    <>
      <Skeleton width={100} />
      <Box mb={3}>
        <Skeleton height={40} width={150} />
      </Box>
      <SkeletonItemOpen />
      <SkeletonItem />
    </>
  )
}

export default DtpPaidInvoicesAccordionSkeleton
