import ProfileMenu from '@components/ProfileMenu/ProfileMenu'
import useRoleAccess from '@hooks/useRoleAccess/useRoleAccess'
import { Stack, List, ListItem as MuiListItem, styled, ListItemText } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'

import { navRoutes } from '../../../router/routes.config'

const ListItem = styled(MuiListItem)({
  padding: 0,
  width: 'auto',
  margin: 0,
})

export const NavItem = styled(NavLink)(({ theme }) => ({
  background: 'transparent',
  textDecoration: 'none',
  color: theme.palette.text.primary,
  borderRadius: theme.spacing(12),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flex: 1,
  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(1, 2),
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(2),
  },

  '& .MuiListItemIcon-root': {
    fill: 'none',
    color: 'transparent',
  },
  '& .MuiTypography-root': {
    [theme.breakpoints.up('md')]: {
      margin: 0,
      padding: theme.spacing(0.75, 1.5),
    },
  },
  '&.active': {
    '& .MuiTypography-root': {
      color: theme.palette.primary.main,
    },
    background: theme.palette.primary.light,
  },
  '&:hover': {
    '& .MuiTypography-root': {
      color: theme.palette.text.primary,
    },
    background: theme.palette.grey[50],
    '&.active': {
      '& .MuiTypography-root': {
        color: theme.palette.primary.main,
      },
      background: theme.palette.primary.light,
    },
  },
}))

const DesktopMenu = () => {
  const { t } = useTranslation()
  const { disabledStyles } = useRoleAccess()

  const NavItems = () => (
    <List sx={{ display: 'flex', columnGap: 2, py: 0 }}>
      {navRoutes.map(({ path, name }) => {
        if (!name) return
        return (
          <ListItem key={name} sx={path !== '/' ? disabledStyles : null}>
            <NavItem to={path}>
              <ListItemText color="inherit">{t(name)}</ListItemText>
            </NavItem>
          </ListItem>
        )
      })}
    </List>
  )

  return (
    <Stack
      flex={1}
      flexDirection={'row'}
      alignItems={'center'}
      justifyContent={'space-between'}
      display={{ xs: 'none', md: 'flex' }}
    >
      <NavItems />
      <Stack flexDirection="row">
        <NavItem to={'https://www.ckw.ch/kontakt/fragen-und-antworten'} target="_blank">
          <ListItemText sx={{ '& .MuiTypography-root': { fontFamily: 'GothamBook', fontWeight: 400 } }} color="inherit">
            {t('help')}
          </ListItemText>
        </NavItem>
        <ProfileMenu />
      </Stack>
    </Stack>
  )
}

export default DesktopMenu
