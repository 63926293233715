import { Box, styled } from '@mui/material'

import { TooltipContentRowProps } from './TooltipContentRow'

const IconBox = styled(Box)<{ shape: 'square' | 'circle' }>(({ shape }) => ({
  width: 9,
  height: 9,
  borderRadius: shape === 'circle' ? '50%' : 2,
  marginBottom: '3px !important',
}))

const iconStylesConfig = {
  square: (color: string) => ({ backgroundColor: color }),
  circle: (color: string) => ({ backgroundColor: color, borderColor: color }),
  maxPower: {
    backgroundColor: '#EC6A08',
    outline: '1px solid #EC6A08',
    outlineOffset: '1px',
    width: 8,
    height: 8,
  },
  noData: {
    backgroundColor: 'rgba(141, 150, 141, 0.05)',
    border: '1px dashed rgba(141, 150, 141, 0.5)',
  },
  forecast: {
    backgroundColor: 'rgba(177, 97, 162, 0.2)',
    border: '1px dashed rgba(177, 97, 162, 0.5)',
  },
}

const RowIcon = ({ lableIconColor = '', lableIconVariant }: Partial<TooltipContentRowProps>) => {
  if (!lableIconVariant) return null

  const iconStyles = iconStylesConfig[lableIconVariant as keyof typeof iconStylesConfig]
  if (!iconStyles) return null

  return (
    <IconBox
      id={`${lableIconVariant}Icon`}
      shape={lableIconVariant === 'circle' || lableIconVariant === 'maxPower' ? 'circle' : 'square'}
      sx={typeof iconStyles === 'function' ? iconStyles(lableIconColor) : iconStyles}
    />
  )
}

export default RowIcon
