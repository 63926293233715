import { cardPalette } from '@assets/themes/palette'
import theme from '@assets/themes/theme'
import { IAccount } from '@interfaces/accounts'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  ButtonBase,
  Typography,
  styled,
  useMediaQuery,
} from '@mui/material'
import { CSSProperties } from '@mui/material/styles/createMixins'
import { t } from 'i18next'

export const menuItemSharedStyles: CSSProperties = {
  width: '100%',
  background: 'transparent',
  padding: theme.spacing(1, 4),
  color: '#303636',
  textDecoration: 'none',
  fontFamily: 'GothamMedium',
  fontWeight: 500,
  fontSize: 'inherit',
  justifyContent: 'start',
  textAlign: 'left',
  '&::before': {
    content: '""',
    position: 'absolute',
    left: 15,
    top: 'calc(50% - 2.5px)',
    background: 'transparent',
    width: 5,
    height: 5,
    borderRadius: '100%',
    transition: 'all 0.3s ease',
  },
}

const MenuItemButton = styled(ButtonBase)(({ theme }) => ({
  ...menuItemSharedStyles,
  '&.active': {
    color: theme.palette.primary.main,
    '&::before': {
      background: theme.palette.primary.main,
    },
  },
  '&:hover': {
    '&::before': {
      background: theme.palette.text.primary,
    },
  },
}))

const AccountsAccordion = ({
  currentAccount,
  accounts,
  switchAccount,
  onClick,
}: {
  currentAccount: IAccount
  accounts: Array<IAccount>
  switchAccount?: (account: IAccount) => void
  onClick: () => void
}) => {
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const handleMenuItemClick = (action: () => void, account: IAccount) => {
    return () => {
      switchAccount && switchAccount(account)
      action && action()
    }
  }

  return (
    <>
      <Typography
        fontFamily="GothamBook"
        variant={isMobile ? 'bodySmall' : 'caption'}
        fontWeight={isMobile ? 400 : 500}
        color={isMobile ? theme.palette.text.secondary : theme.palette.text.primary}
        sx={{ mx: 4, mt: isMobile ? 1 : 2 }}
      >
        {t('navigation.navi_change_account')}
      </Typography>
      <Accordion
        elevation={0}
        style={{ top: 0, height: 'max-content' }}
        sx={{ width: '100%', m: 0 }}
        disableGutters={true}
      >
        <AccordionSummary sx={{ padding: theme.spacing(0, 4), mb: isMobile ? 1.5 : 0 }} expandIcon={<ExpandMoreIcon />}>
          <Typography sx={{ my: 0 }}>{currentAccount.name}</Typography>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            backgroundColor: cardPalette.stackBgColor,
            maxHeight: '232px',
            overflowY: 'auto',
            scrollbarWidth: 'thin',
            padding: 0,
            borderStyle: 'solid',
            borderWidth: '1px 0',
            borderColor: cardPalette.border,
          }}
        >
          {accounts.map((account: IAccount, index) => (
            <Box key={index} py={theme.spacing(1.5)}>
              <MenuItemButton
                disableRipple
                className={account.gpnr === currentAccount.gpnr ? 'active' : ''}
                data-testid="AccountNameButton"
                onClick={handleMenuItemClick(onClick, account)}
              >
                {account.name}
              </MenuItemButton>
            </Box>
          ))}
        </AccordionDetails>
      </Accordion>
    </>
  )
}

export default AccountsAccordion
