import { MaxPowerIcon } from '@assets/icons'
import theme from '@assets/themes/theme'
import DtpInfoTooltip from '@components/DtpInfoTooltip/DtpInfoTooltip'
import { isNil } from '@helpers/functional'
import { Stack, useMediaQuery, styled, Box, Typography } from '@mui/material'
import Show from '@src/ui/wrappers/Show/Show'
import { useTranslation } from 'react-i18next'

import LabelValue from './components/LabelValue'
import LabelIndicator, { StyledSquare } from './components/Labelindicator'

type ConsumptionItemV2 = {
  label: string
  value?: string | number | null
  unit: string
  showSquare?: boolean
  showCircle?: boolean
  showMaxPower?: boolean
  color?: string
  border?: string
  showTooltip?: boolean
  tooltipContent?: React.ReactNode | string
  customLabelValue?: string | React.ReactNode
}

const Wrapper = styled(Stack)(({ theme }) => ({
  flex: 1,
  flexDirection: 'column',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column-reverse',
    alignItems: 'center',
    textAlign: 'center',
  },
}))

const localNumberFormat = new Intl.NumberFormat('de-CH', {
  style: 'decimal',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
})

const tooltipLabelErrorStyle = {
  fontSize: 16,
  fontWeight: '200',
  whiteSpace: 'wrap',
  ml: 1,
}

const ConsumptionItemV2 = ({
  label,
  value,
  unit,
  showSquare,
  showCircle,
  showMaxPower,
  color,
  border,
  showTooltip,
  tooltipContent,
  customLabelValue,
}: ConsumptionItemV2) => {
  const { t } = useTranslation()

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const numberValue = isNil(value) ? null : localNumberFormat.format(Number(value))

  const displayValue = isNil(numberValue) ? null : (
    <LabelValue unit={unit} numberValue={numberValue} isMobile={isMobile} />
  )
  return (
    <Wrapper>
      <Stack direction="row" alignItems="center" color="#fff">
        <LabelIndicator
          color={color}
          border={border}
          showCircle={showCircle}
          showMaxPower={showMaxPower}
          showSquare={showSquare}
        />

        <Typography
          variant="body2"
          whiteSpace="nowrap"
          sx={{
            my: 0,
            mt: 0.25,
          }}
        >
          {label}
        </Typography>

        <Show when={!!showTooltip}>
          <DtpInfoTooltip
            tooltipWidth={300}
            id={label}
            title={
              <Stack direction="row" alignItems="center" width="max-content">
                <Show when={!!showSquare}>
                  <StyledSquare sx={{ backgroundColor: color }} border={border && `1px dashed ${border}`} my="10px" />
                </Show>
                <Show when={!!showMaxPower}>
                  <MaxPowerIcon sx={{ width: 12, height: 12, mr: 1 }} />
                </Show>
                <Typography sx={{ my: 0 }}>{label}</Typography>
              </Stack>
            }
            content={tooltipContent}
          />
        </Show>
      </Stack>

      <Stack direction="row" alignItems={'center'}>
        <Box>
          <Typography
            sx={{
              my: 0,
              typography: customLabelValue ? 'bodyRegular' : 'bodyRegularEmphasized',
              ...(value === null ? tooltipLabelErrorStyle : undefined),
            }}
          >
            {customLabelValue ? value : (displayValue ?? t('power_consumption_page.error_message_no_data_available'))}
          </Typography>
        </Box>
        <Show when={!!customLabelValue}>
          <DtpInfoTooltip
            tooltipWidth={300}
            id={label}
            title={
              <Stack direction="row" alignItems="center" width="max-content">
                <MaxPowerIcon sx={{ width: 12, height: 12, mr: 1 }} />
                <Typography sx={{ my: 0 }}>{label}</Typography>
              </Stack>
            }
            content={customLabelValue}
          />
        </Show>
      </Stack>
    </Wrapper>
  )
}

export default ConsumptionItemV2
